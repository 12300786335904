import React from 'react';

const ProgramEmbed = ({ sheetUrl }) => {
    // Inline styles
    const containerStyle = {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%', // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
        width: '100%' // Take up all container width
    };

    const iframeStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        border: '0'
    };

    return (
        <div style={containerStyle}>
            <iframe
                style={iframeStyle}
                src={sheetUrl}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                title="Google Sheet">Loading...</iframe>
        </div>
    );
};

export default ProgramEmbed;