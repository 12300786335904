import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              So what makes <span className="purple"> The Gym Community </span> different?
            </h1>
            <p className="home-about-body">
              We are founded on education and community.
              <br />
              <br />Our goals are to 
              <i>
                <b className="purple"> help others, educate, and create a welcoming environment. </b>
              </i>
              <br />
              <br />
              We have helped our members &nbsp;
              <i>
                <b className="purple">Gain Muscle </b>,
                <b className="purple"> Increase Lifts </b>, and{" "}
                <b className="purple">
                  Lose Fat.
                </b>
              </i>
              <br />
              <br />
              We have coaches avalible that are knowledgable
              with <i><b className="purple">dieting</b></i>,
              <i>
                <b className="purple">
                  {" "}
                  bodybuilding,
                </b>
              </i>
              &nbsp; and
              <i>
                <b className="purple"> powerlifting</b>
              </i>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
