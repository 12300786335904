import React from "react";
import { Container } from "react-bootstrap";

const Break = ({ id }) => {
  return (
    <Container>
      <br></br>
      <br></br>
      <br></br>
      <hr></hr>
      <p id={id}></p>
      <br></br>
      <br></br>
      <br></br>

    </Container>
  );
};

export default Break;
