import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";


function BulkingvsCutting({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [expand, updateExpanded] = useState(false);;
    return (
        <Container fluid className="about-section">
            <Container>
                <h2>This is the comprehensive guide to bulking vs cutting</h2>
                <p>This will include information on what defines a bulk or cut, how each one works, and a table on whether or not you should be bulking or cutting.</p>
            </Container>
            <br></br><br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Welcome to The Gym Community Library!</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library  </Nav.Link>
                    <a href="#0">Bulking and Cutting in Fitness</a>
                    <br></br>
                    <a href="#1">Bulking vs Cutting Criteria</a>
                </Offcanvas.Body>
            </Offcanvas>
            <Break id="0" />
            <Container>
                <h2>Bulking and Cutting in Fitness</h2>

                <h3>Bulking</h3>
                <p>In the bulking phase, the primary objective is to build muscle mass. This usually involves:</p>
                <ul>
                    <li><strong>Increased Caloric Intake:</strong> Consuming more calories than you burn to create a caloric surplus.</li>
                    <li><strong>High Protein Diet:</strong> Ensuring you get adequate protein to facilitate muscle repair and growth.</li>
                    <li><strong>Strength Training:</strong> Lifting heavier weights and focusing on compound exercises like squats, deadlifts, and bench presses.</li>
                    <li><strong>Rest and Recovery:</strong> Allowing adequate time for muscle recovery, which is crucial for growth.</li>
                </ul>

                <p>There are different types of bulking:</p>
                <ul>
                    <li><strong>Clean Bulking:</strong> Involves a modest caloric surplus and focuses on gaining lean muscle mass with minimal fat gain.</li>
                    <li><strong>Dirty Bulking:</strong> A more aggressive approach involving a larger caloric surplus, often without paying much attention to the quality of the calories consumed. This usually results in both muscle and fat gain.</li>
                </ul>

                <h3>Cutting</h3>
                <p>In the cutting phase, the primary objective is to lose body fat while maintaining as much muscle mass as possible. This usually involves:</p>
                <ul>
                    <li><strong>Caloric Deficit:</strong> Consuming fewer calories than you burn to create a caloric deficit.</li>
                    <li><strong>High Protein Diet:</strong> Maintaining a high protein intake to preserve muscle mass.</li>
                    <li><strong>Cardio Exercise:</strong> Including more cardiovascular exercises like running, swimming, or cycling.</li>
                    <li><strong>Strength Training:</strong> Continuation of lifting weights but often at lower volumes to maintain muscle.</li>
                </ul>

                <h3>Alternatives</h3>
                <p>Some other options include:</p>
                <ul>
                    <li><strong>Recomposition:</strong> Some people opt for a "recomp" where they try to gain muscle and lose fat simultaneously, although this is generally considered to be less efficient than bulking and cutting cycles.</li>
                    <li><strong>Maintenance:</strong> Some may also go through a maintenance phase, where the aim is to maintain the current muscle mass and body fat levels.</li>
                </ul>

                <p>It's important to consult with healthcare providers or certified fitness professionals before undergoing significant changes in your diet or exercise regimen.</p>

            </Container>
            <Break id="1" />
            <Container>
                <h2>Bulking vs Cutting Criteria</h2>
                <p>Have you ever wondered: "Should I bulk or cut?, well in this section you will be taken through different comparisions which will helpfull veer you in the correct direction and help aid you in your decision making.</p>
                <br></br><br></br>
                <h3>Current Body Fat Percentage</h3>
                <p>Bulking</p>
                <p><strong>Ideal for:</strong> Those with a lower body fat percentage (below 15% for men, below 25% for women).</p>
                <p><strong>Why:</strong> Lower body fat allows for more efficient muscle gain with minimal fat accumulation.</p>

                <h3>Cutting</h3>
                <p><strong>Ideal for:</strong> Those with a higher body fat percentage (above 15% for men, above 25% for women).</p>
                <p><strong>Why:</strong> Reducing body fat will improve muscle definition and overall health.</p>

                <br></br><br></br>
                <br></br><br></br>
                <h3>What Are Your Current Goals</h3>

                <p>Bulking</p>
                <p><strong>Ideal for:</strong> Those aiming to increase muscle mass, strength, or athletic performance.</p>
                <p><strong>Why:</strong> A calorie surplus is necessary for optimal muscle growth and performance gains.</p>

                <p>Cutting</p>
                <p><strong>Ideal for:</strong> Those aiming for a leaner physique, muscle definition, or weight loss.</p>
                <p><strong>Why:</strong> A calorie deficit will help shed excess fat, revealing muscle underneath.</p>

                <br></br><br></br><br></br><br></br>
                <h3>What is Your Current Weight</h3>

                <p>Bulking</p>
                <p><strong>Ideal for:</strong> Those who are underweight or at a healthy weight.</p>
                <p><strong>Why:</strong> Gaining weight through muscle mass is generally healthier and more sustainable.</p>

                <p>Cutting</p>
                <p><strong>Ideal for:</strong> Those who are overweight or at the higher end of a healthy weight range.</p>
                <p><strong>Why:</strong> Losing excess weight can improve health markers and athletic performance.</p>
                <br></br><br></br><br></br><br></br>

                <h3>What Is Your Current Training Style</h3>

                <p>Bulking</p>
                <p><strong>Ideal for:</strong> Strength training, powerlifting, or hypertrophy-focused routines.</p>
                <p><strong>Why:</strong> These training styles are conducive to muscle growth, which is the primary goal of bulking.</p>

                <p>Cutting</p>
                <p><strong>Ideal for:</strong> Cardio-intensive routines, circuit training, or high-intensity interval training (HIIT).</p>
                <p><strong>Why:</strong> These training styles are effective for burning calories and shedding fat.</p>
                <br></br><br></br><br></br><br></br>
                <h3>Summary</h3>
                <p><strong>Bulking:</strong> Opt for bulking if you have lower body fat, aim to gain muscle, are underweight or at a healthy weight, and prefer strength training.</p>
                <p><strong>Cutting:</strong> Choose cutting if you have higher body fat, aim for a leaner physique, are overweight, and prefer cardio or circuit training.</p>

            </Container>
            
            <Break></Break>
            <Particle />
        </Container >
    );
}

export default BulkingvsCutting;