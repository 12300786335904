import React from "react";
import { Button, Container, Offcanvas, Table, Dropdown } from "react-bootstrap";
import Particle from "../Particle";
import { useState } from 'react';
import { CgFileDocument } from "react-icons/cg";
import NavItemComponent from "./NavItemComponent";
import Break from "./Break"
import ScrollToTop from "../ScrollToTop";


function Library({ name, ...props }) {

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }
    window.addEventListener("scroll", scrollHandler);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);
    
    return (
        <Container fluid className="about-section">

            <Container>
                <h1>Welcome to The Gym Community Library!</h1>
                <br></br>
                <h2 className="head2"> Here you will find resources you need on various topics from our server including:</h2>
                <br></br>
                <Table>
                    <tr>
                        <th>
                            <Button variant="primary" onClick={handleShow} className="me-2">
                                Table of Contents
                            </Button>
                            <Offcanvas
                                show={show}
                                onHide={handleClose}
                                scroll={true}
                                backdrop={false}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Welcome to The Gym Community Library!</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '325px', backgroundColor: '#5F3787' }}>
                                                <CgFileDocument style={{ marginBottom: "2px" }} /> General Education
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/readLabel"
                                                    label="Reading a Nutritional Label"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/StayHydro"
                                                    label="The Essentials of Staying Hydrated"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/TrainingTerms"
                                                    label="Key Training Terms Explained"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/RunningLifting"
                                                    label="Running and Lifting"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/RecoveryTime"
                                                    label="Recovery Time During Sets"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item> */}
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/RepRangeMus"
                                                    label="Best Rep Range for Muscle Growth"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item> */}
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/SleepRec"
                                                    label="Sleep Recommendations"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/EquipmentRec"
                                                    label="Equipments Recommendations"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item> */}
                                             {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/IncreaseAthleticism"
                                                    label="Increase Athleticism"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item> */}
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/TrainingUniversals"
                                                    label="Training Universals"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/TrainingFailure"
                                                    label="Training for Failure"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item> */}
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/ProperForm"
                                                    label="Proper Form"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item> */}
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/BuildBench"
                                                    label="Building a Better Bench"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/BuildSquat"
                                                    label="Building a Better Squat"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item> */}
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/genEd/BuildDeadLift"
                                                    label="Building a Better Deadlift"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item> */}

                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <br></br>
                                    {/* Mobility Button */}
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '325px', backgroundColor: '#5F3787' }}>
                                                <CgFileDocument style={{ marginBottom: "2px" }} /> Mobility
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                        <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/mobility/FlexibilityRoutines"
                                                    label="Flexibility Routines"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                                                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/mobility/MobilityRoutines"
                                                    label="Mobility Routines"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/mobility/SelfMyoRelease"
                                                    label="Self Myofascial Release"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/mobility/ElbowWristPain"
                                                    label="Elbow and Wrist Pain"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/mobility/TennisElbow"
                                                    label="Tennis Elbow"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/mobility/Flexibility"
                                                    label="Flexibility"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/mobility/Stretching"
                                                    label="Static vs. Dynamic Stretching"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/mobility/Yoga"
                                                    label="Yoga"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <br></br>
                                    {/* Hypertro start */}
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '325px', backgroundColor: '#5F3787' }}>
                                                <CgFileDocument style={{ marginBottom: "2px" }} /> Hypertrophy
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/hypertro/Hypertrophy101"
                                                    label="Hypertrophy 101"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/hypertro/PrinciplesOfHyper"
                                                    label="Principles of Hypertrophy training"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/hypertro/HypertrophyProgramming"
                                                    label="Hypertrophy Programming"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* Hypertro end */}
                                    <br></br>
                                    {/* Streng start */}
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '325px', backgroundColor: '#5F3787' }}>
                                                <CgFileDocument style={{ marginBottom: "2px" }} /> Strength
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Streng/Strength101"
                                                    label="Strength 101"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Streng/StrengthOverload"
                                                    label="Overload Requirement For Strength"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Streng/SevournTips"
                                                    label="Sevourn's Powerlifting Advice"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Streng/SBDVariations"
                                                    label="SBD Variations"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Streng/DifferentPowerliftingTechniques"
                                                    label="SBD Techniques"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* Streng end */}
                                    <br></br>
                                    {/* Mindfulness start */}
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '325px', backgroundColor: '#5F3787' }}>
                                                <CgFileDocument style={{ marginBottom: "2px" }} /> Mindfulness
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/CommunityCreatedBooklist"
                                                    label="Community Created Booklist"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/ControllingYourEmotions"
                                                    label="Controlling Your Emotions"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/Discipline"
                                                    label="Discipline"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/HowToLoseGracefully"
                                                    label="How To Lose Gracefully"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/LearningToPlanMoreEfficiently"
                                                    label="Learning To Plan More Efficiently"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/Mindfulness101"
                                                    label="Mindfulness 101"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/OvercomingProcrastination"
                                                    label="Overcoming Procrastination"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/Resources"
                                                    label="Resources"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/WhoAmI"
                                                    label="Who Am I?"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/Mindfulness/Willpower"
                                                    label="Willpower"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* Mindfulness end */}
                                    <br></br>
                                    {/* Diet start */}
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '325px', backgroundColor: '#5F3787' }}>
                                                <CgFileDocument style={{ marginBottom: "2px" }} /> Diet
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/bulkingvscutting"
                                                    label="Bulking Vs. Cutting"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/mealsizeandtiming"
                                                    label="Meal Size and Timing"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/calculatingnutritionalvalues"
                                                    label="Calculating Nutritional Values"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/carbs"
                                                    label="Carbs"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/fats"
                                                    label="Fats"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item> */}
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/protein"
                                                    label="Protein"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item> */}
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/cookbooks"
                                                    label="Cookbooks"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/diet101"
                                                    label="Weightloss Guide"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/dietresources"
                                                    label="Dieting Resources"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item> */}
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/supplementation"
                                                    label="Supplementation"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item> */}
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/teendieting"
                                                    label="Teen Dieting"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/diet/trackingweight"
                                                    label="Tracking Weight"
                                                    updateExpanded={updateExpanded}
                                                />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* Diet end */}
                                    <br></br>
                                    {/* Injury Prevention start */}
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '325px', backgroundColor: '#5F3787' }}>
                                                <CgFileDocument style={{ marginBottom: "2px" }} /> Injury Prevention
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/injury/nutritionhydration"
                                                    label="Nutrition and Hydration"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/injury/balanceworkoutmuscle"
                                                    label="Balancing workouts and muscle groups"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/injury/warmupcooldown"
                                                    label="Warming Up and Cooling Down"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/injury/resting"
                                                    label="Resting when needed"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/injury/mobilityFlexibility"
                                                    label="Prioritizing Mobility and Flexibility"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/injury/progressionandperiodization"
                                                    label="Gradual Progression and Periodization"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/injury/properFormAndTechnique"
                                                    label="Proper Form and Technique"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>


                                    {/* Programs Button */}
                                    <br />
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '325px', backgroundColor: '#5F3787' }}>
                                                <CgFileDocument style={{ marginBottom: "2px" }} /> Programs
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/programs/strength"
                                                    label="Premade programs for Strength"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            {/* <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/programs/hypertrophy"
                                                    label="Premade programs for Hypertrophy"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/programs/calisthenics"
                                                    label="Premade programs for Calisthenics"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/programs/AtHome"
                                                    label="Premade programs for At-Home"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item>
                                            <Dropdown.Item>
                                                <NavItemComponent
                                                    to="/library/content/programs/Specializations"
                                                    label="Premade programs for Specializations"
                                                    updateExpanded={updateExpanded}
                                                /></Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </th>
                    </tr>
                </Table>
                <Break></Break>
                <Container>
                    <h2 id="0">What is included in our general education section?</h2>
                    <p>The "General Education" dropdown menu is a comprehensive resource that covers a wide array of topics essential for anyone interested in fitness and well-being. The first set of topics includes "Reading a Nutritional Label," which helps you understand the information on food packaging, and "The Essentials of Staying Hydrated," offering guidelines on proper hydration. "Key Training Terms Explained" demystifies the jargon often encountered in fitness literature, while "Running and Lifting" discusses the synergies and challenges of combining these two forms of exercise. "Recovery Time During Sets" and "Best Rep Range for Muscle Growth" delve into the specifics of workout planning, providing insights on how to optimize your gym sessions for maximum results.</p>
                    <br></br>
                    <p>The second set of topics focuses more on specialized knowledge and advanced techniques. "Sleep Recommendations" provides guidelines on how much rest is needed for optimal performance and recovery. "Equipment Recommendations" offers advice on selecting the right gear for your workouts. Topics like "Increase Athleticism" and "Training Universals" offer broader perspectives on fitness, while "Training for Failure" discusses the pros and cons of pushing your limits during workouts. Finally, the section rounds off with detailed guides on improving specific lifts, including "Building a Better Bench," "Building a Better Squat," and "Building a Better Deadlift." These topics, along with many others, make this section a one-stop-shop for fitness education.</p>

                </Container>
                <Break></Break>
                <Container>
                    <h2 id="1">What is included in our mobility section?</h2>
                    <p>The dropdown menu in the mobility section offers a variety of topics aimed at improving physical flexibility, preventing injuries, and enhancing overall well-being. For example, "Mobility 101" serves as an introductory guide to understanding the basics of mobility and its importance in daily life. "Self Myofascial Release" focuses on techniques for releasing muscle tightness, which can be particularly beneficial for those who engage in regular physical activity. "Injury Prevention" provides valuable tips and exercises to minimize the risk of common injuries, helping you to maintain a consistent fitness routine. Lastly, "Static vs. Dynamic Stretching" explores the differences between these two types of stretching, allowing you to choose the most effective method for your needs. These topics, along with others like "Elbow and Wrist Pain," "Flexibility," and "Yoga," offer a comprehensive guide to mobility and physical health.</p>
                </Container>
                <Break></Break>
                <Container>
                    <h2 id="3">What is included in our premade programs section?</h2>
                    <p>The dropdown menu in the "Premade Programs" section offers a curated selection of fitness programs tailored to specific goals and environments. For example, "Premade programs for Strength" focuses on routines designed to increase your overall strength and power. "Premade programs for Hypertrophy" targets muscle growth through specialized workout plans. "Premade programs for Calisthenics" offers bodyweight exercises that improve functional strength and flexibility. Lastly, "Premade programs for At-Home" provides convenient workout routines that can be done in the comfort of your own home. These options, along with "Premade programs for Specializations," which may cover niche or advanced fitness goals, offer a comprehensive resource for anyone looking to follow a structured fitness program.</p>
                    <br></br>
                    <p>And if you need more of a breakdown checkout the AI personal trainer!</p>
                </Container>
                <Break></Break>
                <Container>
                    <h2 id="4">What is included in our diet section?</h2>
                    <p>Our diet section includes range of topics to help you navigate the complexities of diet and nutrition. For instance, "Bulking Vs. Cutting" delves into the strategies for either gaining muscle mass or shedding fat, helping you align your food choices with your fitness goals. "Calculating Nutritional Values" offers insights into how to accurately measure the nutritional content of your meals, which is crucial for anyone serious about their diet. The section on "Protein" educates you on the importance of this macronutrient in muscle repair and overall health. Additionally, "Tracking Weight" provides valuable tips on how to monitor your weight effectively, ensuring that you are on the right path to achieving your fitness objectives. These topics, along with many more, offer a comprehensive guide to dieting and nutrition.</p>

                </Container>
                <ScrollToTop />
            </Container>
            <Particle />
        </Container >
    );
}

export default Library;
