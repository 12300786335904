import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ProgramEmbed from './ProgramEmbed';

function ProgramAccordion(props){

  const headerStyle = {
    backgroundColor: '#5F3787', // Deep purple color
    color: 'white', // White text color
  };

  const bodyStyle = {
    backgroundColor: '#5F3787', // Deep purple color
    color: 'white', // White text color
    padding: '1rem',
  };

  // If the URL comes from a prop or local state, your setup could be as follows:
  const [sheetUrl, setUrl] = useState(props.sheetUrl);

  // If the URL can change, you might respond to that change like this:
  useEffect(() => {
    if (props.url !== sheetUrl) {
      setUrl(props.sheetUrl);
    }
  }, [props.sheetUrl, sheetUrl]);



  return (
    <Accordion defaultActiveKey="1">
      <Accordion.Item eventKey="0">
        <Accordion.Header style={headerStyle}>View Program</Accordion.Header>
        <Accordion.Body style={bodyStyle}>
        <ProgramEmbed sheetUrl={sheetUrl}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default ProgramAccordion;