import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";


const SleepRec = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
 
    return (
        <Container fluid className="about-section">   
            <Container>
                <h1 className="title">Sleep Importance for Muscle Growth?</h1>
                <p>Learn the importance of sleep for your workouts and muscle growth.</p>

                <br></br><br></br>
                </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Sleep Importance for Muscle Growth?</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">Why is Sleep Important for Muscle Growth?</a>
                    <br /><br />
                    <a href="#1">Sleep recommendations from the gym community</a>
                     
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <h2 ><Break id="0" /><strong>Why is Sleep Important for Muscle Growth?</strong></h2><br />
                <p><strong>Protein Synthesis and Hormone Release:</strong> During sleep, the body repairs and grows through protein synthesis. Deep sleep stages are crucial for the release of growth hormone, essential for muscle development.</p>

                <p><strong>Cortisol Levels:</strong> Sleep regulates cortisol, a stress hormone. High cortisol levels, often a result of poor sleep, can inhibit muscle growth by impacting protein synthesis and promoting muscle breakdown.</p>

                <p><strong>Energy Restoration:</strong> Sleep replenishes energy levels and glycogen stores, vital for high-intensity workouts. Well-rested muscles show better performance and endurance, key for workouts that stimulate muscle growth.</p>

                <p><strong>Recovery and Repair:</strong> Sleep allows the body to repair microtears in muscle fibers caused by exercise. This repair process is critical for muscle strengthening and growth.</p>

                <p><strong>Mental Health and Motivation:</strong> Quality sleep is crucial for cognitive functions and mood. Lack of sleep can reduce motivation and focus, affecting exercise and dietary commitments necessary for muscle growth.</p>

                <p><strong>Reduced Injury Risk:</strong> Adequate sleep reduces fatigue, improving concentration and exercise form, thus decreasing the risk of injuries. Consistent training without injuries is essential for muscle development.</p>

                <p><strong>Insulin Sensitivity:</strong> Sleep affects insulin sensitivity. Insufficient sleep can lead to reduced insulin sensitivity, impairing the body's ability to use glucose and amino acids for muscle repair and growth.</p>

                
            </Container>
            <Container>
                <h2 ><Break id="1" /><strong>Sleep recommendations from the gym community</strong></h2><br />
                <p>Set a period for relaxation before bed at least 30 minutes and avoid using your electronics:</p>
                <ul>
                    <li>It is important for your body to dissociate from all the excitement that goes on day to day.</li>
                    <li>Your body needs time to process and decompress before bed.</li>
                    <li>Avoid using your electronics.</li>
                </ul>

                <p>Wake up at the same time every morning regardless of the time you go to bed.</p>
                <p>This will begin to regulate your circadian rhythm. A circadian rhythm, or circadian cycle, is a natural, internal process that regulates the sleep–wake cycle and repeats roughly every 24 hours.</p>

                <p>Shoot for 7-9 hours of quality sleep.</p>
                <p>This is a range because everybody has a different genetic requirement so experiment and see how much sleep is right for you.</p>

                <p>Avoiding stims - Caffeine.</p>
                <p>Recommendation is to avoid any and all caffeine consumption 6 hours prior to sleep. Even if you are able to fall asleep, the presence of caffeine in your system will affect the effectiveness of your rest.</p>
                <br></br>
                <h3>Other Notes or Suggestions</h3>
     
                    <p>Avoid melatonin dependancy esp as a teenager. Melatonin as a supplement will only help you fall asleep not stay asleep and you may still find yourself waking in the middle of the night. If they also don't help then speak to a sleep specialist.</p>
                    <p>Eat and shower before bed. Some people find comfort in being warm and full when they head to bed but this is another area where results may vary.</p>
                    <p>Tea can be a helpful addition to a bedtime routine. Magnolia, Lavender, Camomile, Valerian Root.</p>
                
            </Container>            
            <Break />
            <Particle />
        </Container>
    );
}


export default SleepRec;