import React from "react";
import {Container, Offcanvas, Button} from "react-bootstrap";
import Particle from "../../../Particle";
import {useState} from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import {CgFileDocument} from "react-icons/cg";
import Break from "../../Break";

const Mindfulness101 = ({name, ...props}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">Mindfulness 101</h1>
            <br></br>
            <Button variant="primary"
                onClick={handleShow}
                className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Mindfulness 101</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link}
                        to="/library"
                        onClick={
                            () => setShow(false)
                    }>
                        <CgFileDocument style={
                            {marginBottom: "2px"}
                        }/>
                        Go Back To Library
                    </Nav.Link>
                    <a href="#understanding-mindfulness">Understanding Mindfulness</a>
                    <br /><br />
                    <a href="#benefits-of-mindfulness">Benefits of Mindfulness</a>
                    <br /><br />
                    <a href="#mindfulness-practices">Mindfulness Practices</a>
                    <br /><br />
                    <a href="#incorporating-mindfulness">Incorporating Mindfulness into Daily Life</a>
                    <br /><br />
                    <a href="#overcoming-challenges">Overcoming Challenges in Mindfulness Practice</a>
                    <br /><br />
                    <a href="#further-resources">Further Resources</a>

                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>
                <h2 id="understanding-mindfulness">Understanding Mindfulness</h2>

                <p>Definition of Mindfulness</p>
                <p>Rooted in Buddhist meditation, mindfulness in modern times is the practice of being fully present and engaged in the moment, aware of your thoughts and feelings without distraction or judgment.</p>

                <p>Principles of Mindfulness</p>
                <ul>
                    <li>Awareness: Being fully aware of what is happening both inside and around you.</li>
                    <li>Non-judgmental Observation: Observing thoughts and emotions without labeling them as good or bad.</li>
                    <li>Living in the Present: Focusing on the current moment, rather than dwelling on the past or worrying about the future.</li>
                </ul>
                <br></br>
                <h2 id="benefits-of-mindfulness">Benefits of Mindfulness</h2>

                <p>Mental Health</p>
                <p>Reduces stress and anxiety, and enhances emotional health and resilience.</p>

                <p>Physical Health</p>
                <p>Can lower blood pressure, improve sleep, boost the immune system, and increase pain tolerance.</p>

                <p>Cognitive Benefits</p>
                <p>Improves attention, concentration, and decision-making; enhances creativity and problem-solving skills.</p>

                <p>Emotional Well-being</p>
                <p>Promotes a greater sense of happiness and well-being; helps in developing empathy and understanding.</p>
                <br></br>
                <h2 id="mindfulness-practices">Mindfulness Practices</h2>
                <p>Meditation</p>
                <ul>
                    <li>Guided Meditation: Following a guided meditation using apps or audio.</li>
                    <li>Unguided Meditation: Sitting quietly and focusing on thoughts, sounds, sensations.</li>
                </ul>

                <p>Mindful Breathing</p>
                <p>Focusing solely on the act of breathing, observing each inhale and exhale.</p>

                <p>Body Scan</p>
                <p>Paying attention to different parts of the body, noticing any sensations.</p>

                <p>Mindful Observation</p>
                <p>Selecting an object within your environment and focusing on it intensely.</p>

                <p>Mindful Listening</p>
                <p>Listening to sounds and conversations without forming judgments or getting distracted.</p>
                <br></br>
                <h2 id="incorporating-mindfulness">Incorporating Mindfulness into Daily Life</h2>

                <p>Mindful Eating</p>
                <p>Eating slowly and savoring each bite, being fully present during meals.</p>

                <p>Mindful Walking</p>
                <p>Walking slowly and deliberately, noticing the sensations of movement and the environment.</p>

                <p>Mindful Communication</p>
                <p>Being fully present in conversations, listening actively without judgment.</p>

                <p>Mindful Work</p>
                <p>Engaging fully with work tasks, taking regular breaks to maintain focus.</p>
                <br></br>
                <h2 id="overcoming-challenges">Overcoming Challenges in Mindfulness Practice</h2>

                <p>Dealing with Distractions</p>
                <p>Accepting that distractions are normal and gently refocusing attention.</p>

                <p>Consistency</p>
                <p>Setting a regular time and place for mindfulness practices.</p>

                <p>Patience</p>
                <p>Understanding that benefits come with time and regular practice.</p>
                <br></br>
                <h2 id="further-resources">Further Resources</h2>

                <p>Books</p>
                <p>"Wherever You Go, There You Are" by Jon Kabat-Zinn, "The Miracle of Mindfulness" by Thich Nhat Hanh.</p>

                <p>Apps</p>
                <p>Headspace, Calm</p>

                <p>Courses</p>
                <p>Mindfulness-Based Stress Reduction (MBSR) programs; online courses on platforms like Coursera or Udemy.</p>

            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default Mindfulness101;
