import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

function TennisElbow({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
    
    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Tennis Elbow</h2>
                <p>Here is some self-care advice from our favorite wellness website for those dealing with tennis elbow.</p>
                
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Tennis Elbow</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">Self-Care for Tennis Elbow</a>
                    <br /><br />
                    {/* <a href="#1">h2 Name</a>
                    <br /><br />
                    <a href="#2">h2Name</a>
                    <br /><br />
                    <a href="#3">h2Name</a>
                    <br /><br />
                    <a href="#4">h2Name</a>
                    <br /><br />
                    <a href="#5">h2Name</a>   */}
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <h2 ><Break id="0" /><strong>Self-Care for Tennis Elbow</strong></h2><br />
                <p>Here is some self-care advice from our favorite wellness website for those dealing with tennis elbow.</p>
                <ul>
                    <li><p><strong>Rest your fingers, wrist, and forearm muscles:</strong> This allows your tendon to heal. Stop any activities that may be causing your elbow pain and soreness.</p></li>
                    
                    <li><p><strong>Try ice or heat:</strong> Use ice or cold packs for 10 to 15 minutes at a time, several times a day, as soon as you notice pain. After the first 3 days, you can try heat, or alternating heat and ice.</p></li>
                    
                    <li><p><strong>Try using a brace:</strong> Wear a counterforce brace during activities that require grasping or twisting arm movements.</p></li>
                    
                    <li><p><strong>Try elevating your elbow:</strong> This may help ease pain and reduce swelling in your wrist or forearm.</p></li>
                    
                    <li><p><strong>Try non-prescription medicine:</strong> NSAIDs like aspirin, ibuprofen, and naproxen help reduce pain and inflammation. Acetaminophen can also help with pain.</p></li>
                    
                    <li><p><strong>Do warm-up and stretching exercises:</strong> They may help keep your tendons from getting stiff. If you have any pain, stop the exercises.</p></li>
                    
                    <li><p><strong>Start exercising gradually:</strong> When your pain is gone, begin with stretching and strengthening exercises, and then gradually increase these exercises. Learn the correct techniques and use the best equipment for your activities.</p></li>
                </ul><br /><br />

                <p><strong>For more info on Tennis Elbow and more indepth analysis check out the full article on:  <a href="https://myhealth.alberta.ca/health/pages/conditions.aspx?Hwid=hw225372#hw225467"> My Health Alberta</a></strong></p>
                
            </Container>
            <Break />
            <Particle />
        </Container>
    );
}

export default TennisElbow;