import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

function MobilityFlexibility({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

    const openDiscord = () => {
        window.open("https://discord.com/invite/cP3E9FrMCn", "_blank");
    };

    return (
        <Container fluid className="about-section">
            <Container>
                <h2>The Role of Mobility and Flexibility in Injury Prevention and Exercise Performance ft Project Reign</h2>
                <p>Prioritizing mobility and flexibility in your training routine is crucial for maintaining proper joint function, preventing injuries, and enhancing overall exercise performance. Incorporating exercises such as dynamic stretching, yoga, and foam rolling can improve joint range of motion and muscle flexibility, reducing the risk of strains and sprains. This article will discuss the benefits of prioritizing mobility and flexibility, types of exercises that can help improve these components, and practical tips for incorporating them into your training program.</p>
                
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Button variant="primary" onClick={openDiscord}>
                Check out Kyle's Project Reign
            </Button>

            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Injury Prevention: Prioritizing Mobility and Flexibility</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">Benefits of Mobility and Flexibility</a>
                    <br /><br />
                    <a href="#1">Types of Exercises to Improve Mobility and Flexibility</a>
                    <br /><br />
                    <a href="#2">Tips for Incorporating Mobility and Flexibility Exercises into Your Training Program</a>
                    <br /><br />
                    <a href="#3">Conclusion</a>
                    <br /><br />
                </Offcanvas.Body>
            </Offcanvas>
            
            <Container>  
                <h2><Break id="0" /><strong>Benefits of Mobility and Flexibility</strong></h2><br />
                <p>Focusing on mobility and flexibility can lead to several benefits, including:</p>
                <ul>
                    <li><p><strong>Reduced injury risk:</strong> Improved joint range of motion and muscle flexibility can reduce the risk of strains, sprains, and other injuries resulting from restricted movement or muscle imbalances.</p></li>
                    <li><p><strong>Enhanced performance:</strong> Greater mobility and flexibility can contribute to improved movement efficiency, force production, and overall exercise performance.</p></li>
                    <li><p><strong>Improved posture and alignment:</strong> Regularly engaging in mobility and flexibility exercises can help maintain proper joint alignment and posture, reducing the risk of pain and dysfunction associated with poor movement patterns.</p></li>
                </ul>
            </Container>
            
            <Container>
                <h2 ><Break id="1" /><strong>Types of Exercises to Improve Mobility and Flexibility</strong></h2><br />
                <p>There are various exercises and techniques that can help improve mobility and flexibility, including:</p>
                <ul>
                    <li><p><strong>Active stretching:</strong> Active stretching is a type of stretching exercise where you assume a position and hold it there solely with the strength of your muscles. Unlike passive stretching where you use an external force such as your hand or a prop to hold the stretch, active stretching relies on engaging the muscles on one side of a joint to stretch the muscles on the opposite side. It helps to improve muscle strength, increase range of motion, and enhance motor performance.</p></li>
                    <li><p><strong>Dynamic stretching:</strong> These stretches involve controlled, fluid movements that take joints and muscles through their full range of motion. Examples include leg swings, arm circles, and hip rotations. Dynamic stretching is best performed during warm-ups before exercise.</p></li>
                    <li><p><strong>Static stretching:</strong> Static stretches involve holding a stretched position for a period of time (typically 15-30 seconds) to improve muscle flexibility. Examples include hamstring stretches, quadriceps stretches, and calf stretches. Static stretching is best performed during cool-downs after exercise.</p></li>
                    <li><p><strong>Yoga:</strong> Yoga combines physical postures, breathing techniques, and mindfulness to improve flexibility, mobility, and overall well-being. Regular yoga practice can help enhance joint range of motion, muscle flexibility, and balance.</p></li>
                    <li><p><strong>Foam rolling:</strong> Also known as self-myofascial release, foam rolling involves using a foam roller or other tools to apply pressure to tight muscles and connective tissues. This technique can help release muscle knots, improve flexibility, and promote recovery.</p></li>
                </ul>
            </Container>
            <Container>
                <h2><Break id="2" /><strong>Tips for Incorporating Mobility and Flexibility Exercises into Your Training Program</strong></h2><br />
                <p>To effectively incorporate mobility and flexibility exercises into your training routine, consider the following tips:</p>
                <ul>
                    <li><p><strong>Schedule dedicated sessions:</strong> Set aside specific time in your training schedule for mobility and flexibility work. This can help ensure that these components are not neglected and are given equal priority alongside other aspects of your training program.</p></li>
                    <li><p><strong>Include mobility work in warm-ups and cool-downs:</strong> Incorporate dynamic stretching during warm-ups and static stretching during cool-downs to improve joint range of motion and muscle flexibility before and after exercise.</p></li>
                    <li><p><strong>Listen to your body:</strong> Pay attention to areas that feel tight or restricted and prioritize mobility and flexibility exercises targeting those areas. This can help address muscle imbalances and prevent potential injuries.</p></li>
                    <li><p><strong>Consult a professional:</strong> Work with a certified fitness professional or physical therapist to identify mobility and flexibility limitations and develop a personalized program to address these issues.</p></li>
                </ul>
            </Container>
            <Container>
                <h2><Break id="3" /><strong>Conclusion</strong></h2><br />
                <p>Prioritizing mobility and flexibility in your training routine is essential for maintaining proper joint function, preventing injuries, and enhancing exercise performance. Incorporating exercises such as dynamic stretching, yoga, and foam rolling can improve joint range of motion and muscle flexibility, reducing the risk of strains and sprains. Schedule dedicated sessions for mobility and flexibility work, include these exercises in your warm-ups and cool-downs, and consult a professional to develop a personalized program tailored to your unique needs and limitations. This will ultimately lead to a more enjoyable, effective, and injury-free exercise experience.</p>
            </Container>
            <Break />
            <ScrollToTop />
            <Particle />
        </Container>
    );
}

export default MobilityFlexibility;