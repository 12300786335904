import React from "react";
import {Container} from "react-bootstrap";
import Particle from "../../../Particle";
import Break from "../../Break";

const Strength101 = ({
    name,
    ...props
}) => {
    return (
        <Container fluid className="about-section">
            <h1 className="title">Strength Training 101</h1>
            <br></br>
            <Container>
                <p>
                    <strong>Specificity: </strong>

                    Training should mimic the demands of the sport or desired outcome as closely as possible.
                                        For powerlifters, this means exercises, intensities, and volumes that reflect the demands of competitive lifts.</p>

                <p>
                    <strong>Overload: </strong>

                    To experience gains in strength, training must present a greater challenge than what the body is accustomed to.
                                            This can be achieved by increasing the weight lifted, the volume of training, or the frequency of training sessions.</p>

                <p>
                    <strong>Fatigue Management: </strong>

                    Proper recovery is essential to minimize fatigue and prevent overtraining.
                                                This principle emphasizes the importance of rest, nutrition, and other recovery strategies.</p>

                <p>
                    <strong>SRA (Stimulus, Recovery, Adaptation): </strong>

                    Following a stimulus (training), there is a period of recovery, after which the body adapts to the new level of stress.
                                                    The goal is to time the next training stimulus once recovery and adaptation have occurred.</p>

                <p>
                    <strong>Variation: </strong>

                    Varying training stimuli over time can help prevent plateaus and continue to challenge the body.
                                                        This might include altering exercises, sets, repetitions, and intensities.</p>

                <p>
                    <strong>Individual Differences: </strong>

                    Individuals will respond differently to the same training stimulus based on genetics, previous training experience, age, and other factors.
                                                            Tailoring the training program to the individual's response is crucial for optimal progress.</p>

                <p>
                    <strong>Reversibility: </strong>

                    If training is stopped or reduced, the gains in strength and muscle mass will revert back over time.
                                                                Maintaining a consistent training schedule is important to continue progressing.</p>

                <p>
                    <strong>Phase Potentiation: </strong>

                    Organizing training into phases where each phase builds upon the previous one, creating a cumulative effect towards peaking for competition or achieving specific goals.</p>
            </Container>
            <Break />
            <Particle />
        </Container>
    );
}

export default Strength101;
