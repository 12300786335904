import React from "react";
import {Container, Offcanvas, Button} from "react-bootstrap";
import Particle from "../../../Particle";
import {useState} from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import {CgFileDocument} from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

const Discipline = ({name, ...props}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Container fluid className="about-section">
            <h1 className="title">A Comprehensive Guide to Discipline</h1>
            <br></br>
            <Button variant="primary"
                onClick={handleShow}
                className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>What Is Discipline</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link}
                        to="/library"
                        onClick={
                            () => setShow(false)
                    }>
                        <CgFileDocument style={
                            {marginBottom: "2px"}
                        }/>
                        Go Back To Library
                    </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>
                <h2 id="understanding-discipline">1. Understanding Discipline</h2>
                <p>Discipline involves training oneself to follow rules or a code of behavior, and it's essential for achieving long-term goals, maintaining focus, and overcoming procrastination and impulse.</p>
                <p>One of our wonderful members, Bodhi, has shared his notes on discipline that he has compiled on his journey:
                    <br />
                    <a href="https://docs.google.com/document/d/11cpnrDSdIcnCHODX-_cX_OkE_X3gCLbu4WSdWf2__wI/edit">Click here to read</a>
                </p>
                <p>Also here is a great video on discipline from one of my favorite self improvement Youtubers:</p><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/9OF06n1jNkM?si=zzcSQq0fkAWkPZ-o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br /><br />
                <h2 id="goal-setting">2. Goal Setting</h2>
                <p>Set SMART (Specific, Measurable, Achievable, Relevant, Time-bound) goals and visualize the benefits and outcomes of achieving these goals.</p><br />

                <h2 id="establishing-routines">3. Establishing Routines</h2>
                <p>Create a daily routine including work, exercise, relaxation, and personal growth. Focus on building one habit at a time for effective habit formation.</p><br />

                <h2 id="overcoming-challenges">4. Overcoming Challenges</h2>
                <p>Deal with procrastination by breaking tasks into smaller parts and manage distractions to enhance focus and productivity.</p><br />

                <h2 id="self-control-willpower">5. Self-Control and Willpower</h2>
                <p>Understand the limits of willpower and use rest, healthy eating, and exercise to recharge it.</p><br />

                <h2 id="consistency-persistence">6. Consistency and Persistence</h2>
                <p>Maintain consistency in routines and track progress through journals, apps, or checklists.</p><br />

                <h2 id="learning-from-failure">7. Learning from Failure</h2>
                <p>View failures as learning opportunities and be willing to change strategies if necessary.</p><br />

                <h2 id="seeking-support">8. Seeking Support</h2>
                <p>Use accountability partners or seek professional help for additional support in maintaining discipline.</p><br />

                <h2 id="reward-motivation">9. Reward and Motivation</h2>
                <p>Set up a reward system for milestones and constantly remind yourself of your goals' 'why'.</p><br />

                <h2 id="continuous-improvement">10. Continuous Improvement</h2>
                <p>Be open to learning and adapting new strategies for discipline and regularly reflect on your progress.</p><br />

                <h2 id="resources-for-learning">Resources for Further Learning</h2>
                <p>Explore books like “Atomic Habits” by James Clear, listen to podcasts like The Tim Ferriss Show, or take online courses on productivity and personal development.</p>


            </Container>
            <Break />
            <ScrollToTop />
            <Particle/>
        </Container>
    );
}

export default Discipline;
