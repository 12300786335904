import React from "react";
import { Container, Offcanvas,Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

function ProperFormAndTechnique({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
    
    const openDiscord = () => {
        window.open("https://discord.com/invite/cP3E9FrMCn", "_blank");
    };

    return (
        <Container fluid className="about-section">
            <Container>
                <h2>The Importance of Mastering Proper Form and Technique in Resistance Training ft. Project Reign</h2>
                <p>
                Proper form and technique are critical for injury prevention and optimal performance in resistance training. Ensuring correct body alignment and movement execution engages the appropriate muscles while minimizing stress on joints and ligaments. Below I discuss methods for learning proper form and technique, including consulting fitness professionals, using dependable online resources, and attending workshops, classes or the obvious - referring to your local discord fitness community.</p>
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Button variant="primary" onClick={openDiscord}>
                Check out Kyle's Project Reign
            </Button>

            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Injury Prevention: Proper Form and Technique ft Project Reign</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">Consult Certified Fitness Professionals</a>
                    <br /><br />
                    <a href="#1">Use Reputable Online Resources</a>
                    <br /><br />
                    <a href="#2">Attend In-person or Online Workshops or Classes</a>
                    <br /><br />
                    <a href="#3">Community Engagement and Learning in Discord Fitness Communities</a>
                    <br /><br />
                    <a href="#4">Conclusion</a>
                    <br /><br />
                    <a href="#5">Elevate Your Fitness Journey by Discovering Two Thriving Communities, & What They Both Collectively Offer!</a>  
                </Offcanvas.Body>
            </Offcanvas>
            
            <Container>
                <h2 ><Break id="0" /><strong>Consult Certified Fitness Professionals</strong></h2>
                <p>Working with a certified fitness professional, such as a personal trainer or strength and conditioning coach, can provide valuable guidance in mastering proper form and technique. These professionals have the expertise to assess your current movement patterns, identify areas for improvement, and provide personalized feedback and instruction.</p>
                <ul>
                    <li><p><strong>Individualized attention and coaching:</strong> A fitness professional can create a tailored training program that addresses your specific needs and goals while ensuring proper exercise execution.</p></li>
                    <li><p><strong>Ongoing support and motivation:</strong> Regular check-ins and progress assessments with a fitness professional can help keep you motivated and accountable, making it more likely that you'll stick to your training program and achieve your desired results.</p></li>
                    <li><p><strong>Access to expert knowledge:</strong> Fitness professionals possess a wealth of information about exercise science, programming, and injury prevention, which can be invaluable in your training journey.</p></li>
                </ul>
            </Container>
            <Container>
                <h2 ><Break id="1" /><strong>Use Reputable Online Resources</strong></h2><br />
                <p>There are numerous online resources available that provide exercise descriptions, instructional videos, and tips for mastering proper form and technique. Some reputable sources include the National Strength and Conditioning Association (NSCA), ExRx.net, and the American Council on Exercise (ACE). Being a millennial myself, I would say that YouTube is also a fantastic source of information when it comes to fitness in general.</p>
                <ul>
                    <li><p><strong>Cross-reference multiple sources:</strong> Verify the accuracy and consistency of the information you find by consulting multiple sources.</p></li>
                    <li><p><strong>Look for credentials:</strong> Seek out resources created by certified professionals or organizations with a strong reputation in the fitness industry.</p></li>
                    <li><p><strong>Focus on quality over quantity:</strong> Choose a few key exercises to master at a time, rather than attempting to learn a large number of new movements all at once.</p></li>
                </ul>
                
            </Container>            
            <Container>
                <h2><Break id="2" /><strong>Attend In-person or Online Workshops or Classes</strong></h2><br />
                <p>Participating in workshops or classes that emphasize technique can be a valuable way to learn proper form and receive feedback from experienced instructors.</p>
                <ul>
                    <li><p><strong>Technique-focused seminars:</strong> These workshops, often led by certified professionals, focus specifically on teaching and refining exercise technique. Participants have the opportunity to practice movements, receive personalized feedback, and ask questions.</p></li>
                    <li><p><strong>Group fitness classes:</strong> Many gyms and fitness studios offer group classes that incorporate resistance training, such as functional fitness, strength training, or circuit training classes. These classes often provide instruction on proper form and technique, with the added benefit of social support and motivation.</p></li>
                    <li><p><strong>Specialty courses:</strong> Some organizations offer specialty courses or certifications focusing on specific training modalities, such as Olympic weightlifting, powerlifting, or kettlebell training. These courses can provide in-depth instruction and hands-on practice of proper form and technique.</p></li>
                </ul>
                
            </Container>            
            <Container>
                <h2><Break id="3" /><strong>Community Engagement and Learning in Discord Fitness Communities</strong></h2><br />
                <p>In part, this is also much of what a Discord Fitness Community can provide too. If there’s ever a Q&A, Info Session, Event, etc.. Basically anything that you can spend some time in, that time is going to be well worth it. Being able to take the engagement and interactive learning found in physical settings to the digital realm, a Discord Fitness Community offers a variety of avenues for improving one's knowledge and technique in resistance training and other forms of exercise.</p>
                <ul>
                    <li><p><strong>Live Q&A and Info Sessions:</strong> Scheduled or impromptu sessions hosted by experts or certified trainers provide a platform for community members to have their specific questions answered.</p></li>
                    <li><p><strong>Virtual Workshops and Classes:</strong> From technique-focused seminars to group fitness classes, these virtual offerings allow members to learn and receive feedback on their form and technique.</p></li>
                    <li><p><strong>Event Announcements:</strong> A space to announce and organize both local or virtual fitness events, challenges, or meetups, encouraging engagement and continuous learning.</p></li>
                    <li><p><strong>Resource Sharing:</strong> A dedicated channel for sharing reputable resources or personal experiences promotes a culture of shared learning and self-improvement.</p></li>
                    <li><p><strong>Video Analysis and Feedback:</strong> Members can upload workout videos for feedback, fostering an environment of constructive criticism and growth.</p></li>
                    <li><p><strong>Motivational Channels:</strong> Channels dedicated to sharing personal achievements or daily motivational posts can help keep the community motivated and engaged.</p></li>
                    <li><p><strong>Nutritional Advice:</strong> Access to general dietary advice or Q&A sessions with nutritionists enhances understanding of nutrition’s role in injury prevention and recovery.</p></li>
                    <li><p><strong>Support Channels:</strong> A space for members to discuss challenges, seek advice, or share their fitness journey fosters a supportive community atmosphere.</p></li>
                    <li><p><strong>Scheduled Challenges:</strong> Regular fitness or nutritional challenges encourage active participation and learning.</p></li>
                    <li><p><strong>Access to Experts:</strong> Having a roster of on-call or active professional trainers, physiotherapists, and nutritionists within the community for quick consultations or advice.</p></li>
                </ul>
                <p>The Discord Fitness Community essentially becomes a hub of knowledge sharing, motivation, and support, accessible to anyone regardless of their location, making it a valuable resource for anyone looking to prevent injuries and improve their training regimen.</p>
                
            </Container>            
            <Container>
                <h2><Break id="4" /><strong>Conclusion</strong></h2><br />
                <p>Mastering proper form and technique is crucial for preventing injuries and optimizing performance in resistance training. By consulting certified fitness professionals, utilizing reputable online resources, and attending workshops or classes, you can learn correct movement execution, engage the appropriate muscles, and minimize stress on joints and ligaments. Investing time and effort into learning proper form and technique will not only enhance your athletic performance but also contribute to a more enjoyable and sustainable training experience.</p>            
            </Container>            
            <Container>
                <h2><Break id="5" /><strong>Elevate Your Fitness Journey by Discovering Two Thriving Communities, & What They Both Collectively Offer!</strong></h2><br />
                <p>Discover a plethora of fitness insights right here in The Gym Community! The <a href="https://discord.com/channels/960727872887222332/1102401063862145064">Form Check</a> channel, for example, is a great spot for real-time feedback on your workout form. For an extended sphere of fitness camaraderie and resources, hop over to my personal Discord Fitness Community, Project Reign, found via my bio. </p>
            </Container>
            <Break />
            <ScrollToTop />
            <Particle />
        </Container>
    );
}

export default ProperFormAndTechnique;