import React from "react";
import {Container} from "react-bootstrap";
import Particle from "../../../Particle";
import Break from "../../Break";

function RunningLifting() {
    return (
        <Container fluid className="about-section">
            <Container>
                <h2>What is included in our RUNNING AND LIFTING section?</h2><br />
                
                <p>Balancing running and lifting as part of a fitness regimen requires careful planning and understanding of how these two forms of exercise complement each other. Begin by establishing a weekly schedule that alternates between running and weight lifting days, ensuring adequate rest periods to prevent overtraining. For instance, you might dedicate three days to running, focusing on varied intensities and distances, and three days to weight lifting, targeting different muscle groups each session. It's crucial to listen to your body and adjust the intensity and frequency based on how you feel.</p>
                <p><strong>Checkout this video below on running and lifting:</strong></p>
                <br></br>
                <br></br>
            </Container>
            <Container>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/XomP1MfJw0c?si=UAJ7SJZIPJ2J43KQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

            </Container>
            <Break></Break>
            <Particle /> 
        </Container>
    );
}

export default RunningLifting;
