import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const TrainingTerms = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
 
    return (
        <Container fluid className="about-section">
            <Container>
                <h1 className="title">Key Training Terms Explained</h1>
                <p>Understanding these terms can help optimize your workouts for better results.</p>

                <br></br><br></br>
                <Button variant="primary" onClick={handleShow} className="me-2">
                    Table of Contents
                </Button>

                <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Key Training Terms Content</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav.Link 
                            as={Link} 
                            to="/library" 
                            onClick={() => updateExpanded(false)}
                        >
                            <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library </Nav.Link>
                            <br />
                            <a href="#0">Rep (Repetition)</a>
                            <br /><br />
                            <a href="#1">Set</a>
                            <br /><br />
                            <a href="#2">Volume</a>
                            <br /><br />
                            <a href="#3">Intensity (Absolute Intensity)</a>
                            <br /><br />
                            <a href="#4">Relative Intensity</a>
                            <br /><br />
                            <a href="#5">MRV (Maximum Recoverable Volume)</a>
                            <br /><br />
                            <a href="#6">SFR (Stimulus-to-Fatigue Ratio)</a>

                    </Offcanvas.Body>
                </Offcanvas>

                
                <Container>
                    <h2><Break id="0"/>Rep (Repetition)</h2><br />
                    <p><strong>Definition:</strong> One complete motion of an exercise, usually consisting of a concentric (lifting) and eccentric (lowering) phase.</p>
                    <p><strong>Importance:</strong> Reps are the building blocks of sets and contribute to the overall training volume.</p>
                </Container>
                
                <Container>   
                    
                    <h2><Break id="1"/>Set</h2><br />
                    <p><strong>Definition:</strong> A group of consecutive repetitions performed without resting.</p>
                    <p><strong>Importance:</strong> Sets are used to structure a workout and are a key factor in determining training volume.</p>
                </Container>
                
                <Container>    
                    
                    <h2><Break id="2"/>Volume</h2><br />
                    <p><strong>Definition:</strong> The total amount of mechanical work done, often calculated as the product of sets, reps, and weight lifted.</p>
                    <p><strong>Importance:</strong> Volume is a key variable in training, affecting both stimulus and fatigue.</p>
                    <p><strong>Technical Measurement:</strong> Sum total of force x distance executed.</p>
                    <p><strong>Practical Measurement:</strong> Sets x Reps x Weight is often used as a reasonable proxy to estimate volume.</p>
                    <p><strong>Note:</strong> Usually compared between the same lifts (e.g., bench vs. bench, squat vs. squat) of the same lifter, making the distance factor less critical.</p>
                </Container>
                
                <Container>
                    
                    <h2><Break id="3"/>Intensity (Absolute Intensity)</h2><br />
                    <p><strong>Definition:</strong> The amount of resistance presented during a movement.</p>
                    <p><strong>Measurement:</strong> Expressed in force, usually in pounds or kilograms.</p>
                    <p><strong>Example:</strong> A 335lb squat for one easy rep has higher intensity than a 315lb squat for 11.5 reps to absolute muscular failure.</p>
                    <p><strong>Common Misuse:</strong> Often confused with "Relative Intensity."</p>
                </Container>
                
                <Container>   
                    
                    <h2><Break id="4"/>Relative Intensity</h2><br />
                    <p><strong>Definition:</strong> Proximity to concentric muscular failure of any given set.</p>
                    <p><strong>Example:</strong> A set of 405 for 3, where you could have done 6, is less relatively intense than a set of 275 for 8, where you could have only got 9 with a gun to your head.</p>
                </Container>
                
                <Container>
                    
                    <h2><Break id="5"/>MRV (Maximum Recoverable Volume)</h2><br />
                    <p><strong>Definition:</strong> The highest amount of training volume that an individual can recover from within a specific time frame.</p>
                    <p><strong>Importance:</strong> Exceeding your MRV can lead to overtraining and hinder performance, while training below it may result in suboptimal gains.</p>
                </Container>
                
                <Container>
                    
                    <h2><Break id="6"/>SFR (Stimulus-to-Fatigue Ratio)</h2><br />
                    <p><strong>Definition:</strong> A metric used to evaluate the effectiveness of an exercise or training protocol in eliciting a desired response (e.g., muscle growth) relative to the fatigue it induces.</p>
                    <p><strong>Importance:</strong> A higher SFR indicates a more efficient exercise, allowing for greater stimulus with less fatigue.</p>
                </Container>
                
            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
    
}

export default TrainingTerms;