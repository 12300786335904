import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

function NutritionHydration({ name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [expand, updateExpanded] = useState(false);
  
  const openDiscord = () => {
    window.open("https://discord.com/invite/cP3E9FrMCn", "_blank");
  };

  return (
    <Container fluid className="about-section">
      <Container>
        <h2><strong>The Role of Nutrition and Hydration in Injury Prevention ft Project Reign</strong></h2><br />
        <p>Proper nutrition and hydration are vital components of a comprehensive injury prevention strategy. A well-balanced diet provides the essential nutrients needed for tissue repair, muscle growth, and optimal performance. Adequate hydration helps maintain blood volume, regulate body temperature, and prevent muscle cramps. This article will explore the importance of nutrition and hydration in injury prevention and provide guidelines for maintaining optimal hydration levels during exercise - In part inspired by{" "}
        <a href="https://www.andygalpin.com/">Dr. Andy Galpin</a> and his{" "}
        <a href="https://www.youtube.com/watch?v=aw8ZqF58chg&t=965s&ab_channel=AndyGalpin">
        guidelines </a>{" "} specific to hydration. </p>
      </Container>
      <br /><br />
      <Button variant="primary" onClick={handleShow} className="me-2">
        Table of Contents
      </Button>

      <Button variant="primary" onClick={openDiscord}>
        Check out Kyle's Project Reign
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        scroll={true}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Injury Prevention: Nutrition and Hydration</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav.Link
            as={Link}
            to="/library"
            onClick={() => updateExpanded(false)}
          >
            <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To
            Library
          </Nav.Link>

          <a href="#1">Nutrition for Injury Prevention</a>
          <br /><br />
          <a href="#2">Hydration for Injury Prevention</a>
          <br /><br />
          <a href="#3">Electrolyte Balance</a>
          <br /><br />
          <a href="#4">Conclusion</a>
          <br /><br />
          <a href="#5">Additional Info On Andy Galpin & His Work</a>
          
        </Offcanvas.Body>
      </Offcanvas>
            
      <Container>
        <h2><Break id="1" /><strong>Nutrition for Injury Prevention</strong></h2><br />
        <p>A well-balanced diet is crucial for maintaining overall health and supporting the body's ability to recover from exercise-induced stress.</p>
        <ul><br />
          <li>
            <p><strong>Key components of a balanced diet include:</strong></p><br />
            <ul>
              <li><p><strong>Protein:</strong> Supports muscle repair and growth. Sources include lean meats, fish, dairy, legumes, and tofu.</p></li>
              <li><p><strong>Carbohydrates:</strong> Provide energy for daily activities and exercise. Sources include whole grains, fruits, and vegetables.</p></li>
              <li><p><strong>Healthy fats:</strong> Aid in inflammation reduction and support overall health. Sources include avocados, nuts, seeds, and olive oil.</p></li>
              <li><p><strong>Micronutrients:</strong> Vitamins and minerals, such as vitamin C, vitamin D, calcium, and zinc, contribute to bone health, immune function, and tissue repair.</p></li>
            </ul>
          </li>
        </ul>
      </Container>
      
      <Container>
        <h2><Break id="2" /><strong>Hydration for Injury Prevention</strong></h2><br />
        <p>Adequate hydration is essential for preventing exercise-related injuries, as it helps maintain blood volume, regulate body temperature, and prevent muscle cramps. Dehydration can lead to reduced physical performance, increased muscle fatigue, and a higher risk of injury. Following Dr. Andy Galpin’s hydration guidelines could significantly bolster injury prevention efforts.</p>
        <ul><br />
          <li>
            <p><strong>General guidelines for hydration include:</strong></p><br />
            <ul>
              <li><p><strong>Daily Fluid Intake:</strong> Dr. Galpin suggests a loose guideline of consuming half your body weight in ounces of fluid daily. For example, if you weigh 200 pounds, aim for 100 ounces of water.</p></li>
              <li><p><strong>Pre-Exercise:</strong> Ensure you're well-hydrated by taking 500mg sodium pre-exercise.</p></li>
              <li><p><strong>During Exercise:</strong> Every 15 minutes, consume (in ounces) your body weight (in pounds) divided by 30 to stay hydrated.</p></li>
              <li><p><strong>Post-Exercise:</strong> Replace the fluid you lose with 125 to 150 percent of the weight you lost in fluid, considering not just water, but electrolytes too.</p></li>
            </ul>
          </li>
        </ul>
      </Container>
      
      <Container>
        <h2><Break id="3" /><strong>Electrolyte Balance</strong></h2><br />
        <p>Electrolytes, such as sodium, potassium, and magnesium, play a crucial role in maintaining proper hydration levels and muscle function. Excessive sweating during exercise can lead to an imbalance in electrolyte levels, which may contribute to muscle cramps, fatigue, and weakness. Dr. Galpin emphasizes the importance of maintaining a balanced electrolyte level, especially during prolonged exercise where he recommends starting with around 300-600 mg/h of sodium.</p>
        <p>To maintain electrolyte balance, consider consuming sports drinks or electrolyte supplements during extended exercise sessions, particularly in hot or humid conditions. Dr. Galpin also highlights the usefulness of sports drinks or hydration powders to meet sodium recommendations.</p>
        <p>Consume a diet rich in fruits, vegetables, whole grains, lean proteins, and dairy products. This can help provide the essential minerals necessary for maintaining electrolyte balance.</p>
      </Container>
      
      <Container>
        <h1><Break id="4" /><strong>Conclusion</strong></h1><br />
        <p>Proper nutrition and hydration are essential components of injury prevention. A well-balanced diet provides the necessary nutrients for tissue repair and optimal performance, while adequate hydration helps maintain blood volume, regulate body temperature, and prevent muscle cramps. Following the insights provided by experts like Dr. Andy Galpin could further enhance our understanding and practices towards achieving better physical well-being and injury prevention.</p>
      </Container>
      <Break id="5" />
      <Container>
        <h2><strong>Additional Info On Andy Galpin & His Work</strong></h2><br />
        <ul>
          <li><p><a href="https://www.andygalpin.com/">His Website</a></p></li>
          <li><p><a href="https://www.youtube.com/watch?v=aw8ZqF58chg&t=976s&pp=ygUVYW5keSBnYWxwaW4gaHlkcmF0aW9u">3 Steps To Optimizing Hydration</a></p></li>
          <li><p><a href="https://www.youtube.com/watch?v=cILplEggl4I&pp=ygUVYW5keSBnYWxwaW4gaHlkcmF0aW9u">
                Optimizing Hydration for Athletes Pt 1</a></p></li>
          <li><p><a href="https://www.youtube.com/watch?v=G-tQ153n91Y&pp=ygUVYW5keSBnYWxwaW4gaHlkcmF0aWE">
                Optimizing Hydration for Athletes Pt 2</a></p></li>
          <li><p><a href="https://www.youtube.com/playlist?list=PLPNW_gerXa4N_PVVoq0Za03YKASSGCazr">
                Andrew Huberman x Andy Galpin Guest Series</a></p></li><br />
          <li><h6>Note: This series covers a wide range of topics about fitness, health, hydration, programming, nutrition & supplementation.</h6></li>
        </ul>
      </Container>
      <Break />
      <ScrollToTop />
      <Particle />
    </Container>
  );
}

export default NutritionHydration;
