import React from "react";
import {Container} from "react-bootstrap";
import Particle from "../../../Particle";

function TrainingUniversals() {
    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Training Universals</h2>
                <br></br>
                        <strong>Great technique</strong>:
                        <ul>
                            <li>This refers to the importance of executing exercises with the correct form. Proper technique ensures the targeted muscles are effectively engaged and reduces the risk of injury.</li>
                        </ul>
                        <br></br>
                        <strong>High SFR exercises</strong>:
                        <ul>
                            <li>SFR stands for Stimulus to Fatigue Ratio. High SFR exercises are those that provide a significant muscular stimulus (i.e., muscle growth potential) relative to the fatigue they produce. Choosing exercises with a higher SFR ensures that you're getting the most bang for your buck in terms of muscle growth vs. recovery needed.</li>
                        </ul>
                        <br></br>
                        <strong>Rep ranges that give higher SFRs</strong>:
                        <ul>
                            <li>Different rep ranges can produce different stimuli for the muscles. The key is to identify which rep ranges provide the best stimulus for muscle growth relative to the fatigue produced.</li>
                            <li>Additionally, using a diversity of rep ranges can be beneficial. For example, sometimes lifting heavy with lower reps and other times lifting lighter weights with higher reps.</li>
                        </ul>
                        <br></br>
                        <strong>Per-muscle frequency of 2+ per week</strong>:
                        <ul>
                            <li>This suggests training each muscle group at least twice a week for optimal results.</li>
                            <li>Training a muscle more frequently can lead to increased muscle protein synthesis, potentially leading to faster muscle growth.</li>
                        </ul>
                        <br></br>
                        <strong>At least 4x a week total sessions for best results</strong>:
                        <ul>
                            <li>For those who are more serious or advanced in their training, increasing the overall number of training sessions per week can lead to better results.</li>
                        </ul>
                        <br></br>
                        <strong>From 3 to 0 RIR</strong>:
                        <ul>
                            <li>RIR stands for Reps in Reserve. It's a measure of how close to failure you're pushing yourself in a given set. For instance, if you stop a set with 2 RIR, that means you could have done 2 more reps before reaching failure.</li>
                            <li>Training between 3 to 0 RIR means pushing yourself close to, but not necessarily to, failure. This ensures a high level of muscular stimulus without overly taxing the body.</li>
                        </ul>
                        <br></br>
                        <strong>From MEV to MRV</strong>:
                        <ul>
                            <li>MEV stands for Minimum Effective Volume, which is the least amount of work needed to see progress.</li>
                            <li>MRV stands for Maximum Recoverable Volume, which is the most amount of work you can do and still recover from.</li>
                            <li>Training between these volumes ensures you're doing enough work to grow but not so much that recovery becomes impossible.</li>
                        </ul>
            </Container>
            <Particle/>
        </Container>
    );
}
export default TrainingUniversals;
