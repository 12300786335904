import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";




function TrackingWeight({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Scale Success: How and When to Weigh Yourself for Maximum Accuracy</h2>
                <p>Stepping onto the scale can be a little scary and confusing sometimes. But, when done correctly, it's a powerful tool in your journey toward health and wellness. In "Scale Success: How and When to Weigh Yourself for Maximum Accuracy," we delve into the nuances of weight tracking. From the significance of consistent weigh-in timing to understanding the mysteries of weight fluctuation, we'll guide you through essential weight tracking tips and methods. This article will equip you with the knowledge to make accurate weight measurements a part of your routine, highlighting the benefits of daily weigh-ins while helping you interpret what the numbers really mean</p>
            </Container>
            <br></br><br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Tracking Weight</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library  </Nav.Link>
                    <a href="#1">The Importance of Consistent Weigh-In Timing</a>
                    <br></br>
                    <br></br>
                    <a href="#2">Key Weight Tracking Tips for Accuracy</a>
                    <br></br>
                    <br></br>
                    <a href="#3">Understanding Daily Weigh-In Benefits</a>
                    <br></br>
                    <br></br>
                    <a href="#4">Navigating Weight Fluctuation Understanding</a>
                    <br></br>
                    <br></br>

                </Offcanvas.Body>
            </Offcanvas>
            <Break id="1"></Break>
            <Container>
                <h2>The Importance of Consistent Weigh-In Timing</h2>
                <p>Consistency is key when it comes to tracking your weight. Weighing yourself at the same time each day, preferably in the morning, offers a more accurate reflection of your true weight. Morning weigh-ins help eliminate variables such as meals, hydration levels, and clothing that can affect the scale reading. By sticking to a routine, you avoid the normal fluctuations that occur throughout the day, making it easier to notice real changes over time.</p>
            </Container>
            <Break id="2"></Break>
            <Container>
                <h2> Key Weight Tracking Tips for Accuracy</h2>
                <p>Effective weight tracking goes beyond just stepping on a scale. It involves understanding the right way to use it. Placement of the scale on a hard, flat surface, avoiding weighing right after a meal or heavy workout, and using the same scale are some of the critical tips we will cover here. These practices ensure you get the most accurate readings from your weigh-ins.</p>
            </Container>
            <Break id="3"></Break>
            <Container>
                <h2>Understanding Daily Weigh-In Benefits</h2>
                <p>Daily weigh-ins can be a powerful tool for weight management. Regular monitoring can keep you accountable and provide immediate feedback on your lifestyle choices. This section discusses how daily tracking can contribute to a better understanding of your body and help in managing your weight more effectively.</p>
            </Container>
            <Break id="4"></Break>
            <Container>
                <h2>Navigating Weight Fluctuation Understanding</h2>
                    <p>A few important things to note when losing or gaining weight.</p>
                    <ul>
                        <li>Weight will always fluctuate throughout the day, especially on a bulk.</li>
                        <br></br>
                        <li>When tracking, make sure it's the same time of day and take the average of 4-6 days throughout the week. This will give you a weekly average.</li>
                        <br></br>
                        <li>When losing weight, the first week will be much greater than the rest, an extra ~5lbs (2kg). This is due to the reduction of carbs and overall food, meaning almost all water weight.</li>
                        <br></br>
                        <li>The same can be said for bulking but opposite. When introducing a surplus, expect a large weight gain in the first week and heavy fluctuation during the day.</li>
                        <br></br>
                        <li>After the first or second week, the loss/gain will be more linear (if the diet is performed correctly) and taper at the end of your fat loss phase.</li>
                        <br></br>
                        <li>More than 1% of body weight (in lbs) a week is too quick for either bulk or cut. If this is the case, reassess, look at diet and activity, and apply corrections.</li>
                    </ul>
            </Container>
            <Particle />
        </Container>
    );
}

export default TrackingWeight;