import React from "react";
import { Container, Offcanvas, Button, Col } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import Stretch1 from "../../../../Assets/Stretch1.jpg";
import Stretch2 from "../../../../Assets/Stretch2.jpg";
import Stretch3 from "../../../../Assets/Stretch3.jpg";
import Stretch4 from "../../../../Assets/Stretch4.jpg";
import Stretch5 from "../../../../Assets/Stretch5.jpg";
import Stretch6 from "../../../../Assets/Stretch6.png";
import Stretch7 from "../../../../Assets/Stretch7.jpg";
import Stretch8 from "../../../../Assets/Stretch8.jpg";

function Flexibility({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Flexibility Training: An In-Depth Guide </h2>
                <p>
                Flexibility training is often undervalued but is essential for athletes and fitness enthusiasts. It offers various benefits and should be a part of any training regimen.</p>
                <p><strong>Source:</strong> <a href="https://www.sport-fitness-advisor.com/flexibilitytraining.html">Sport Fitness Advisor on Flexibility Training</a></p>
                <p><strong>General Rule:</strong></p>
                <ul>
                    <li><p><strong>Dynamic Stretches:</strong> Used for warm-ups.</p></li>
                    <li><p><strong>Static Stretches or PNF Training:</strong> Used to increase range of motion.</p></li>
                </ul>
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Flexibility Training: An In-Depth Guide</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">The Benefits of Flexibility Training:</a>
                    <br /><br />
                    <a href="#1">Types of Flexibility and Stretching:</a>
                    <br /><br />
                    <a href="#2">Additional Resources on Flexibility Training</a>
                    <br /><br />
                    <a href="#3">Flexibility Exercises: A Comprehensive Guide</a>
                    <br /><br />
                    <a href="#4">Upper Body Flexibility Exercises:</a>
                    <br /><br />
 
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <h2 ><Break id="0" /><strong>The Benefits of Flexibility Training:</strong></h2><br />
                <ul>
                    <li><p><strong>Enhanced Performance and Reduced Injury Risk:</strong> Increases joint range of motion, potentially reducing injury risk<sup>(3,4)</sup>.</p></li>
                    <li><p><strong>Muscle Tightness and Injury:</strong> Tight muscles can lead to muscle tears. Dynamic stretching can reduce this risk<sup>(7,8)</sup>.</p></li>
                    <li><p><strong>Sport-Specific Imbalances:</strong> Flexibility training can correct imbalances caused by repetitive motions in sports like tennis or soccer<sup>(9,10)</sup>.</p></li>
                    <li><p><strong>Mobility and Dexterity:</strong> A more flexible athlete can move more easily and efficiently.</p></li>
                    <li><p><strong>Additional Benefits:</strong> Includes increased body awareness and muscle relaxation, which may improve skill acquisition and performance.</p></li>
                </ul>
                
            </Container>
            <Container>
                <h2 ><Break id="1" /><strong>Types of Flexibility and Stretching:</strong></h2><br />
                <ul>
                    <li><p><strong>Dynamic Flexibility:</strong></p>
                    <ul>
                        <li><p>Involves dynamic movements within the joint's full range of motion.</p></li>
                        <li><p>More sport-specific.</p></li>
                    </ul>
                    </li>
                    <li><p><strong>Static Active Flexibility:</strong></p>
                    <ul>
                        <li><p>Stretching an antagonist muscle using the tension in the agonist muscle.</p></li>
                        <li><p>Example: Holding one leg out in front of you.</p></li>
                    </ul>
                    </li>
                    <li><p><strong>Static Passive Flexibility:</strong></p>
                    <ul>
                        <li><p>Holding a stretch using body weight or an external force.</p></li>
                        <li><p>Example: Holding your leg out and resting it on a chair.</p></li>
                    </ul>
                    </li>
                </ul>
                
            </Container>            
            <Container>
                <h2><Break id="2" /><strong>Additional Resources on Flexibility Training</strong></h2><br />
                <h2>How Stretching REALLY Works?</h2>
                <br /><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/zEOkI3xkF4U?si=vWK-iwQjcdGvwRlH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br /><br />
                <h2>How stretching actually changes your muscles - Malachy McHugh</h2><br /><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/g1pb2aK2we4?si=vLEwK-iQgideuJOd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                 
            </Container>
            <Container>
                <h2><Break id="3" /><strong>Flexibility Exercises: A Comprehensive Guide</strong></h2><br />
                <p><strong>Source:</strong> <a href="https://www.sport-fitness-advisor.com/flexibility-exercises.html">Sport Fitness Advisor Staff</a></p>
                <p>Introduction: This compilation targets all major muscle groups. Stretching should be a fundamental part of any exercise program, not just a warm-up activity.</p>
                <p>Research Insights:</p>
                <ul>
                    <li><p><strong>Static vs. Dynamic Stretching:</strong> Recent research suggests static stretching may not be beneficial before training. Dynamic stretching is more appropriate for warm-ups.</p></li>
                </ul><br />
                <p><strong>Types of Stretching:</strong></p>
                <ul>
                    <li><p>For differences between various types of stretching, see the main <a href="#">Flexibility Training section</a>.</p></li>
                </ul>
                <p><strong> When to Perform Static Stretches:</strong></p>
                <ul>
                    <li><p>Ideally, after an exercise session when the body is fully warm.</p></li>
                </ul><br />
                <p><strong>General Guidelines:</strong></p>
                <ul>
                    <li><p>Warm-up thoroughly before stretching.</p></li>
                    <li><p>Stretch to just before the point of discomfort.</p></li>
                    <li><p>Tightness should diminish as you hold the stretch.</p></li>
                    <li><p>Breathe out into the stretch; avoid breath-holding.</p></li>
                    <li><p>Hold each stretch for 10-30 seconds.</p></li>
                    <li><p>Stop if tightness intensifies or pain occurs.</p></li>
                    <li><p>Shake out limbs between stretches.</p></li>
                    <li><p>Complete 2-3 stretches before moving to the next exercise.</p></li>
                </ul>
                
            </Container>            
            <Container>
                <h2><Break id="4" /><strong>Upper Body Flexibility Exercises</strong></h2><br />
                <ul>
                    <li><p><strong>Stretch #1: Shoulder & Chest</strong> - Clasp hands behind back, straighten arms, raise hands, bend forward, and hold.</p>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                        <img
                            src={Stretch1}
                            alt="Stretch1"
                            className="img-fluid"
                        />
                    </Col></li>
                                        
                    <li><p><strong>Stretch #2: Arm Across Chest</strong> - Place one arm across chest, hand on elbow, pull arm towards chest, and hold. Repeat.</p>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                        <img
                            src={Stretch2}
                            alt="Stretch2"
                            className="img-fluid"
                        />
                    </Col></li>
                    <li><p><strong>Stretch #3: Triceps Stretch</strong> - Hand behind back, elbow in air, other hand on elbow, pull towards head, and hold. Repeat.</p>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                        <img
                            src={Stretch3}
                            alt="Stretch3"
                            className="img-fluid"
                        />
                    </Col></li>
                </ul>
                
            </Container>            
            <Container>
                <h2><Break id="5" /><strong>Lower Body Flexibility Exercises</strong></h2><br />
                <ul>
                    <li><p><strong>Stretch #4: Glute Stretch</strong> - Sit, right leg bent, foot over left leg, left arm over right leg, push knee, and hold. Repeat.</p>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                        <img
                            src={Stretch4}
                            alt="Stretch4"
                            className="img-fluid"
                        />
                    </Col></li>
                    <li><p><strong>Stretch #5: Adductor Stretch</strong> - Stand, feet wide, shift weight, bend knee, reach towards extended foot, and hold. Repeat.</p>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                        <img
                            src={Stretch5}
                            alt="Stretch5"
                            className="img-fluid"
                        />
                    </Col></li>
                    <li><p><strong>Stretch #6: Single Leg Hamstring</strong> - One leg straight, bend other, foot into thigh, bend forward, keep back flat, and hold. Repeat.</p>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                        <img
                            src={Stretch6}
                            alt="Stretch6"
                            className="img-fluid"
                            style={{ maxWidth: '110px', height: 'auto' }}
                        />
                    </Col></li>
                    <li><p><strong>Stretch #7: Standing Quadriceps</strong> - Stand, grab ankle, pull heel to buttocks, push hips out, thigh perpendicular to ground, and hold. Repeat.</p>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                        <img
                            src={Stretch7}
                            alt="Stretch7"
                            className="img-fluid"
                        />
                    </Col></li>
                    <li><p><strong>Stretch #8: Standing Calf</strong> - Feet 18 inches apart, back leg straight, heel down, push against wall, and hold. Repeat.</p>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                        <img
                            src={Stretch8}
                            alt="Stretch8"
                            className="img-fluid"
                        />
                    </Col></li>
                </ul>
                <p><strong>Full article:</strong> <a href="https://www.sport-fitness-advisor.com/flexibility-exercises.html">Sport Fitness Advisor on Flexibility Exercises</a></p>
                
            </Container>
            <Break />
            <Particle />
        </Container>
    );
}

export default Flexibility;