import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";


function BalanceWorkoutMuscle({ name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [expand, updateExpanded] = useState(false);

  const openDiscord = () => {
    window.open("https://discord.com/invite/cP3E9FrMCn", "_blank");
  };
  return (
    <Container fluid className="about-section">
      <Container>
        <h2>The Importance of Balancing Workouts and Muscle Groups for Injury Prevention ft Project Reign</h2>
          
        <p>A balanced approach to resistance training and exercise is essential for preventing muscle imbalances and reducing the risk of injury. By targeting all major muscle groups and incorporating exercises that work opposing muscle groups, you can promote overall muscular development, maintain proper joint function, and minimize the stress placed on joints and connective tissues. This article will discuss the benefits of balanced workouts, tips for designing a balanced training program, and examples of exercises to target opposing muscle groups.</p>
      </Container>
      <br /><br />

      <Button variant="primary" onClick={handleShow} className="me-2">
        Table of Contents
      </Button>

      <Button variant="primary" onClick={openDiscord}>
        Check out Kyle's Project Reign
      </Button>
      
      <Offcanvas
        show={show}
        onHide={handleClose}
        scroll={true}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Injury Prevention: Balancing Workouts and Muscle Groups ft Project
          Reign</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav.Link
            as={Link}
            to="/library"
            onClick={() => updateExpanded(false)}
          >
            <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To
            Library
          </Nav.Link>

          <a href="#1">Benefits of Balanced Workouts</a>
          <br /><br />
          <a href="#2">Tips for Designing a Balanced Training Program</a>
          <br /><br />
          <a href="#3">Examples of Exercises Targeting Opposing Muscle Groups</a>
          <br /><br />
          <a href="#4">Conclusion</a>
          <br /><br />
        </Offcanvas.Body>
      </Offcanvas>
        
      <Container>
        <h2><Break id="1" /><strong>Benefits of Balanced Workouts</strong></h2><br />        
        <p>Focusing on balanced workouts that target all major muscle groups can lead to several benefits, including:</p>
        <ul>
          <li><p><strong>Reduced injury risk:</strong> Balanced muscular development helps to maintain proper joint function and prevent muscle imbalances that can place undue stress on joints and connective tissues.</p></li>
          <li><p><strong>Improved performance:</strong> A well-rounded training program promotes overall strength, stability, and movement efficiency, enhancing performance in various sports and activities.</p></li>
          <li><p><strong>Better posture:</strong> Balanced muscular development can contribute to improved posture and reduced risk of postural-related issues, such as lower back pain or rounded shoulders.</p></li>
        </ul>
      </Container>
      
      <Container>
        <h2><Break id="2" /><strong>Tips for Designing a Balanced Training Program</strong></h2><br />
        <p>To ensure that your training program targets all major muscle groups and incorporates exercises for opposing muscle groups, consider the following tips:</p>
        <ul>
          <li><p><strong>Full-body workouts: </strong>Include full-body workouts in your training program, targeting all major muscle groups within each session. This approach can help ensure balanced muscular development and reduce the risk of neglecting specific muscle groups. Coincidentally this is also typically the best approach for beginners.</p></li>
          <li><p><strong>Prioritize compound exercises: </strong>Focus on compound exercises, such as squats, deadlifts, and bench presses, which work multiple muscle groups and joints simultaneously. These exercises promote functional strength and efficient use of training time.</p></li>
          <li><p><strong>Address weaknesses: </strong>Identify and address any muscle imbalances or weaknesses by incorporating targeted strengthening exercises for underdeveloped muscle groups. This can help restore balance and reduce the risk of injury.</p></li>
        </ul>
      </Container>
      
      <Container>
        <h2><Break id="3" /><strong>Examples of Exercises Targeting Opposing Muscle Groups</strong></h2><br />      
        <p>To create a balanced training program, include exercises that target opposing muscle groups. This approach ensures that muscles on both sides of a joint are developed evenly, promoting proper joint function and reducing injury risk.</p>

        <ul>
          <li><p><strong>Chest and back:</strong> Pair exercises such as bench presses or push-ups (chest) with rows or pull-ups (back) to balance the development of the muscles in the upper torso.</p></li>
          <li><p><strong>Quadriceps and hamstrings:</strong> Include exercises like squats and lunges (quadriceps) alongside leg curls and Romanian deadlifts (hamstrings) to promote balanced development of the upper leg muscles.</p></li>
          <li><p><strong>Biceps and triceps: </strong>Balance bicep exercises, such as curls, with triceps exercises like dips or skull crushers to ensure even development of the upper arm muscles.</p></li>
        </ul>

        <p>Generally if you follow an overall program that incorporates all of these movements, you don’t necessarily need to hit each on the same day together as mentioned above. You could just as easily do chest and triceps on one day, back and biceps on another and legs on a third day.</p>
        <p>Make sure to incorporate shoulders and other muscle groups not mentioned in this as well. But the key is to hit all of your main muscle groups thoroughly throughout at least a week by week basis.</p>
      </Container> 

      <Container>
        <h2><Break id="4" /><strong>Conclusion</strong></h2><br />
        <p>Balancing workouts and targeting all major muscle groups is essential for preventing muscle imbalances and reducing the risk of injury. By incorporating a variety of exercises that work opposing muscle groups, you can promote overall muscular development, maintain proper joint function, and minimize stress on joints and connective tissues. Focus on full-body workouts, prioritize compound exercises, and address any muscle imbalances or weaknesses to create a balanced, effective, and safe training program.</p>
      </Container>
      <Break />

      <Particle />
    </Container>
  );
}

export default BalanceWorkoutMuscle;
