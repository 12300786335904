import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import OpenInNewTabButton from "../../OpenInNewTabButton"
import ProgramAccordion from "../../ProgramAccordion";
import Break from "../../Break";

const Strength = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Welcome to Strength Programs</h2>
                <p>Woop</p>
                <Button variant="primary" onClick={handleShow} className="me-2">
                    Table of Contents
                </Button>

                <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Strength Programs</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                            <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                        </Nav.Link>
                    </Offcanvas.Body>
                </Offcanvas>
            </Container>
            <Break></Break>
            <Container>
                <h2 id="1">Starting Out Beginner Strength Routine</h2>
                <p>This is a Program the was developed from Kyle Risley on lifting vault</p>
                <p>EXPERIENCE LEVEL: BEGINNER</p>
                <p>WEEKS: INDEFINITE</p>
                <p>PERIODIZATION: LINEAR PERIODIZATION</p>
                <p>MEET PREP PROGRAM: NO</p>
                <p>PROGRAM GOAL: STRENGTH</p>
                <p>USES RPE: NO</p>
                <p>USES 1RM PERCENTAGE(%): YES</p>
                <ProgramAccordion sheetUrl="https://docs.google.com/spreadsheets/d/1y1BILLe7gMbNzQzm00SGlnynb8lJgTC0wDIRORTJ3cY/edit#gid=0" />
                <br></br>
                <Container>
                    <OpenInNewTabButton url="https://docs.google.com/spreadsheets/d/1y1BILLe7gMbNzQzm00SGlnynb8lJgTC0wDIRORTJ3cY/copy#gid=0"/>
                    <br></br><br></br>
                    <p>Check out the full article: <a href=" https://liftvault.com/programs/strength/starting-strength-spreadsheet/" target="_blank" rel="noopener noreferrer">https://liftvault.com/programs/strength/starting-strength-spreadsheet/</a></p>
                </Container>
            </Container>
            <Break></Break>
            <Container>
                <h2 id="id">Beginner GreySkull LP</h2>
                <p>Experience level: beginner</p>
                <p>Program goals: gain strength, build muscle</p>
                <p>Training frequency: 3 days per week</p>
                <p>Program length: can be run indefinitely</p>
                <ProgramAccordion sheetUrl="https://docs.google.com/spreadsheets/d/1_TU_UNMyKene_sLNqotLTBv08nd4exgkXBc-K1MOukQ/edit#gid=1184224795" />
                <br></br>
                <Container>
                <OpenInNewTabButton url="https://docs.google.com/spreadsheets/d/1_TU_UNMyKene_sLNqotLTBv08nd4exgkXBc-K1MOukQ/copy#gid=1184224795"/>
                    <br></br><br></br>
                    <p>Check out the full article: <a href="https://liftvault.com/programs/strength/greyskull-linear-progression-spreadsheet/#GreySkull_LP_Program_Spreadsheet_Phrak8217s_Variation" target="_blank" rel="noopener noreferrer">https://liftvault.com/programs/strength/greyskull-linear-progression-spreadsheet/#GreySkull_LP_Program_Spreadsheet_Phrak8217s_Variation</a></p>
                </Container>
            </Container>
            <Break></Break>
            <Container>
                <h2 id="2">Peaking Program: Cast Iron Strength 12 Week</h2>
                <p>12 weeks in length</p>
                <p>Based on percentages of 1RM</p>
                <p>Trains squat, bench press, and deadlift</p>
                <p>Deload is planned for week 6 or 7 depending on the lift</p>
                <p>Squat runs through preparation and accumulation phases before peaking</p>
                <p>Bench press and deadlift alternates between volume and intensity weeks</p>
                <p>The program ends with a double @ 105% of 1RM</p>
                <ProgramAccordion sheetUrl="https://docs.google.com/spreadsheets/d/1CxO69BJCZhhX6M7IR7y6zLmqfjfqbi_WTlIHBcXzwcE/edit?usp=drivesdk" />
                <br></br>
                <Container>
                <OpenInNewTabButton url="https://docs.google.com/spreadsheets/d/1CxO69BJCZhhX6M7IR7y6zLmqfjfqbi_WTlIHBcXzwcE/copy?usp=drivesdk"/>
                    <br></br><br></br>
                    <p>Check out the full article: <a href="https://liftvault.com/programs/powerlifting/12-week-peaking-program-spreadsheet-by-cast-iron-strength/" target="_blank" rel="noopener noreferrer">https://liftvault.com/programs/powerlifting/12-week-peaking-program-spreadsheet-by-cast-iron-strength/</a></p>
                </Container>
            </Container>
            <Break></Break>
            <Container>
                <h2 id="3">PRs 15 Week Intermediate</h2>
                <p>EXPERIENCE LEVEL: INTERMEDIATE</p>
                <p>WEEKS: 15</p>
                <p>PERIODIZATION: BLOCK PERIODIZATION</p>
                <p>MEET PREP PROGRAM: NO, YES</p>
                <p>PROGRAM GOAL: STRENGTH</p>
                <p>USES RPE: YES</p>
                <p>USES 1RM PERCENTAGE(%): NO</p>
                <ProgramAccordion sheetUrl="https://docs.google.com/spreadsheets/d/1QsKokDFtWVGrQEtPT2z-KaPRbcc1S7e7rs3J7x0LysI/edit?usp=drivesdk" />
                <br></br>
                <Container>
                <OpenInNewTabButton url="https://docs.google.com/spreadsheets/d/1QsKokDFtWVGrQEtPT2z-KaPRbcc1S7e7rs3J7x0LysI/copy?usp=drivesdk"/>
                    <br></br><br></br>
                    <p>Check out the full article: <a href="https://liftvault.com/programs/powerlifting/15-week-intermediate-powerlifting-program-2021-update/" target="_blank" rel="noopener noreferrer">https://liftvault.com/programs/powerlifting/15-week-intermediate-powerlifting-program-2021-update/</a></p>
                </Container>
            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default Strength;
