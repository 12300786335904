import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";


function TeenDieting({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

    return (
        <Container fluid className="about-section">
            <h1>The Risks of Cutting Calories as a Teenager</h1>
            <br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Welcome to The Gym Community Library!</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library  </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>

                <Break></Break>
                <h2 id="1">Hormonal and Developmental Concerns:</h2>
                <p><ul>
                    <li><strong>Hormonal Imbalance:</strong> Cutting calories can lead to hormonal crashes, affecting the body's development.</li>
                    <li><strong>Physiological Issues:</strong> Insufficient energy intake can hinder physical development.</li>
                    <li><strong>Psychological Risks:</strong> Cutting can lead to eating disorders due to the body's increased energy and food requirements.</li>
                    <li><strong>Delayed Hormonal Peak:</strong> For males, hormonal peak doesn't occur until around 22 years old; cutting can inhibit this.</li>
                </ul></p>
                <Break></Break>
                <h2 id="2">A Safer Approach:</h2>
                <p><ul>
                    <li><strong>Doctor Supervision:</strong> Cutting should only be considered if a teenager is morbidly obese and under medical guidance.</li>
                    <li><strong>Caloric Deficit:</strong> A slight caloric deficit through cardio a few days a week is the healthiest route.</li>
                    <li><strong>Avoid Crash Dieting:</strong> A deficit of more than 500 calories can lead to severe side effects and slowed metabolism.</li>
                </ul></p>
                <Break></Break>
                <h2 id="3">Gender-Specific Concerns:</h2>
                <p><ul>
                    <li><strong>Girls:</strong> Especially risky due to earlier completion of puberty, although risks generally decrease after the age of 16.</li>
                </ul></p>
                <Break></Break>
                <h2 id="4">Medical Insights:</h2>
                <p><ul>
                    <li><strong>Nutritional Deficiencies:</strong> Lack of essential nutrients like iron and calcium pose both short- and long-term risks.</li>
                    <li><strong>Growth Deceleration:</strong> Even marginal reductions in energy intake can slow growth.</li>
                    <li><strong>Menstrual Irregularities:</strong> Dieting has been linked to menstrual issues, including secondary amenorrhea.</li>
                    <li><strong>Bone Health:</strong> Long-term risks include osteopenia and osteoporosis, even without amenorrhea.</li>
                    <li><strong>Purging Risks:</strong> Any form of purging, such as self-induced vomiting or laxative use, carries well-established medical risks.</li>
                </ul></p>

                <p><em>Source: National Library of Medicine</em></p>


            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default TeenDieting;