import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

const Willpower = ({name, ...props}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">Will Power</h1>
            <br></br>
            <Button variant="primary"
                onClick={handleShow}
                className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Will Power</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link}
                        to="/library"
                        onClick={
                            () => setShow(false)
                        }>
                        <CgFileDocument style={
                            { marginBottom: "2px" }
                        } />
                        Go Back To Library
                    </Nav.Link>
                    <a href="#bodhi">A Message From Bodhi</a>
                    <br /><br />
                    <a href="#understanding-willpower">Understanding Willpower</a>
                    <br /><br />
                    <a href="#how-willpower-works">How Willpower Works</a>
                    <br /><br />
                    <a href="#enhancing-willpower">Enhancing Willpower</a>
                    <br /><br />
                    <a href="#strategies-for-using-willpower">Strategies for Using Willpower Effectively</a>
                    <br /><br />
                    <a href="#overcoming-challenges">Overcoming Challenges</a>
                    <br /><br />
                    <a href="#willpower-in-everyday-life">Willpower in Everyday Life</a>
                    <br /><br />
                    <a href="#further-resources">Further Resources</a>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>
                <h2 id="bodhi">A Message From Bodhi</h2>
                <p>One of our wonderful members, Bodhi, has once again shared his notes on willpower that he has compiled on his journey:
                    <a href="https://docs.google.com/document/d/11nJzV9po4h_jflTyq69qYaG8JMSTsNCximOS9wefj-0/edit">Click here to read</a>
                </p>
                <p>"Good luck on your journey. Willpower is a difficult thing to build, but it is possible. Stay strong and be better than you were yesterday" -Bodhi</p>
                <br></br>
                <h2 id="understanding-willpower">Understanding Willpower</h2>

                <p>
                    <strong>Definition of Willpower</strong>: Willpower is the ability to resist short-term temptations to meet long-term goals and is often equated with self-control.</p>
                <p>
                    <strong>Psychological Perspective</strong>: The prefrontal cortex plays a key role in maintaining focus, memory, and solving abstract tasks, crucial for willpower.</p>
                <p>
                    <strong>Willpower as a Limited Resource</strong>: The concept of ego depletion suggests that willpower can be exhausted.</p>
                <br></br>
                <h2 id="how-willpower-works">How Willpower Works</h2>

                <p>
                    <strong>The Decision-Making Process</strong>: Balancing immediate rewards against long-term goals.</p>
                <p>
                    <strong>Self-Regulation</strong>: The capacity to override an unwanted thought, feeling, or impulse.</p>
                <p>
                    <strong>Delayed Gratification</strong>: The ability to delay immediate gratification for long-term benefits.</p>
                <br></br>
                <h2 id="enhancing-willpower">Enhancing Willpower</h2>

                <p>
                    <strong>Mindfulness and Meditation</strong>: Practices like meditation strengthen the mind's ability to resist impulses.</p>
                <p>
                    <strong>Healthy Lifestyle Choices</strong>: Exercise, sleep, and diet contribute to better willpower.</p>
                <p>
                    <strong>Setting Clear Goals</strong>: Clearly defined goals direct willpower effectively.</p>
                <p>
                    <strong>Building Willpower Gradually</strong>: Starting with small challenges and increasing difficulty over time.</p>
                <br></br>
                <h2 id="strategies-for-using-willpower">Strategies for Using Willpower Effectively</h2>

                <p>
                    <strong>Precommitment Strategies</strong>: Removing temptations in advance.</p>
                <p>
                    <strong>Implementation Intentions</strong>: "If-then" planning for potential challenges.</p>
                <p>
                    <strong>Self-Monitoring</strong>: Tracking behavior to identify patterns and triggers.</p>
                <p>
                    <strong>Cognitive Reappraisal</strong>: Changing thoughts about a situation to control impulses.</p>
                <br></br>
                <h2 id="overcoming-challenges">Overcoming Challenges</h2>

                <p>
                    <strong>Dealing with Ego Depletion</strong>: Recharging through breaks and enjoyable activities.</p>
                <p>
                    <strong>Addressing Willpower Failures</strong>: Learning from lapses without self-judgment.</p>
                <p>
                    <strong>Understanding the Limits of Willpower</strong>: Recognizing that willpower is not infallible and needs support systems.</p>
                <br></br>
                <h2 id="willpower-in-everyday-life">Willpower in Everyday Life</h2>

                <p>
                    <strong>In Work and Education</strong>: Managing distractions and focusing on long-term achievements.</p>
                <p>
                    <strong>In Personal Relationships</strong>: Exercising patience and understanding in social interactions.</p>
                <p>
                    <strong>In Health and Fitness</strong>: Overcoming immediate comfort for health benefits.</p>
                <br></br>
                <h2 id="further-resources">Further Resources</h2>

                <p>Coming Soon. But Bodhi has his at the top</p>

            </Container>
            <ScrollToTop />
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default Willpower;
