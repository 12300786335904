import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const SevournTips = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">Wisdom of a World Record Bench Presser</h1>
            <br></br>
            <Container>  <p>Here is a page I am proud to display from my friend and valued member of our community Sevourn. He is a world record bench presser hitting a whopping 424 lbs in comp @165lbs. This page is the wisdom that he has compiled to help those interesting in powerlifting and particularly benching.</p></Container>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Sevourns Page</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>
                    <br /><br />
                    <a href="#sevourn">Who Is Sevourn?</a>
                    <br /><br />
                    <a href="#advice-to-young-me">Things I'd Tell Me</a>
                    <br /><br />
                    <a href="#bench-workout-overview">World Record Bencher</a>
                    <br /><br />
                    <a href="#workout-philosophy">Sevourn Benching Workout</a>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>
                {/* <h2 id="sevourn">Who Is Sevourn?</h2>
                <p></p>
                <br></br> */}
                <h2 id="advice-to-young-me">Things I'd tell young me</h2>
                <br></br>
                <p>1. If you squat highbar, use a pad or a manta ray or squat SSB. People might make fun of you. People making fun of you is better than collapsing your cervical vertebrae. Your cervical vertebra, the lower ones where you rest highbar control your hands and grip. Decades of unpadded highbar squats partially collapsed my cervical vertebrae which now presses against my spinal cord, greatly reducing my grip strength and fine motor skills.</p>

                <p>2. If you plan to deadlift for decades, pull sumo. Form won't save your back from wear and tear. If you take a bunch of bone discs and put them under several hundred pounds of pressure, there's no angle you can rub them at (good form) that won't eventually wear them away like tires. Sumo isn't perfect but splits the load between your knees which are very good at handling load, and your back, which isn't so good at handling load.</p>

                <p>3. Think long and hard about how much you want to bulk. Bulking to high BMI carries mobility, cardiac, and longevity consequences, and if you change your mind, especially if you wait til you're older, getting it back off is MUCH harder than putting it on, which is why the world at large is obese, not underweight.</p>

                <p>4. If you get lucky enough to make a good friend a workout partner, value them. Workout partners get harder to come by later in life, and you'll look back at the time you worked out with best friends with some longing. Have a friendly rivalry, but don't let it drive you apart.</p>

                <p>5. Don't obsess over programming. Most programs work close to equally well. Consistency, intensity + time = long term results.</p>
                <br></br>
                <h2 id="bench-workout-overview">My time as a World Record Bencher</h2>
                <p>My squat and deadlift are respectable but it's not hard to find people who've done more there. Only my bench is really world class, so I'll share the general layout of what a bench workout looks like for me.</p>
                <p>This might be disappointingly short since there's just not much to it.</p>
                <br></br>
                <h2 id="workout-philosophy">What kind of workout gets your bench this high?</h2>
                <p>Well, lots of benching. I'm not a minimalist. If I'm doing bench in a crowded gym I'm gonna break a lot of hearts.</p>
                <br></br>
                <h2 id="workout-structure">Here's the general structure.</h2>
                <br></br>
                <Container>
                    <table striped bordered hover variant="dark" style={{ margin: 'auto', textAlign: 'center' }}>
                        <thead>
                            <tr>
                                <th>Exercise</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Warm-up</td>
                                <td>4 sets, usually bar, 135, 225, 315 x 5-8</td>
                            </tr>
                            <tr>
                                <td>Work sets</td>
                                <td>3 sets, usually about 80ish% for 5, or 90ish% for 3</td>
                            </tr>
                            <tr>
                                <td> </td>
                                <td>may go up to 8-12 reps if deep offseason</td>
                            </tr>
                            <tr>
                                <td>Bench variation 1</td>
                                <td>3 sets</td>
                            </tr>
                            <tr>
                                <td>Bench Variation 2</td>
                                <td>3 sets</td>
                            </tr>
                            <tr>
                                <td>Heavy pec deck flyes</td>
                                <td>x 3-4 sets, Very heavy, 300-350 lb</td>
                            </tr>
                            <tr>
                                <td> </td>
                                <td>very limited ROM, maybe the top third of the movement</td>
                            </tr>
                        </tbody>
                    </table>
                </Container>
                <br></br>
                <p>That's it, I might throw in flat dumbbell presses if I'm feeling frisky.</p>
                <br></br>
                <h2 id="bench-variations">Bench variations I like:</h2>
                <p>3 inch block press where I let posture break and 3 second pause, usually for sets of 5-8, I do 315-330ish on a max of about 440, 30 second heavy lockouts with about 130% of max, close grip paused 3 inch block press, pin press and t-shirt press are very helpful for working getting the weight off the chest, lower half or upper half reps in power rack using pins to limit ROM, Sling shot sets for 5-12 with 95-110% of max (I will almost always use sling shot as a variation, can't overstate it's importance)</p>

                <p>Barely worth continuing post, I was like 95% done, but other bench variations:</p>
                <ul>
                    <li>Gym bro 7-7-7 burnouts (ay, fun to do), gym bro dropsets where partners take off plates as you go (also fun to do), one I like is rogue multigrip bar, just go out a grip each set:</li>
                    <li><a href="https://garagegymlab.com/product/rogue-mg-24-multi-grip-bar/">https://garagegymlab.com/product/rogue-mg-24-multi-grip-bar/</a></li>
                </ul>
                <br></br>
                <h2 id="chains-and-bands">Chains and bands?</h2>
                <p>These are not bad for a change of pace, but as I've argued with my various coaches, building a fucking lego castle/playing Minecraft in the middle of the workout, especially band setup to me tends to kill the momentum and hype of the workout and extend the time too much. If you have lots of people or can set these up quickly, go for it, although I don't know how many people actually fail after the end of lockout, which is really what these work.</p>

            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default SevournTips;