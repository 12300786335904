import React from "react";
import {Container} from "react-bootstrap";
import Particle from "../../../Particle";
import ScrollToTop from "../../../ScrollToTop";
import Break from "../../Break";


const HowToLoseGracefully = ({name, ...props}) => {

        return (<Container fluid className="about-section">
            <Container>
            <h1 className="title">How To Lose Gracefully</h1>
            <br></br>
            <p>
                <strong>Everyone loses sometimes, but you can choose how to respond.</strong>
                - Whether you compete as an athlete, as a performing artist or in another domain, there are more and less adaptive ways to relate to losing.</p>

            <p>
                <strong>Losing is part of the process of growth.</strong>
                - You can take cues from acceptance and commitment therapy (ACT) in accepting that pain and loss are essential to your journey as a competitor.</p>

            <p>
                <strong>Meet the pain of losing with mindfulness.</strong>
                - Observing your internal experiences after a loss, without judgment, better prepares you to respond in helpful ways.</p>

            <p>
                <strong>Grant yourself the compassion youd show a teammate or friend.</strong>
                - Self-compassion isnt simply about being nice to yourself; it can also improve your motivation as you come back from a loss.</p>

            <p>
                <strong>Prioritise the most workable thoughts about a loss.</strong>
                - Acknowledge when a thought is not serving you, and focus instead on the present moment, the task in front of you, and your process.</p>

            <p>
                <strong>Carefully review what went wrong after a loss and what went right.</strong>
                - Analyse your performance in a balanced manner rather than fixating on the negatives.</p>

            <p>
                <strong>Focus on controlling the controllables.</strong>
                - Save your energy for working on the factors in your performance that you can directly affect.</p>
                <br></br>
                <a href="https://psyche.co/guides/how-to-be-a-better-loser-and-grow-from-your-mistakes">Click here for the full article!</a>

        </Container>
        
        <Break />
        <ScrollToTop />
        <Particle/>
    </Container>
    );
}

export default HowToLoseGracefully;
