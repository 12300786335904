import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const WhoAmI = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">Figuring Out Who You Really Are</h1>
            <br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Figuring Out Who You Really Are</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
            <Break></Break>  
                <h2 id="2">Who Are You?</h2>
                <p>In november 2023 we had a challenge to figure out who you really are but I think anyone should be able to participate even after. Here are the resources we used:</p>

                <a href="https://programs.clearerthinking.org/uncover_your_guiding_principles.html#.Y_17axZOnDt">Uncover Your Guiding Principles</a>
                <p>Build a list of the principles you aim to live by.</p>

                <a href="https://www.julian.com/blog/memorized-rules">Memorized Rules: How to give your life direction</a>
                <p>A framework for putting advice into action. Use your Memorized Rules to remember rules of thumb for better everyday decision-making.</p>

            </Container>
            <Break></Break>
            <Particle /> 
        </Container>
    );
}

export default WhoAmI;