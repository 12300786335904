import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const StayHydro = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

 
    return (
        <Container fluid className="about-section">   
            <Container>
                <h1 className="title">Hydration Guidelines for Fitness Enthusiasts</h1>
                <p>Master the art of staying hydrated to optimize your workout performance and overall health.</p>

                <br></br><br></br>
                </Container>
                <br></br>
                <Button variant="primary" onClick={handleShow} className="me-2">
                    Table of Contents
                </Button>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    scroll={true}
                    backdrop={false}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Hydration Guidelines for Fitness Enthusiasts</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library  </Nav.Link>

                        <a href="#0">Introduction</a>
                        <br></br><br></br>
                        <a href="#1">General Hydration Guidelines</a>
                        <br></br><br></br>
                        <a href="#2">Pre-Workout Hydration</a>
                        <br></br><br></br>
                        <a href="#3">During-Workout Hydration</a>
                        <br></br><br></br>
                        <a href="#4">Post-Workout Rehydration</a>
                        <br></br><br></br>
                        <a href="#5">Signs of Dehydration</a>
                        <br></br><br></br>
                        <a href="#6">What to Drink</a>
                        <br></br><br></br>
                        <a href="#7">Hydration Tips</a>

                    </Offcanvas.Body>
                </Offcanvas>
                
                <Container>
                    <h2 > <Break id="0" /><strong>Introduction</strong></h2> <br />

                    <p>Sweating is the body's natural mechanism for releasing heat generated from physical activities. To ensure optimal body temperature and performance, it's crucial to replenish the fluids lost through sweating.</p>
                </Container>
                
                <Container>

                    <h2 > <Break id="1"/> General Hydration Guidelines</h2><br></br>

                    <p>It's advised to consume between half an ounce to one ounce of fluid per pound of body weight.</p>
                    <p><strong>Example in Pounds:</strong> If you weigh 150 lbs, aim to drink between 75 to 150 ounces of water daily.</p>
                    <p><strong>Example in Kilograms:</strong> If you weigh 68 kg (approximately 150 lbs), aim to drink between 2,275 to 4,550 milliliters (ml) of water daily.</p>
                
                </Container>

                <Container>
                    <h2> <Break id="2"/> Pre-Workout Hydration</h2><br></br>
                    <p>Consume 16 ounces of fluids a few hours before your workout to kickstart your exercise in a hydrated state.</p>
                </Container>

                <Container>
                    <h2> <Break id="3"/> During-Workout Hydration</h2><br></br>
                    
                    <p>To gauge how much fluid you need during exercise, perform the following steps:</p>
                    <p>Weigh yourself before and after a 60-minute workout. Deduct your post-exercise weight from your pre-exercise weight. Add the volume of fluid you consumed during the workout to get your hourly sweat rate.</p>
                    <p><strong>Example:</strong> If you shed 1 pound in a 1-hour workout and drank 8 ounces of fluid, your sweat rate is 24 ounces (16 ounces lost + 8 ounces consumed). Aim to drink 6 ounces every quarter-hour to balance your fluid loss.</p>
                    <p>The sensation of thirst is a delayed response and not a reliable measure of your hydration needs. Don't wait until you're thirsty; by then, dehydration is already setting in.</p>
                </Container>

                <Container>
                    <h2> <Break id="4"/>Post-Workout Rehydration</h2><br></br>

                    <p>For every pound of body weight lost, consume 24 ounces of fluid.</p>
                </Container>

                <Container>
                    <h2> <Break id="5"/>Signs of Dehydration</h2><br></br>

                    <p>Be alert for signs such as thirst, dry mouth, dark-colored urine, reduced urine output, muscle cramps, fatigue, loss of coordination, headaches, and dizziness.</p>

                </Container>
                <Container>
                    <h2><Break id="6" /><strong>What to Drink</strong></h2><br />
                    <p>Water is the go-to beverage for daily hydration. Aim for at least 64 ounces (8 cups) of non-alcoholic fluids per day. Milk and juice also offer essential nutrients that aid in hydration, while alcoholic beverages can lead to dehydration.</p>
                    <p>Carbohydrate-electrolyte drinks like Gatorade replenish minerals and provide energy to muscles. If you're not a fan of plain water, flavored sports drinks can be a good alternative. Avoid sodas, fruit juices, and energy drinks during exercise as they can cause stomach discomfort.</p>
                
                </Container>
                <Container>
                    <h2><Break id="7" /><strong>Hydration Tips</strong></h2><br />
                    <p><strong>Two hours before:</strong> Drink 2 cups</p>
                    <p><strong>30 minutes before:</strong> Drink 1-2 cups</p>
                    <p><strong>Every 10-15 minutes during:</strong> Drink 6-8 ounces</p>
                    <p><strong>Within 30 minutes after:</strong> Drink 8 ounces</p>
                    <p><strong>Strategies for Increased Hydration:</strong> Keep a water bottle with you throughout the day. Place the bottle where you can easily see it. Using a straw can help you sip water consistently. Follow the "10-gulp rule": Take 10 large sips whenever you think of water. Foods high in water content, like fruits and soups, can also contribute to your hydration levels, but be mindful of the calories they add.</p>
                
                </Container>

                <Break />
                          
                <Particle />
            </Container>
        
    );

}

export default StayHydro;