import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

function Resting({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
    
    const openDiscord = () => {
        window.open("https://discord.com/invite/cP3E9FrMCn", "_blank");
      };

    return (
        <Container fluid className="about-section">
            <Container>
                <h2><strong>The Importance of Listening to Your Body and Resting When Needed in Exercise ft. Project Reign</strong></h2>
                <p>In the pursuit of fitness goals, it's crucial to pay attention to your body's signals and rest when needed. Overtraining can lead to a weakened immune system, decreased performance, and increased risk of injury. By implementing rest days and de-load weeks (reduced training volume and intensity) in your program, you can allow for adequate recovery and maintain long-term progress. This article will discuss the importance of listening to your body, signs of overtraining, and strategies for incorporating rest and recovery into your training program.</p>
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Button variant="primary" onClick={openDiscord}>
                Check out Kyle's Project Reign
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Injury Prevention: Listening to Your Body and Resting When Needed</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#1">The Importance of Listening to Your Body</a>
                    <br /><br />
                    <a href="#2">Signs of Overtraining</a>
                    <br /><br />
                    <a href="#3">Strategies for Incorporating Rest and Recovery into Your Training Program</a>
                    <br /><br />
                    <a href="#4">Conclusion</a>
                    <br /><br />  
                </Offcanvas.Body>
            </Offcanvas>
                      
            <Container>
                <h2 ><Break id="1" /><strong>The Importance of Listening to Your Body</strong></h2><br />
                <p>Listening to your body is essential for preventing overtraining and reducing the risk of injury. Key reasons to pay attention to your body's signals include:</p>
                <ul>
                    <p><li><strong>Injury prevention:</strong> Ignoring pain or discomfort can exacerbate existing issues or lead to new injuries. By addressing these signals early, you can prevent more serious problems from developing.</li></p>
                    <p><li><strong>Optimal performance: Adequate rest and recovery are essential for optimizing performance and reaching your fitness goals. Overtraining can result in diminished returns or even regression in progress.</strong></li></p>
                    <p><li><strong>Longevity: </strong>Prioritizing rest and recovery can promote long-term health and well-being, allowing you to maintain an active lifestyle for years to come.</li></p>
                </ul>
                
            </Container>            
            <Container>
                <h2><Break id="2" /><strong>Signs of Overtraining</strong></h2><br />
                <p>Recognizing the signs of overtraining is crucial for knowing when to rest and recover. Common indicators of overtraining include:</p>
                <ul>
                    <p><li>Persistent fatigue or lack of energy</li></p>
                    <p><li>Decreased performance or progress</li></p>
                    <p><li>Increased resting heart rate</li></p>
                    <p><li>Frequent illness or infections</li></p>
                    <p><li>Insomnia or poor sleep quality</li></p>
                    <p><li>Persistent muscle soreness or joint pain</li></p>
                    <p><li>Mood swings, irritability, or depression</li></p>
                </ul>
                <p>If you experience any of these symptoms, it may be time to reevaluate your training program and incorporate additional rest and recovery.</p>
                
            </Container>
            <Container>
                <h2><Break id="3" /><strong>Strategies for Incorporating Rest and Recovery into Your Training Program</strong></h2><br />
                <p>To ensure adequate rest and recovery, consider the following strategies:</p>
                <ul>
                    <p><li><strong>Schedule rest days:</strong> Include regular rest days in your training program, allowing your body time to recover from the stresses of exercise. The number of rest days needed will vary depending on your fitness level, training intensity, and individual needs.</li></p>
                    <p><li><strong>Implement de-load weeks:</strong> Periodically incorporate de-load weeks, where you reduce training volume and intensity to facilitate recovery and prevent overtraining. This can be especially beneficial for those engaging in high-intensity or high-volume training programs.</li></p>
                    <p><li><strong>Listen to pain or discomfort:</strong> If you experience pain, swelling, or any unusual symptoms during exercise, stop the activity and consult a medical professional before continuing your training. Pushing through pain can exacerbate injuries and prolong recovery time.</li></p>
                    <p><li><strong>Prioritize sleep: </strong>Ensure that you are getting enough sleep each night, as this is when the body undergoes the majority of its recovery processes. Aim for 7-9 hours of sleep per night for optimal recovery and performance.</li></p>
                    <p><li><strong>Focus on nutrition and hydration:</strong> Proper nutrition and hydration are essential for supporting recovery and maintaining overall health. Consume a balanced diet rich in whole foods, lean proteins, healthy fats, and complex carbohydrates, and stay well-hydrated throughout the day.</li></p>
                </ul>
                
            </Container>
            <Container>
                <h2><Break id="4" /><strong>Conclusion</strong></h2><br />
                <p>Listening to your body and resting when needed is crucial for preventing overtraining, reducing the risk of injury, and maintaining long-term progress in your fitness journey. Recognize the signs of overtraining and implement strategies such as scheduled rest days, de-load weeks, and proper nutrition and sleep to ensure adequate recovery. By prioritizing your body's needs and allowing for sufficient rest, you can optimize your performance, reach your fitness goals, and maintain a healthy and active lifestyle.</p>
            </Container>
            <Break />
            <ScrollToTop />
            <Particle />
        </Container>
    );
}

export default Resting;