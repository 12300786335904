import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break"

function CalculatingNutritionalValues({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [updateExpanded] = useState(false);
    return (
        <Container fluid className="about-section">
            <h1>Calculating Nutritional Values a Comprehensive Guide</h1>
            <br></br>
            <br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Welcome to The Gym Community Library!</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library  </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>

                <h2>General Guidelines:</h2>
                <ul>
                    <li><strong>Timing:</strong> Consider pre, intra, and post-workout nutrition.</li>
                    <li><strong>Carbohydrates:</strong> Opt for fast-digesting carbs to spike insulin levels and replenish glycogen stores.</li>
                    <li><strong>Limitations:</strong> Avoid high fiber/high fat foods around your workout to ensure optimal digestion and performance.</li>
                </ul>


                <Break></Break>

                <h2 id="1">Pre-Workout Nutrition:</h2>
                <p>
                    <ul>
                        <li><strong>Carbohydrate Intake:</strong> 1-4g/kg (1-4 hours prior to exercise) [PMID: 31100798].</li>
                        <li><strong>Fat Content:</strong> Choose foods low in fat as fats slow down digestion. Fast-digesting carbohydrates are your main energy source for resistance training.</li>
                    </ul>
                </p>

                <Break></Break>

                <h2 id="2">Intra-Workout Nutrition:</h2>
                <p>
                    <ul>
                        <li><strong>Recommended Supplements:</strong> Highly Branched Cyclic Dextrin (HBCD) along with Essential Amino Acids (EAAs).</li>
                        <li><strong>Importance:</strong>
                            <ul>
                                <li><strong>For Competitors:</strong> Small but potentially significant benefits; consider for optimal efficiency.</li>
                                <li><strong>For Average Gym-Goers:</strong> Not very important.</li>
                            </ul>
                        </li>
                        <li><strong>Cheaper Alternative:</strong> Maltodextrin can be used as a more budget-friendly option.</li>
                    </ul>
                </p>

                <Break></Break>

                <h2 id="3">Post-Workout Nutrition: The Road to Faster Recovery</h2>
                <p>
                    <ul>
                        <li><strong>Importance:</strong> Post-workout nutrition is crucial for quicker recovery, which in turn can lead to more muscle growth.</li>
                        <li><strong>What to Eat:</strong> Opt for fast-digesting carbs, low fiber, and low fat—similar to your pre-workout nutrition.</li>
                        <li><strong>State of Mind:</strong> Aim to relax and enter a parasympathetic state post-workout.</li>
                        <li><strong>Protein Timing:</strong>
                            <ul>
                                <li><strong>Common Belief:</strong> Immediate protein consumption is essential.</li>
                                <li><strong>Reality:</strong> Take your time with protein ingestion; it's not crucial to have a protein shake immediately after training.</li>
                            </ul>
                        </li>
                    </ul>
                </p>
                <Break></Break>

                <h2 id="4">Hydration Strategy: Quenching Your Thirst the Right Way</h2>
                <p>
                    <ul>
                        <li><strong>General Rule:</strong> Hydrate according to thirst.</li>
                        <li><strong>Urine Check:</strong> Ensure your urine is not dark-colored before exercise.</li>
                        <li><strong>If Dark:</strong> Rapidly hydrate and consider salty pre-workout snacks like pretzels to draw water into the muscles.</li>
                    </ul>
                </p>

                <Break></Break>

                <h2 id="5">Pre-Workout Powders: A Boost or a Bust?</h2>
                <p>
                    <ul>
                        <li><strong>Effectiveness:</strong> Pre-workout can enhance focus but lacks evidence for performance benefits unless taken at 4-6mg/kg caffeine per body weight.</li>
                        <li><strong>Usage Recommendation:</strong> Use only when extremely needed, like when you're feeling very tired. Alternatively, opt for coffee.</li>
                    </ul>
                </p>

                <Break></Break>


                <h2 id="6">Timing Your Pre-Workout: The Clock is Ticking</h2>
                <p>
                    <ul>
                        <li><strong>Caffeine Duration:</strong> Stays in the system for 10-12 hours.</li>
                        <li><strong>Optimal Timing:</strong> Use as early as possible in the day, especially at higher dosages.</li>
                        <li><strong>Health Implications:</strong> Caffeine blocks deep sleep, affecting muscle recovery and overall health. Use cautiously.</li>
                    </ul>
                </p>

                <Break></Break>

                <h2 id="7">Calculating Macros: The Building Blocks of Your Diet</h2>
                <h3>Here is a comprehensive example provided from our friends at BSF: </h3>
                <h2>Calculating Macros: The Building Blocks of Your Diet</h2>
                <p>Once you understand exactly how many calories you need to achieve your goal you will need to figure out what macronutrients those calories will compose of.</p>
                <ul>
                    <li><strong>Carbohydrates:</strong> Typically carbohydrates and proteins will be calculated first. If you're in a muscle-building phase aim for 4-6g/kg body weight of carbohydrates. We will use 5g/kg bodyweight. This would mean if you are 100kg you will need 500g of carbohydrates. 1g carb = 4 calories, therefore 500g=2000 calories.</li>
                    <li><strong>Protein:</strong> Aim for around 2g/kg bodyweight. Therefore, if you are 100kg you will need 200g of total protein. 1g of protein = 4 calories, therefore 200g=800 calories. Try to shoot for 0.25g-0.40g of protein/kg of body weight in each meal |4-7x a day|. If youre a 100kg person youll need 25g-40g of protein in a meal to help spike MPS. Any more than this will see no added benefit typically. Wait 2-3 hours before another serving.</li>
                    <li><strong>Fat:</strong> For example sake, this 100kg person needs 4,000 calories per day to gain weight at a rate of 0.5kg/week. |Protein= 800 calories and carbohydrate =2,000 calories. This makes 2,800 calories total between carbs and protein.| We know that this 100kg person needs 4,000 calories consequently the rest will be filled with fat. 4,000-2,800=1,200. 1g fat = 9 calories, 1200/9=133g. Therefore, this leaves us with 500g C, 200g P, and  13 g F.</li>
                    <li><strong>Additional Notes:</strong> Here the fat could be taken down even further, as from scientific literature we understand that more carbohydrates is typically better for recovery and performance outcomes versus fat. You could get away with having as low as 20% of your calories coming from fat. Therefore, more could be taken off of the fat about -30g. 30g fat would equal 270 calories |30*9|. These 270 calories can be given to carbohydrates giving us around 70g of extra carbohydrates |270/4=68|.</li>
                </ul>
                <p>Overall breakdown: this would take us to 570g carbohydrate, 100g fat, 200g protein and 4000 calories.</p>

                <Break></Break>

                <h2 id="8">Fiber Intake: Don't Forget the Gut Health</h2>
                <p>
                    <ul>
                        <li><strong>Importance:</strong> Vital for gut health, fecal motility, and reducing disease risk.</li>
                        <li><strong>Daily Recommendation:</strong> Aim for 30g/day.</li>
                        <li><strong>Digestive Benefits:</strong> Fiber aids in digestion and allows for a slower, more controlled release of nutrients.</li>
                        <li><strong>High-Fiber Foods:</strong> Include vegetables, dark grains, and complex carbohydrates like brown rice, brown pasta, and wholemeal breads.</li>
                        <li><strong>Health Implications:</strong> A diet rich in fiber may reduce the risk of colon cancers.</li>
                    </ul>
                </p>
            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default CalculatingNutritionalValues;