import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

const ControllingYourEmotions = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">Videos on Emotional Intelligence</h1>
            <br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Videos on Emotional Intelligence</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>

                <h2>Explore Our Recommended Videos</h2>
                <p>Below is a list of carefully selected videos that delve into various aspects of emotional control and personal growth. These books have been chosen for their transformative potential and practical advice. Dive in to discover the wisdom and techniques that can help you navigate the complexities of emotions.</p>
                <br></br>
                <h2>Taking Control Of Your Emotions is The Same As Taking Control Of Your Life. Jocko Willink </h2>
                <br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/3zvoh8W4T8w?si=3amO9M_DkwzY12Vh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br>
                <h2>Why Some Adults Can't... "Act Their Age"</h2>
                <br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/0M9CHMES68A?si=rC1Xu5fJy2WndyZ-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br>
                <h2>How to manage your emotions</h2>
                <br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Uew5BbvmLks?si=jdiVknWN4yoO72hq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br>
                <h2>How to stop your thoughts from controlling your life | Albert Hobohm | TEDxKTH</h2>
                <br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/29Vj0-TVHiQ?si=KsrUQ1crLqyWrtk0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            </Container>
            <Break />
            <ScrollToTop />
            <Particle />
        </Container>
    );
}

export default ControllingYourEmotions;