import React from "react";
import {Container, Offcanvas, Dropdown, Button} from "react-bootstrap";
import Particle from "../../../Particle";
import {useState} from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import {CgFileDocument} from "react-icons/cg";
import NavItemComponent from "../../NavItemComponent";
import Break from "../../Break";


function MealSizeandTiming({name, ...props}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
    
    return (
        <Container fluid className="about-section">
            <h1>Meal Size and Timing</h1>
            <br></br>
            <Button variant="primary"
                onClick={handleShow}
                className="me-2">
                Table of Contents
            </Button>
            <Offcanvas show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Welcome to The Gym Community Library!</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link}
                        to="/library"
                        onClick={
                            () => updateExpanded(false)
                    }>
                        <CgFileDocument style={
                            {marginBottom: "2px"}
                        }/>
                        Go Back To Library
                    </Nav.Link>
                    <NavItemComponent to="/library/content/genEd/readLabel#0" label="Pre-Workout Nutrition"
                        updateExpanded={updateExpanded}/>
                </Offcanvas.Body>
            </Offcanvas>
            <br></br>
            <br></br>
            <br></br>
            <h2 id="1">Factors to Consider:</h2>
            <p>
                <ul>
                    <li>
                        <strong>Consistency is Key</strong>: Keeping a regular meal schedule improves results and adherence.</li>
                    <li>
                        <strong>Simplicity</strong>: A regular meal schedule facilitates easier meal preparation and aligns well with daily routines.</li>
                    <li>
                        <strong>Optimal Timing</strong>: Proper meal sizing and timing can enhance workout effectiveness, boost daily energy levels, and improve sleep quality.</li>
                </ul>
            </p>

            <Break></Break>
            <h2 id="2">Number of Meals:</h2>
            <p>
                <ul>
                    <li>
                        <strong>Flexibility</strong>: It's possible to eat as little as one meal a day and still achieve results, as long as that meal meets your daily macronutrient requirements.</li>
                    <li>
                        <strong>3-5 Meals Recommended</strong>: Eating multiple meals a day has several benefits:
                        <ul>
                            <li>Helps in curbing hunger.</li>
                            <li>Supplies energy for daily tasks.</li>
                            <li>Ensures a steady flow of nutrients to preserve muscle.</li>
                            <li>Fuels your workouts.</li>
                        </ul>
                    </li>
                </ul>
            </p>

            <Break></Break>
            <h2 id="3">Suggested Meal Timing:</h2>
            <p>
                <ul>
                    <li>Have a meal within a few hours of waking up.</li>
                    <li>Consume a post-workout meal to fuel muscle growth.</li>
                    <li>Eat every 6 hours at a minimum.</li>
                    <li>Have a meal within 2 hours of bedtime.</li>
                </ul>
            </p>

            <Break></Break>
            <h2 id="4">Macronutrients Per Meal:</h2>
            <p>
                <ul>
                    <li>
                        <strong>Protein</strong>: This can be fairly consistent across meals. It aids in muscle repair, growth, and satiety.</li>
                    <li>
                        <strong>Carbohydrates</strong>:
                        <ul>
                            <li>Consume carbs before workouts to fuel your sessions.</li>
                            <li>Include carbs post-workout to aid recovery.</li>
                            <li>Limit carb intake during other times.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Fats</strong>:
                        <ul>
                            <li>Avoid fats close to workout times as they can slow digestion.</li>
                            <li>Consume fats during longer intervals between meals. They release energy slowly, helping to curb hunger.</li>
                        </ul>
                    </li>
                </ul>
            </p>

            <Break></Break>
            <h2 id="5">Sample Meal Plan (for a 150lb person):</h2>
            <p>
                <strong>Daily Macros</strong>: P150 (Protein) F60 (Fat) C215 (Carbohydrates)
                <ul>
                    <li>
                        <strong>Meal 1 (10 am)</strong>:
                        <ul>
                            <li>Turkey bacon, egg whites, vegetables, whole grain English muffin.</li>
                            <li>Macros: P40 F20 C40</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Meal 2 (2 pm - Pre-workout)</strong>:
                        <ul>
                            <li>Turkey sandwich with veggies and light mayo, fruit.</li>
                            <li>Macros: P35 F10 C60</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Meal 3 (7 pm - Post-workout)</strong>:
                        <ul>
                            <li>Meat sauce pasta with grilled veggies on the side.</li>
                            <li>Macros: P35 F10 C80</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Meal 4 (10 pm)</strong>:
                        <ul>
                            <li>Greek yogurt with berries and nut butter.</li>
                            <li>Macros: P40 F20 C35</li>
                        </ul>
                    </li>
                </ul>
            </p>

            <p>The sample meal plan is structured to align with the guidelines. It ensures consistent protein intake, positions carbs around workout times for energy and recovery, and distributes fats throughout the day for sustained energy. Adapting these guidelines to individual needs and preferences is essential for long-term adherence and success.</p>
            <Break></Break>
            <Particle/>
        </Container>
    );
}

export default MealSizeandTiming;
