import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break"

function Carbs({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [updateExpanded] = useState(false);
    return (
        <Container fluid className="about-section">
                            <h1>Understanding Carbohydrates: Simple vs Complex</h1>
                            <br></br><br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Welcome to The Gym Community Library!</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library  </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>

                    <h2 id="1">Simple Carbohydrates</h2>
                    <p>
                        <ul>
                            <li><strong>Characteristics:</strong> Rapidly digested, causing quick spikes in blood sugar levels.</li>
                            <li><strong>Consequences:</strong> Leads to energy crashes and increased hunger.</li>
                            <li><strong>Examples:</strong>
                                <ul>
                                    <li>Candy</li>
                                    <li>Soda</li>
                                    <li>Syrups</li>
                                    <li>Sugar</li>
                                    <li>White bread</li>
                                    <li>Pastries</li>
                                    <li>Fruit juice concentrate</li>
                                    <li>Table sugar (sucrose)</li>
                                    <li>Corn syrup</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <Break></Break>

                        <h2 id="2">Complex Carbohydrates</h2>
                        <p>
                            <ul>
                                <li><strong>Characteristics:</strong> Slowly digested, providing a steady release of energy and promoting satiety. Rich in vitamins, minerals, and fiber.</li>
                                <li><strong>Benefits:</strong>
                                    <ul>
                                        <li>Supports weight loss</li>
                                        <li>Prevents blood sugar fluctuations</li>
                                        <li>Curbs cravings</li>
                                        <li>Promotes longer periods of fullness</li>
                                        <li>Aids in calorie control and overall weight management</li>
                                    </ul>
                                </li>
                                <li><strong>Examples:</strong>
                                    <ul>
                                        <li>Brown rice</li>
                                        <li>Rice</li>
                                        <li>Oatmeal</li>
                                        <li>Quinoa</li>
                                        <li>Potatoes</li>
                                        <li>Lentils</li>
                                        <li>Kidney beans</li>
                                        <li>Chickpeas</li>
                                        <li>Whole grain bread</li>
                                        <li>Whole grain pasta</li>
                                        <li>Barley</li>
                                        <li>Millet</li>
                                        <li>Sweet potatoes</li>
                                        <li>Corn</li>
                                        <li>Whole fruits (e.g., apples, berries)</li>
                                    </ul>
                                </li>
                            </ul>
                        </p>

                    </Container>
                    <Break></Break>
                    <Particle />
            </Container>
            );
}

            export default Carbs;