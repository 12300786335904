import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../../../Particle";
import Break from "../../Break";

const StrengthOverload = () => {

    return (
        <Container fluid className="about-section">
            <h1 className="title">Overload Requirement For Strength</h1>
            <br></br>
            <Container>
                <h2>Strength Training and Fatigue</h2>
                <p><strong>Fatigue Levels:</strong> Heavier loads are more fatiguing than lighter loads when the volume is the same.</p>
                <p><strong>Strength vs. Hypertrophy:</strong> Strength training requires lifting heavier loads than hypertrophy training. Therefore, the level of fatigue during a strength phase should be lower to allow for effective heavy training.</p>
                <br></br>
                <h2>Optimal Set Range</h2>
                <p><strong>Insufficient:</strong> One set of 5 per workout is not enough.</p>
                <p><strong>Optimal:</strong> 5 sets of 5 push most lifters in the right direction.</p>
                <p><strong>Excessive:</strong> 10 sets of 5 is too much for most people.</p>
                <br></br>
                <h2>Intensity for Strength Adaptations</h2>
                <p><strong>Minimal Intensity:</strong> The lowest intensity for optimal strength gains is around 75%.</p>
                <p><strong>Sub-Optimal Intensity:</strong> Anything less than 75% doesn't effectively signal the nervous system to improve its force-generating capacities.</p>
                <br></br>
                <h2>Volume and Intensity in Training</h2>
                <p><strong>Hypertrophy Training:</strong> Volume increases are mandatory, while intensity increases are optional.</p>
                <p><strong>Strength Training:</strong> Intensity increases are mandatory, while volume may not need to change much.</p>
                <br></br>
                <h2>Progression in Strength Training</h2>
                <p><strong>Importance of Intensity:</strong> Just like you won't get bigger with only 3 sets of 10 all the time, you won't get stronger unless you progressively increase intensity.</p>
                <p><strong>Progressive Overload:</strong> Weights should start at 75% and move up towards 85% during a strength mesocycle for optimal results.</p>
                <br></br>
                <h2>Summary</h2>
                <p>In a strength phase, start with moderate volumes and a heavy weight (around 75%). Progressively increase the weight for the best results.</p>
            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default StrengthOverload;