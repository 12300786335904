import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

const CommunityCreatedBooklist = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Container fluid className="about-section">
            <h1 className="title">Community Created Book List</h1>
            <br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Community Created Book List</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>
                <h2 id="0">Book List</h2>

                <ul>
                    <li><strong>Can't Hurt Me</strong> - David Goggins</li>
                    <li><strong>Atomic Habits</strong> - James Clear</li>
                    <li><strong>The Dichotomy of Leadership: Balancing the Challenges of Extreme Ownership to Lead and Win</strong> - Jocko Willink</li>
                    <li><strong>The Alchemist</strong> - Paulo Coelho</li>
                    <li><strong>The 4-hour Work Week</strong> - Timothy Ferriss</li>
                    <li><strong>Awaken the Giant Within</strong> - Tony Robbins</li>
                    <li><strong>The Gifts of Imperfection</strong> - Brene Brown</li>
                    <li><strong>How to Win Friends and Influence People</strong> - Dale Carnegie</li>
                    <li><strong>Crucial Conversations</strong> - Kerry Patterson et al.</li>
                    <li><strong>Digital Minimalism</strong> - Cal Newport</li>
                    <li><strong>Unlimited Power</strong> - Tony Robbins</li>
                    <li><strong>Thinking, Fast and Slow</strong> - Daniel Kahneman</li>
                    <li><strong>The Psychology of Selling</strong> - Brian Tracy</li>
                    <li><strong>Neville Goddard Collection</strong></li>
                    <li><strong>Becoming Supernatural</strong> - Joe Dispenza</li>
                    <li><strong>Breaking the Habit of Being Yourself</strong> - Joe Dispenza</li>
                    <li><strong>Dissolving the Ego</strong> - Helen Hamilton</li>
                    <li><strong>Bhagavad Gita</strong></li>
                    <li><strong>Seth Speaks</strong> - Jane Roberts</li>
                    <li><strong>Nature of Personal Reality</strong> - Jane Roberts</li>
                    <li><strong>Outwitting the Devil</strong> - Napolean Hill</li>
                    <li><strong>The Game of Life and How to Play It</strong> - Florence Shinn</li>
                    <li><strong>The Power of the Subconscious Mind</strong> - Joseph Murphy</li>
                    <li><strong>The Keys to the Ultimate Freedom</strong> - Lester Levenson</li>
                    <li><strong>I AM That</strong> - Nisargadatta Maharaj</li>
                    <li><strong>Awakening from the Daydream</strong> - David Nichtern</li>
                    <li><strong>The Power of Now</strong></li>
                    <li><strong>10% Happier</strong> - Dan Harris</li>
                </ul>

            </Container>
            <Break />
            <ScrollToTop />
            <Particle />
        </Container>
    );
}

export default CommunityCreatedBooklist;