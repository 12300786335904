import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

function ProgressionAndPeriodization({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

    const openDiscord = () => {
        window.open("https://discord.com/invite/cP3E9FrMCn", "_blank");
    };
    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Gradual Progression and Periodization ft Project Reign </h2>
                <p>In resistance training and general exercise, gradual progression and periodization are essential for preventing injuries and optimizing performance. By allowing the body time to adapt to increasing demands and strategically varying training variables over time, you can minimize the risk of overuse injuries and enhance performance gains.</p>
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Button variant="primary" onClick={openDiscord}>
                Check out Kyle's Project Reign
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Injury Prevention: Gradual Progression and Periodization ft Project Reign</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">Gradual Progression</a>
                    <br /><br />
                    <a href="#1">Periodization</a>
                    <br /><br />
                    <a href="#2">Practical Tips for Implementing Gradual Progression and Periodization</a>
                    <br /><br />
                    <a href="#3">Conclusion</a>
                    <br /><br /> 
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <h2 ><Break id="0" /><strong>Gradual Progression</strong></h2>
                <p>Gradual progression is the process of slowly increasing the demands placed on the body during exercise. This approach allows the body to adapt to new stimuli, reducing the risk of overuse injuries.</p>
                <ul>
                    <li><p><strong>Overload:</strong> To stimulate adaptation, the body must be exposed to a stimulus that exceeds its current capacity. This can be achieved by increasing resistance, repetitions, or sets.</p></li>
                    <li><p><strong>Specificity:</strong> Adaptations are specific to the type of training performed. To improve in a particular exercise or sport, training should mimic the movement patterns and energy demands of that activity.</p></li>
                    <li><p><strong>Individualization:</strong> Each individual will respond differently to a training stimulus. Adjust your training program based on your unique needs, goals, and abilities, considering factors such as age, fitness level, and injury history.</p></li>
                </ul>                
            </Container>
            <Container>
                <h2 ><Break id="1" /><strong>Periodization</strong></h2><br />
                <p>Periodization is the systematic planning of training to strategically vary volume, intensity, and frequency over time. This approach helps prevent injuries by managing fatigue and reducing the risk of overtraining. Additionally, periodization optimizes performance gains by targeting specific physiological adaptations at different times.</p>
                <ul>
                    <li><p><strong>Linear periodization:</strong> This model involves gradually increasing intensity and decreasing volume over a training cycle. This approach is effective for beginners and those with general fitness goals.</p></li>
                    <li><p><strong>Undulating periodization:</strong> This approach involves regularly changing training volume and intensity, allowing for greater variation and preventing training plateaus. This model can be beneficial for intermediate and advanced exercisers.</p></li>
                    <li><p><strong>Block periodization:</strong> This model focuses on developing specific physiological adaptations in distinct training blocks, with each block lasting several weeks. This approach is often used by competitive athletes preparing for specific events.</p></li>
                </ul>                
            </Container>            
            <Container>
                <h2><Break id="2" /><strong>Practical Tips for Implementing Gradual Progression and Periodization</strong></h2><br />
                <ul>
                    <li><p><strong>Set realistic goals:</strong> Establish clear, achievable goals that align with your current fitness level and long-term objectives. This will help guide your progression and ensure that you don't advance too quickly.</p></li>
                    <li><p><strong>Monitor progress:</strong> Regularly assess your progress by tracking performance metrics, such as strength, endurance, or power. Use this information to adjust your training program as needed.</p></li>
                    <li><p><strong>Plan recovery:</strong> Ensure that you are allowing adequate time for rest and recovery between training sessions. Incorporate active recovery activities, such as light aerobic exercise or mobility work, to further promote healing and adaptation.</p></li>
                    <li><p><strong>Consult a professional:</strong> Work with a certified fitness professional or strength and conditioning coach to develop a personalized training program that incorporates gradual progression and periodization. This will help ensure that your program is safe, effective, and tailored to your unique needs.</p></li>
                </ul>
            </Container>            
            <Container>
                <h2><Break id="3" /><strong>Conclusion</strong></h2><br />
                <p>Incorporating gradual progression and periodization into your resistance training and general exercise program is crucial for preventing injuries and optimizing performance. By allowing your body time to adapt to increasing demands and strategically varying training variables over time, you can minimize the risk of overuse injuries and enhance performance gains. Invest time in planning and adjusting your training program, focusing on realistic goals, monitoring progress, and prioritizing recovery. This will ultimately lead to a more enjoyable, effective, and sustainable exercise experience.</p>                
            </Container>            

            <Break />
            <ScrollToTop />
            <Particle />
        </Container>
    );
}

export default ProgressionAndPeriodization;