import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

function NameOfPage({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
    
    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Addressing Elbow and Wrist Pain in Training</h2>
                <p>Elbow and wrist pain are common issues in various forms of training, including calisthenics, free weights, machines, martial arts, and sports. These issues can arise even with proper form.</p>
                
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Elbow and Wrist Pain</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">Helpful Resources</a>
                    <br /><br />
                    <a href="#1">The Overlooked Components:</a>
                    <br /><br /> 
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <h2 ><Break id="0" /><strong>Helpful Resources</strong></h2><br /><br />
                <h4>Knee Ability For Elbows</h4><br /><br />
                
                <iframe width="560" height="315" src="https://www.youtube.com/embed/pzUVcG7oKLc?si=bEoapJ1pYdHkrOc7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br /><br /><br />
                
                <h4>Get Strong And Flexible Wrists With This Ready-to-go Wrist Prep Routine!</h4><br /><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/mSZWSQSSEjE?si=9keoS7wssyqgjnF7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


            </Container>
            <Container>
                <h2 ><Break id="1" /><strong>The Overlooked Components:</strong></h2><br />
                <ul>
                    <p><li><strong>Small Muscles, Tendons, and Joints:</strong> Often ignored because they don't contribute to lifting heavy weights or weight loss.</li></p>
                    <p><li><strong>Good News:</strong> These pains are not something you have to live with; they can be improved.</li></p>
                </ul><br /><br />
                
                <h3>Solutions:</h3><br />
                <ul>
                    <p><li><strong>Ben Patrick's Recommendations:</strong></li></p>                    
                    <p><li>Offers simple, cost-effective tools for home use.</li></p>
                    <p><li>Provides exercise options to strengthen these areas.</li></p>
                    <p><strong>Checkout <a href="https://myhealth.alberta.ca/Health/aftercareinformation/pages/conditions.aspx?hwid=av2582"> My Health Alberta</a></strong> for a guide on exercises for Golfers Elbow.</p> 
                    <p><strong><a href="https://myhealth.alberta.ca/Health/aftercareinformation/pages/conditions.aspx?hwid=av2582"> Here's </a></strong> more information on Golfer's Elbow itself.</p> 
                </ul><br /><br />  

                <p><strong>NOTE:</strong> Checkout <a href="/library/content/mobility/tennisElbow">Tennis Elbow</a> for more rehab information on elbow pain.</p>             
            </Container>
            <Break />
            <Particle />
        </Container>
    );
}

export default NameOfPage;