import React from "react";
import {Container, Offcanvas, Dropdown, Button} from "react-bootstrap";
import Particle from "../../../Particle";
import {useState} from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import {CgFileDocument} from "react-icons/cg";
import Break from "../../Break"

function Diet101({name,...props}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

    return (
        <Container fluid className="about-section">
            <h1>Weightloss Periodization</h1>
            <br></br>
            <Button variant="primary"
                onClick={handleShow}
                className="me-2">
                Table of Contents
            </Button>
            <Offcanvas show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Weightloss Guide</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link}
                        to="/library"
                        onClick={
                            () => updateExpanded(false)
                    }>
                        <CgFileDocument style={
                            {marginBottom: "2px"}
                        }/>
                        Go Back To Library
                    </Nav.Link>
                    <a href="#1">Weight Loss Periodization Guide</a>
                    <br></br>
                    <br></br>
                    <a href="#2">Diet Troubleshooting Guide</a>
                    <br></br>
                    <br></br>
                    <a href="#3">Minimize Muscle Loss On A Cut</a>
                </Offcanvas.Body>
            </Offcanvas>
            <Break id="1"></Break>
            <Container>

                <h2>Weight Loss Periodization Guide</h2>

                <p>Weight loss periodization refers to a structured and planned approach to losing weight. Instead of continuously dieting, periodization involves phases of focused fat loss followed by maintenance phases. This structure can help prevent muscle loss, fatigue, psychological burnout, and increase the likelihood of long-term weight maintenance.</p>

                <h2>Why Not Just Continuously Lose Weight?</h2>
                <p>
                    <ul>
                        <li>No Need for Periodization? Contrary to popular belief, you don't always need periodization for fat loss, but it can be advantageous.</li>
                        <li>The Dangers of Continuous Caloric Deficit: A prolonged caloric deficit can lead to muscle loss, increased fatigue, hindered performance, and a high likelihood of weight regain or "rebound".</li>
                        <li>Preserving Muscle: The goal isn't just to lose weight, but to retain as much muscle as possible. Rapid, continuous weight loss doesn't favor this.</li>
                        <li>Avoiding Fatigue & Burnout: A structured approach can reduce the fatigue of dieting over and over, helping you stick to your goals in the long term.</li>
                        <li>One-shot Approach Challenges: Rapidly losing a significant amount of weight without breaks can be especially challenging and potentially harmful if you're looking to lose more than 15% of your body weight.</li>
                    </ul>
                </p>
                <br></br>
                <h2>The Importance of Maintenance Phases:</h2>
                <p>
                    <ul>
                        <li>What Are Maintenance Phases? These are periods where the goal is weight stability after active fat loss phases.</li>
                        <li>Why Are They Needed?
                            <ol>
                                <li>Continuous low-calorie diets become increasingly tiring.</li>
                                <li>Prolonged dieting will inevitably lead to muscle loss.</li>
                                <li>Psychologically, extended dieting is tough.</li>
                                <li>Successful weight loss without rebound is challenging. Maintenance phases can reduce the risk of gaining the weight back.</li>
                            </ol>
                        </li>
                        <li>Frequency & Duration of Maintenance Phases: They are generally suggested after every 5-10% of weight loss and should last 2/3 to 1.5x the length of the preceding fat loss phase.</li>
                    </ul>
                </p>
                <br></br>
                <h2>Phasic Structure for Weight Loss:</h2>
                <p>
                    <ul>
                        <li>The Long-Term View: Weight loss success might involve a combination of fat loss phases and maintenance phases.</li>
                        <li>Does Everyone Need Long Maintenance Phases? Not always. But many people benefit from starting with generous maintenance phases.</li>
                        <li>Typical Structure: The pattern often looks like: Fat Loss → Maintenance → Fat Loss → Maintenance... and so on.</li>
                        <li>Temptation: While it might be enticing to go for rapid, continuous weight loss (one-shot), it might not be the most sustainable or healthiest approach.</li>
                    </ul>
                </p>
                <br></br>
                <h2>Weight Loss Periodization Example:</h2>
                <p>
                    <ul>
                        <li>June-August: Start at 200lbs, target 180lbs.</li>
                        <li>September-December: Maintain between 180lbs-185lbs.</li>
                        <li>January-March: Reduce from 185lbs to 170lbs.</li>
                        <li>April-July: Aim for slight loss or maintenance, ranging 170lbs-175lbs.</li>
                        <li>August-October: Lose weight from 175lbs to 165lbs.</li>
                        <li>November-December: Maintain between 165lbs-170lbs.</li>
                        <li>January-March: Target 170lbs to 160lbs.</li>
                        <li>From April onwards: Maintain and enjoy a balanced approach to eating and training, holding weight between 160lbs-165lbs.</li>
                    </ul>
                </p>
                <br></br>
                <h2>Conclusion:</h2>
                <p>Weight loss periodization offers a strategic approach to weight loss, aiming to optimize results while minimizing potential drawbacks like muscle loss, fatigue, and psychological stress. By integrating fat loss and maintenance phases, individuals can achieve sustainable weight loss results and a healthier relationship with food and exercise. Considering your fitness-minded profile, this approach could be valuable if you're looking to shed some pounds while also maintaining a balanced and healthy lifestyle.</p>

            </Container>
            <Break id="2"></Break>
            <Container>
                <h2>Diet Troubleshooting Guide</h2>
                <br></br>
                <h2>1. Are you in a calorie deficit?</h2>
                <p>
                    How do you know?
                    <ul>
                        <li>Track Your Calories: Using apps like MyFitnessPal or Cronometer can help you keep an accurate record of your daily intake.</li>
                        <li>Monitor Weight Trends: Use a scale to check your weight regularly (e.g., once a week). If it remains consistent or increases over several weeks, you might not be in a calorie deficit.</li>
                    </ul>
                    What to do if you’re not losing weight?
                    <ul>
                        <li>Re-evaluate Caloric Intake: Sometimes, initial calculations for maintenance calories can be off. Recalculate your TDEE (Total Daily Energy Expenditure) and adjust your intake accordingly.</li>
                        <li>Increase Activity Level: Intensify your workouts, add more cardio, or increase your daily steps to burn more calories.</li>
                        <li>Check for Overeating: It's easy to underestimate how much you're consuming. Ensure you're measuring and tracking your food correctly.</li>
                    </ul>
                </p>
                <br></br>
                <h2>2. Have you been dieting too long?</h2>
                <p>
                    How long is too long (12-16 weeks)?
                    <ul>
                        <li>Risks of Prolonged Dieting: Dieting for extended periods can lead to muscle loss, metabolic slowdown, and hormonal imbalances.</li>
                    </ul>
                    What that means (lowered NEAT, hormones, hunger, psych)?
                    <ul>
                        <li>Lowered NEAT (Non-Exercise Activity Thermogenesis): You may become less active without realizing it, burning fewer calories throughout the day.</li>
                        <li>Hormonal Changes: Leptin, the hormone that signals fullness, can decrease, while ghrelin, the hunger hormone, can increase.</li>
                        <li>Increased Hunger: Prolonged dieting can make you feel hungrier, making it harder to stick to your caloric goals.</li>
                        <li>Psychological Fatigue: Being in a deficit for an extended period can be mentally draining, leading to decreased motivation or feelings of deprivation.</li>
                    </ul>
                    How to do a maintenance phase?
                    <ul>
                        <li>Figuring Out Initial Calories: Return to your maintenance calorie intake (TDEE) for a while.</li>
                        <li>Raising Calories Slowly: Instead of jumping straight back to maintenance, consider increasing your intake by 100-200 calories per week until you reach TDEE. This approach can help prevent rapid weight gain.</li>
                        <li>Finding Middle Ground of Healthy Meals: Strike a balance between nutrient-dense meals and occasional treats. It's a chance to relax a bit on strict dieting, but mindful eating remains essential.</li>
                    </ul>
                    How long to do a maintenance phase?
                    <ul>
                        <li>Typically, a maintenance phase should last anywhere from 2-4 weeks. However, the duration can vary based on how you feel both physically and mentally. Some people benefit from longer breaks, especially if they've been dieting for a very long time.</li>
                    </ul>
                    How do you know when you can diet again?
                    <ul>
                        <li>Physical Signs: You have consistent energy levels, reduced hunger pangs, and stable weight.</li>
                        <li>Mental Readiness: You feel motivated and mentally prepared to start another dieting phase without feeling deprived or burned out.</li>
                        <li>Hormonal Balance: If you had issues with hunger or satiety hormones during the prolonged diet, it's essential to feel that these have normalized.</li>
                    </ul>
                </p>
                <br></br>
                <h2>Conclusion</h2>
                <p>Dieting is a complex journey that requires consistent monitoring and adjustment. Understanding your body's signals, tracking progress, and making informed decisions based on both physical and mental feedback are crucial for successful and sustainable weight management. This guide serves as a roadmap to navigate common challenges and make effective adjustments as needed.</p>

            </Container>
            <Break id="3"></Break>
            <Container>
                <h2>Minimize Muscle Loss On A Cut</h2>
                <br></br>
                <h2>1. Prioritize Protein Intake</h2>
                <p>
                    <ul>
                        <li>Protein plays a crucial role in muscle maintenance, especially during a calorie deficit.</li>
                        <li>Aim for at least 1g of protein per pound of body weight daily for optimal results. To be more conservative and ensure adequate protein intake, consider exceeding this amount slightly.</li>
                    </ul>
                </p>
                <br></br>
                <h2>2. Manage Caloric Deficit Carefully</h2>
                <p>
                    <ul>
                        <li>A slower, more controlled caloric deficit minimizes the risk of muscle loss.</li>
                        <li>Extreme deficits (over 500 calories per day) are a calculated risk and may lead to faster muscle loss.</li>
                    </ul>
                </p>
                <br></br>
                <h2>3. Duration of the Diet</h2>
                <p>
                    <ul>
                        <li>Shorter cutting phases (less than 8 weeks) combined with moderate caloric deficits are generally safer for preserving muscle mass.</li>
                        <li>Prolonged cutting (beyond 12 weeks) poses a higher risk of muscle loss. If such a long phase is needed, consider interspersing it with short maintenance or refeed periods.</li>
                    </ul>
                </p>
                <br></br>
                <h2>4. Frequent Meals</h2>
                <p>
                    <ul>
                        <li>Eating at least 4 meals a day can be beneficial in preserving muscle.</li>
                        <li>While 3 meals a day is acceptable, less than 3 meals might not be optimal for muscle preservation. Spread out macronutrients, especially protein, roughly evenly across meals.</li>
                    </ul>
                </p>
                <br></br>
                <h2>5. Maximize Carbohydrate Intake</h2>
                <p>
                    <ul>
                        <li>Carbohydrates fuel workouts and aid muscle retention.</li>
                        <li>Aim to cut fats rather than carbohydrates when creating a caloric deficit. This approach ensures that protein intake doesn't push carbohydrate consumption too low.</li>
                        <li>Prioritize carbohydrates before and after workouts for energy and recovery.</li>
                    </ul>
                </p>
                <br></br>
                <h2>6. Monitor Fat Intake</h2>
                <p>
                    <ul>
                        <li>Don't drastically reduce fats. Extremely low fat intake (below 0.3g per lb) can affect hormonal balance and overall health.</li>
                        <li>However, if fats exceed 0.4g per lb, consider reallocating some of those calories to carbohydrates, especially if workout performance begins to suffer.</li>
                    </ul>
                </p>
                <br></br>
                <h2>7. Stay Active</h2>
                <p>
                    <ul>
                        <li>Regular physical activity, including weightlifting and cardio, aids in muscle preservation.</li>
                        <li>A more active lifestyle also allows for a higher caloric intake without gaining fat, ensuring you get the necessary nutrients without squeezing your macros too tight.</li>
                    </ul>
                </p>
                <br></br>
                <h2>8. Avoid Drastic Caloric Reductions</h2>
                <p>
                    <ul>
                        <li>Sudden, large decreases in caloric intake can shock the body and lead to unwanted muscle loss.</li>
                        <li>If you're seeing progress with your current caloric intake, maintain that level. Gradual adjustments are generally more sustainable and less risky than sudden, drastic changes.</li>
                    </ul>
                </p>
                <br></br>
                <h2>9. Be Aware of Diminishing Returns as You Get Leaner</h2>
                <p>
                    <ul>
                        <li>As you achieve a leaner physique, retaining muscle becomes more challenging.</li>
                        <li>It's essential to be realistic with expectations. While you may achieve visible abs and prominent veins, there may also be a decrease in strength during the cut. This is a natural response to being in a calorie deficit and getting leaner.</li>
                    </ul>
                </p>
                <br></br>
                <h2>Overall Tips</h2>
                <p>During a cutting phase, the goal is to lose body fat while preserving as much muscle mass as possible. Achieving this requires a balance between nutrition, workout intensity, and recovery. The provided strategies emphasize the importance of understanding and listening to your body, making informed and deliberate nutritional choices, and staying committed to your workout routine. Remember, consistency, patience, and a holistic approach to health and fitness will yield the best results.</p>

            </Container>
            <Break></Break>
            <Particle/>
        </Container>
    );
}

export default Diet101;
