import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const Hypertrophy101 = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

    return (
        <Container fluid className="about-section">
            <h1 className="title">Principles of Hypertrophy: A Comprehensive Overview</h1>

            <br></br><br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Principles of Hypertrophy: A Comprehensive Overview</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>

                    <a href="#0">Progressive Overload</a>
                    <br /><br />
                    <a href="#1">Caloric Surplus</a>
                    <br /><br />
                    <a href="#2">Lifestyle and Recovery</a>
                    <br /><br />
                    <a href="#3">Summary</a>
                    <br /><br />
                </Offcanvas.Body>
            </Offcanvas>

            <Container>
                <h2><Break id="0" /><strong>Progressive Overload:</strong></h2><br />
                <ul>
                    <li><p><strong>What It Is:</strong> To gain "muscle weight," you need a training stimulus with progressive overload.</p></li>
                    <li><p><strong>Key Phrase:</strong> The focus is on gaining "muscle weight," not just any weight.</p></li>
                </ul>
            </Container>
      
            <Container>

                <h2><Break id="1" /><strong>Caloric Surplus:</strong></h2><br />
                <ul>
                    <li><p><strong>What It Is:</strong> To gain any weight, you must be in a caloric surplus.</p></li>
                    <li><p><strong>Implication:</strong> Eating more calories than you expend is essential for muscle gain.</p></li>
                    <li><p><strong>Note:</strong> A training stimulus is necessary to ensure the weight gained is muscle, not fat.</p></li>
                </ul>
            </Container>
      
            <Container>

                <h2><Break id="2" /><strong>Lifestyle and Recovery:</strong></h2><br />
                <ul>
                    <li><p><strong>Importance:</strong> Recovery is crucial for muscle building.</p></li>
                    <li><p><strong>Factors to Consider:</strong>
                        <ul>
                            <li><p><strong>Sleep:</strong> Are you getting enough?</p></li>
                            <li><p><strong>Stress:</strong> Is it affecting you around the clock?</p></li>
                        </ul>
                    </p></li>
                </ul>
            </Container>
      
            <Container>

                <h2><Break id="3" /><strong>Summary</strong></h2><br />
                <ul>
                    <li><p><strong>Training Stimulus:</strong> Essential for muscle weight gain.</p></li>
                    <li><p><strong>Caloric Surplus:</strong> Required for any weight gain.</p></li>
                    <li><p><strong>Lifestyle:</strong> Recovery and stress management are key.</p></li>
                </ul>
            </Container>
            <Break />
            <Particle />
        </Container>


    );
}

export default Hypertrophy101;