import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const DifferentPowerliftingTechniques = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">SDB Technique Breakdown</h1>
            <br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Different Powerlifting Techniques</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>

                    {/*<a href="#Progressive Overload">Progressive Overload</a>
                    <br></br><br></br> */}

                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>
                <h2 id="squat-technique">Squat Technique</h2>
                <p>Objective: Improve the functionality of the athlete's body by developing lower body muscles, focusing on quadriceps, hamstrings, and glutes.</p>
                <p>Position: Perform the squat with a barbell on the upper back, starting from a standing position, descending by bending the hips and knees, then ascending back to start.</p>
                <p>Setup: Approach the barbell correctly, ensure a firm grip and even hand spacing, and place the bar on the upper trapezius muscles.</p>
                <p>Descent: Use box squats at knee level to learn depth and upright back, progress to half squats, and then to full-depth squats for a complete range of motion.</p>
                <p>Breathing: Inhale at the top, hold during descent, and exhale during ascent for stability and power.</p>
                <p>Knee and Foot Position: Keep knees in line with feet, find a stance width that allows efficient muscle use, and maintain a slightly raised head.</p>
                <p>Common Mistakes: Avoid leaning forward excessively and ensure knees do not move past toes to prevent injury and ensure proper form.</p>
                <p>Supporting Exercises: Include box squats with varying heights and other exercises to perfect squat technique and correct issues.</p>
                <br></br>
                <h2 id="bench-press-technique">Bench Press Technique</h2>
                <p>Objective: Target pectoral and upper body muscles, improving functionality and muscle development.</p>
                <p>Position: Lie on a flat bench, lower the barbell from locked arms to chest level, and press up to full arm extension.</p>
                <p>Gripping the Bar: Use wide or medium grips to emphasize different muscle groups, choosing based on arm length and chest strength.</p>
                <p>Breathing: Perform the bench press on inhale for increased strength and stability.</p>
                <p>Head Position: Keep the head on the bench post-"Start" command and do not lift off.</p>
                <p>Bar Path: Tailor the bar path to emphasize chest or triceps based on athlete's preference and strength areas.</p>
                <p>Bar Stabilization: Focus on proper elbow extension and bar stabilization in the final press stage.</p>
                <p>Common Mistakes: Ensure bar alignment over the chest and avoid thumbless grips or unnecessary bar to rack contact.</p>
                <br></br>
                <h2 id="deadlift-technique">Deadlift Technique</h2>
                <p>Starting Position: Keep feet hip-width apart with the bar over foot centers, toes pointed straight or slightly outward.</p>
                <p>Grip and Hand Placement: Bend to grip the bar just beyond shoulder width, using an overhand or mixed grip for stability.</p>
                <p>Setting Your Back: Maintain a straight back with a chest up to avoid spinal rounding and engage the core for stability.</p>
                <p>Lifting the Bar: Push through the heels, straighten hips and knees while keeping the bar close to the body, lifting with synchronized shoulder and hip movement.</p>
                <p>At the Top: Stand neutral with shoulders back and chest out, avoiding backward lean at lift completion.</p>
                <p>Lowering the Bar: Hinge at the hips and bend knees slightly, keeping the back straight and following the same bar path down.</p>
                <p>Breathing: Inhale before lifting, hold breath during the lift to maintain core stability, and exhale post-lift or past the most challenging part.</p>
                <p>Repeat: Reset and prepare for subsequent lifts with proper technique and form.</p>
                <br></br>
            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default DifferentPowerliftingTechniques; 