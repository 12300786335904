import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";


const ReadLabel = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
 
    return (
        <Container fluid className="about-section">   
            <Container>
                <h1 className="title">Understanding Nutrition Labels</h1>
                <p>Learn how to read and understand the various components of nutrition labels.</p>

                <br></br><br></br>
                </Container>
                <br></br>
                <Button variant="primary" onClick={handleShow} className="me-2">
                    Table of Contents
                </Button>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    scroll={true}
                    backdrop={false}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Nutrition Label Guide Contents</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library  </Nav.Link>

                        <a href="#0">The Basics</a>
                        <br></br><br></br>
                        <a href="#1">Macronutrients</a>
                        <br></br><br></br>
                        <a href="#2">Micronutrients</a>
                        <br></br><br></br>
                        <a href="#3">Percent Daily Values (%DV)</a>
                        <br></br><br></br>
                        <a href="#4">Additional Information</a>
                        <br></br><br></br>
                        <a href="#5">Practical Tips</a>
                    </Offcanvas.Body>
                </Offcanvas>
                
                <Container>
                    <h2 > <Break id="0" /><strong>The Basics</strong></h2> <br />

                    <p>Serving Size and Servings Per Container</p><br></br>
                    <p>The first thing to look for is the serving size and the number of servings per container. This is the foundation for understanding the rest of the label.</p>
                    <p><strong>Tip:</strong> Be cautious, as the serving size listed may not be the same as the portion you usually consume.</p>
                    
                    <p>Calories</p>
                    <p>This tells you the amount of energy you'll get from one serving of the food.</p>
                    <p><strong>Note:</strong> If you're watching your weight, pay close attention to this number.</p>
                </Container>
                
                <Container>

                    <h2 > <Break id="1"/> Macronutrients</h2><br></br>

                    <p><strong> Fats</strong></p>
                    <p><strong>Total Fat:</strong> The sum of all types of fats in the food.</p>
                    <p><strong>Saturated Fat:</strong> The type of fat linked to heart disease.</p>
                    <p><strong>Trans Fat:</strong> Another unhealthy fat you should aim to avoid.</p>
                    
                    <p><strong>Cholesterol</strong></p>
                    <p><strong>Warning:</strong> High levels of cholesterol can be harmful, so it's good to keep an eye on this number, especially if you have heart-related issues.</p>

                    <p><strong>Protein</strong></p>
                    <p><strong>Importance:</strong> This is essential for muscle repair and growth. Make sure you're getting enough, but not too much.</p>
                    
                    <p><strong>Carbohydrates</strong></p>
                    <p><strong>Total Carbohydrates:</strong> Includes all types of carbs, both good and bad.</p>
                    <p><strong>Dietary Fiber:</strong> A type of carb that's good for digestion.</p>
                    <p><strong>Sugars:</strong> Includes both natural and added sugars.</p>
                
                </Container>

                <Container>
                    <h2> <Break id="2"/> Micronutrients</h2><br></br>
                    <p><strong>Vitamins and Minerals</strong></p>
                    <p><strong>Note:</strong> These are usually listed toward the bottom of the label. They're essential for various bodily functions but are needed in smaller amounts compared to macronutrients.</p>
                </Container>

                <Container>
                    <h2 id="3">Percent Daily Values (%DV)</h2><br></br>
                    
                    <p>Understanding %DV</p>
                    <p>The Percent Daily Values tell you the percentage of each nutrient in a single serving, based on the daily recommended amount.</p>
                    <p><strong>General Guide:</strong> 5% DV or less is low; 20% DV or more is high.</p>
                </Container>

                <Container>
                    <h2 id="4">Additional Information</h2><br></br>

                    <p>Ingredients List</p>
                    <p><strong>Note:</strong> Ingredients are listed in descending order by weight. This can help you identify if the product is high in certain ingredients you may want to avoid, like sugar or salt.</p>
                    
                    <p>Allergen Information</p>
                    <p><strong>Allergy Alert:</strong> This section is crucial for those with food allergies. It lists common allergens that may be present.</p>
                </Container>

                <Container>
                    <h2 id="5">Practical Tips</h2><br></br>

                    <p>Compare and Contrast</p>
                    <p><strong>Tip:</strong> Always compare labels of similar products to make the best choice for your dietary needs.</p>

                    <p>Be Mindful of Marketing</p>
                    <p><strong>Caution:</strong> Terms like "natural" or "organic" don't necessarily mean the product is healthier. Always check the nutrition label for the real facts.</p>

                </Container>
                <Break />
                <Particle />
            </Container>
        
    );

}


export default ReadLabel;