import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

function Cookbooks({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const handleShow = () => setShow(true);

    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);
    return (
        <Container fluid className="about-section">
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Welcome to The Gym Community Library!</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library  </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
            <br></br>
                <h1>Community Created Cookbooks!</h1>

                <p>This is the channel that all our cookbooks will be in. As always everything in the forums is completely free so have at thee!</p>

                <h2 id="1">Breakfast Cookbook Volume 1:</h2>
                <p>
                    <ul>
                        <li>View only: <a href="https://docs.google.com/document/d/1wOGhWPDDtnxbykMUOG1Q_RXJq61kC07GsOWtRhQSFWE/edit?usp=sharing">Click Here</a></li>
                        <li>Make a copy: <a href="https://docs.google.com/document/d/1jGgLpBoc1lga5nK6JE0fYATvm5yCHM43Iir8A_qxh-8/copy?usp=sharing">Click Here</a></li>
                    </ul>
                </p>

                <h2 id="2">Lunch and Dinner Cookbook Volume 1:</h2>
                <p>
                    <ul>
                        <li>View only: <a href="https://docs.google.com/document/d/1wOGhWPDDtnxbykMUOG1Q_RXJq61kC07GsOWtRhQSFWE/edit?usp=sharing">Click Here</a></li>
                        <li>Make a copy: <a href="https://docs.google.com/document/d/1wOGhWPDDtnxbykMUOG1Q_RXJq61kC07GsOWtRhQSFWE/copy?usp=sharing">Click Here</a></li>
                    </ul>
                </p>

                <h2 id="3">Snack Cookbook Volume 1:</h2>
                <p>
                    <ul>
                        <li>View only: <a href="https://docs.google.com/document/d/1nHhydft4B4dAlvnPXVP-ryGWyydlLM1CsB6XoXtpOnE/edit?usp=sharing">Click Here</a></li>
                        <li>Make a copy: <a href="https://docs.google.com/document/d/1nHhydft4B4dAlvnPXVP-ryGWyydlLM1CsB6XoXtpOnE/copy?usp=sharing">Click Here</a></li>
                    </ul>
                </p>

            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default Cookbooks;