import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Particle from '../Particle';

const ZapierChatbot = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js';
        script.type = 'module';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const containerStyle = {
        display: 'flex',        // Enables flexbox
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center',     // Centers vertically
        height: '100vh',          // Full viewport height
        flexDirection: 'column',  // Stack children vertically
    };

    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Workout Planner</h2>
                <br></br>
                <p>This will be your one stop shop for creating a workout program. Tell the bot your gender, age, weight, height, how often you exercise, and what your goals are. It may ask you additional prompts, just answer to the best of your abilities.</p>
            </Container>
            <br></br><br></br>
            <Container>
                <Container>
                    <div style={containerStyle}>
                        {/* You can add <br /> tags if you need some space at the top, but it's better to use padding/margin in the containerStyle */}
                        <div>
                            {/* Inline style for the chatbot embed */}
                            <zapier-interfaces-chatbot-embed
                                is-popup="false"
                                chatbot-id='cloq2ktf6002jl90o4bjq0pgm'
                                style={{
                                    width: '100vw',
                                    height: '100vh',
                                    position: 'absolute', // or 'fixed' depending on your needs
                                    top: 400, left: -10
                                }}
                            ></zapier-interfaces-chatbot-embed>
                        </div>
                    </div>
                </Container>
            </Container>
            <Particle />
        </Container >
    );
};

export default ZapierChatbot;