import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const PrinciplesOfHyper = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h2 className="title">Principles of Hypertrophy Training: A Comprehensive Overview</h2>

            <br></br><br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Hypertrophy101 Guide Contents</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
            <br></br><br></br>
                <h3>Mechanical Tension:</h3>
                <p>This refers to the amount of force produced in muscle fibers. Increased mechanical tension, achieved through lifting heavier weights or increasing the time the muscle is under tension, is a primary driver of muscle growth.</p>

                <h3>Metabolic Stress:</h3>
                <p>Metabolic stress is caused by the accumulation of metabolites like lactate during resistance training. High-repetition sets with short rest intervals can create metabolic stress, contributing to muscle hypertrophy.</p>

                <h3>Muscle Damage:</h3>
                <p>Muscle damage from training leads to an inflammatory response and the activation of satellite cells, which are crucial for muscle repair and growth.</p>

                <h3>Progressive Overload:</h3>
                <p>To continue making progress in muscle size, you need to gradually increase the training stimulus over time. This could be through increasing weight, volume (sets and reps), or training frequency.</p>

                <h3>Volume:</h3>
                <p>Higher training volume (more sets and repetitions) has been associated with greater muscle hypertrophy, up to a point. The optimal volume may vary between individuals.</p>

                <h3>Time Under Tension (TUT):</h3>
                <p>Extending the duration that a muscle is under tension during each set can promote muscle growth. This can be achieved by using slower rep tempos.</p>

                <h3>Frequency:</h3>
                <p>Training frequency refers to how often a muscle or muscle group is trained over a given period. Higher frequency training can be beneficial for muscle hypertrophy, as it allows for more training volume and repeated muscle protein synthesis stimulation.</p>

                <h3>Nutrition:</h3>
                <p>Adequate protein intake and overall nutrition play critical roles in supporting muscle repair and growth following training.</p>

                <h3>Recovery:</h3>
                <p>Adequate recovery between training sessions is essential for muscle repair and growth. This includes proper sleep, nutrition, and stress management.</p>

                <h3>Individualization:</h3>
                <p>Everyone responds differently to training due to genetic and environmental factors. Tailoring the training program to the individual’s response can optimize hypertrophy outcomes.</p>

                <h3>Variety:</h3>
                <p>Varying exercises, angles, and training techniques can help to stimulate all muscle fibers and prevent plateaus.</p>

                <h3>Mind-Muscle Connection:</h3>
                <p>Focusing on the muscle being worked, and feeling the contraction and stretch during each rep, can enhance muscle activation and growth.</p>
            </Container>
            <Break />
            <Particle />
        </Container>
  );
}
export default PrinciplesOfHyper;