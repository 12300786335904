import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

function NameOfPage({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);
    
    return (
        <Container fluid className="about-section">
            <Container>
                <h1>Understanding Stretching Techniques: Static vs. Dynamic</h1><br /><br />
                <p> Stretching is a great way to improve flexibility and range of motion in your joints. It can also help you recover faster from a tough workout. But there are two different types of stretching: static and dynamic. Static stretching involves holding a position for 10-30 seconds, while dynamic stretching involves moving through a range of motion for 10-30 seconds. Both types of stretching have their benefits, but which one is better? Let's take a look at the pros and cons of each type of stretching.</p>
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Understanding Stretching Techniques: Static vs. Dynamic</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">Types of Stretching</a>
                    <br /><br />
                    <a href="#1">Scientific Insights</a>
                    <br /><br />
                    <a href="#2">Stretching Routines</a>
                    <br /><br />
                    <a href="#3">Honorable Mentions</a>
                    <br /><br />
                    <a href="#4">Great Resources</a>
                    <br /><br />
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <h2 ><Break id="0" /><strong>Types of Stretching</strong></h2><br />
                <ul>
                    <p><li><strong>Static Stretching</strong><br /><br />
                        <ul>
                            <p><li>Slow, constant, and held at an end position for up to 30 seconds.</li></p>
                            <p><li><strong>Static Passive Stretching:</strong> Uses external force; no muscle groups are contracted.</li></p>
                        </ul>
                    </li></p><br />
                    <p><li><strong>Dynamic Stretching:</strong><br /><br />
                        <ul>
                            <p><li>Involves speed, momentum, and active muscular effort.</li></p>
                            <p><li>End position is not held.</li></p>
                            <p><li>Useful before exercise and reduces muscle tightness.</li></p>
                        </ul>
                    </li></p>
                </ul>
                
            </Container>
            <Container>
                <h2 ><Break id="1" /><strong>Scientific Insights</strong></h2><br />
                <p><strong>Dynamic vs. Static:</strong> Recent studies suggest dynamic stretches are preferable before exercise, especially for strength and power athletes.</p>
            </Container>            
            <Container>
                <h2><Break id="2" /><strong>Stretching Routines</strong></h2><br />
                <ul>
                    <p><strong><a href="https://oil-marquis-f49.notion.site/Dynamic-Stretching-Routines-BEFORE-WORKOUT-b2b899cf401a463f86f3fc6cf32eb868" target="_blank">Dynamic Stretching Routines</a></strong><br /><br /></p><br />   
                    
                    <p><strong><a href="https://oil-marquis-f49.notion.site/Stretching-Routines-AFTER-WORKOUT-3779736d2c6d40ff810075108d8d4b8b" target="_blank">Static Stretching Routines</a></strong><br /><br /></p>  
                </ul>   
            </Container>            
            <Container>
                <h2><Break id="3" /><strong>Honorable Mentions</strong></h2><br /><br />
                
                <h4>How to do a 90/90 Hip Stretch properly - CORRECT FORM IS ESSENTIAL</h4><br /><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/VYvMMw8z3rE?si=IP6QPjPc5J5Vz8-p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br /><br /><br />


                <h4>Yoga For Flexibility | 16 Minute Practice</h4><br /><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Yzm3fA2HhkQ?si=glP7U6EszypF2Xgf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                
                
            </Container>            
            <Container>
            <h2><Break id="4" /><strong>Great Resources</strong></h2><br />
            <ul>
                <p><li><a href="https://thefitness.wiki/routines/flexibility-mobility/" target="_blank">Flexibility & Mobility Wiki</a></li></p>
                <p><li><a href="https://www.sport-fitness-advisor.com/stretchingexercises.html" target="_blank">Sport Fitness Advisor - Stretching Exercises</a></li></p>
                <p><li><a href="https://www.sport-fitness-advisor.com/staticactivestretching.html" target="_blank">Sport Fitness Advisor - Static Active Stretching</a></li></p>
                <p><li><a href="https://www.sport-fitness-advisor.com/dynamicstretching.html" target="_blank">Sport Fitness Advisor - Dynamic Stretching</a></li></p>
            </ul>
                
            </Container>
            <Break />
            <Particle />
        </Container>
    );
}

export default NameOfPage;