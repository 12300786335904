import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const SBDVariations = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">SBD Variation Exercises</h1>
            <br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>SBD Variation Exercises</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break />
                <h2 id="squat-variations">Squat Variations</h2>
                <p>Squat to Boxes of Various Heights: Master squat depth and maintain an upright back position.</p>
                <p>Front Squat: More emphasis on the quadriceps and maintaining an upright torso.</p>
                <p>Squats with One or Two Pauses: Builds strength and control in specific phases of the lift.</p>
                <p>Slow Eccentric Squat with Rapid Upward Movement: Focus on controlling the descent and exploding upwards.</p>
                <p>Barbell Squat Against a Wall: Helps maintain an upright back and prevents excessive forward lean.</p>
                <p>Squat Negatives: Slow control of a heavy squat on the descent, with assistance on the ascent.</p>
                <p>Squat with Chains: Increases resistance during the ascent phase of the squat.</p>
                <p>Pyramid Power Rack Squat: Squatting to various depths using pins set at different heights in a power rack.</p>
                <p>Barbell Partial Squat: Focuses on a specific range of motion in the squat.</p>
                <p>Narrow Stance Squat: Places more emphasis on the quadriceps.</p>
                <br />
                <h2 id="bench-variations">Bench Variations</h2>
                <p>Wide Grip Bench Press: Emphasizes the pectoral muscles with a wider grip.</p>
                <p>Medium Grip Bench Press: Actively engages the triceps muscles and deltoids.</p>
                <p>Narrow Grip Bench Press: Places more emphasis on the triceps.</p>
                <p>Bench Press with a Foam Roller Under the Lower Back: Helps enhance the arch in the back, emphasizing the chest muscles.</p>
                <p>Bench Press with an Extended Pause: Builds explosive strength off the chest.</p>
                <p>Explosive Bench Press: Focuses on speed and explosiveness of the lift.</p>
                <p>Speed Bench Press: Emphasizes lifting the weight as quickly as possible.</p>
                <p>Bench Press with Pauses: Helps overcome sticking points by adding pauses.</p>
                <p>Slow Negatives: Builds strength and muscle control by lowering the barbell very slowly.</p>
                <p>Cambered Bar Press: Engages different muscle groups by using a cambered bar.</p>
                <p>Bench Press from a Board on the Chest (5-25 cm): Focuses on the lockout phase.</p>
                <p>Bench Press with Chains: Increases resistance as the bar is lifted, emphasizing the lockout phase.</p>
                <br />
                <h2 id="deadlift-variations">Deadlift Variations</h2>
                <p>Deadlift to the Knees: Focuses on the initial pull off the floor.</p>
                <p>Deadlift with One Pause (Bar Above Knee Joints): Builds strength and control with a pause above the knees.</p>
                <p>Deadlift with Two Pauses (Bar Below and Above Knee Joints): Introduces two pauses, below and above the knees.</p>
                <p>Deadlift on a Stand (Deficit Deadlifts): Increases range of motion, targeting the initial phase.</p>
                <p>Deficit Deadlift with One or Two Pauses: Combines deficit position with pauses.</p>
                <p>Deadlift with Chains: Increases resistance during the lift, emphasizing the lockout phase.</p>
                <p>Deadlift from Blocks (Bar Below the Knees): Focuses on the lockout phase.</p>
                <p>Deadlift from Blocks (Bar Above the Knees): Another variation emphasizing the lockout phase.</p>
                <p>Deadlift from Below the Knees to Lockout: Focuses on the second half of the motion.</p>
                <p>Deadlift from Pins (Starting at Lockout and Lowering Down Slowly): Builds strength during the eccentric phase.</p>
            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default SBDVariations;