import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

const Resources = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">Resources COMING SOON</h1>
            <br></br>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Resources COMING SOON</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                    </Nav.Link>

                    {/*<a href="#Progressive Overload">Progressive Overload</a>
                    <br></br><br></br>
                    <a href="#Caloric Surplus">Caloric Surplus</a>
                    <br></br><br></br>
                    <a href="#Lifestyle and Recovery">Lifestyle and Recovery</a>
                    <br></br><br></br>
                    <a href="#Summary">Summary</a>
                    <br></br><br></br> */}
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
            <Break></Break>  
                <p>Coming Soon</p>

            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default Resources;