import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../../../Particle";
import Break from "../../Break";

function MobilityRoutines() {
    return (
        <Container fluid className="about-section">
            
            <Container>
                    <h2>What is included in our Mobility Routines section?</h2>
                    <p>Here is a collection of helpful Flexibiliy routines that we have gathered over the years. They range from trageted areas to full body routines. So take a look and find the one that suits your goals.</p>
                </Container>
            <Break></Break>
            <Container>
                <h2>Full Body Mobility</h2>
                <p>Here is a 25 minute full body mobility routine from bodyweight warrior.</p>
                <a href="https://www.bodyweightwarrior.co.uk/blog/full-body-mobility-25" target="_blank" rel="noopener noreferrer">
                Click Here to view full guide.</a>
                <br></br><br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/O90ZZd1WEQs?si=oCkZLkJ94SzmysHz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
                <h2>Hip Mobility Internal Rotation</h2>
                <p>Here is step by step on how to improve internal hip rotation</p>
                <p>Seated internal hip rotations improve hip mobility and range of motion. This can reduce tightness and discomfort.</p>
                <p>If you have knee problems, avoid this exercise. It can put a lot of stress on the knee.</p>
                <p>Sit on the floor. Bend your knees.</p>
                <p>Place your feet slightly wider than shoulder-width apart.</p>
                <p>For stability, place your hands on the floor behind you.</p>
                <p>Flex your right foot. Keep your left leg in place.</p>
                <p>Bring your right knee in and toward the floor. Repeat on the other side.</p>
                <br></br><br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/nsTSL_GWPQY?si=L3DYJx6q4b4BqoAG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
            </Container>

            <Particle />
        </Container>
    );
}

export default MobilityRoutines;