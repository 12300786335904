import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";

function SelfMyoRelease({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [updateExpanded] = useState(false);

    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Introduction to Self Myofascial Release Techniques</h2>
                <p>Thank you to Sport Fitness Advisor once again - <a href="https://www.sport-fitness-advisor.com/self-myofascial-release.html">Sport Fitness Advisor on Self Myofascial Release</a></p>
                <p>It's important to understand two key terms in order to appreciate how self myofascial release technique acts favorably on the body. They are fascia and trigger points. Both are explored below before moving on to some sample self myofascial release exercises.</p>
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Introduction to Self Myofascial Release Techniques</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>
                
                    <a href="#0">Muscle Fascia</a>
                    <br /><br />
                    <a href="#1">Trigger Points</a>
                    <br /><br />
                    <a href="#2">General Guidelines</a>
                    <br /><br />
                    <a href="#3">Self Myofascial Release Exercise</a>
                    <br /><br />  
                </Offcanvas.Body>
            </Offcanvas>
            
            <Container>
                <h2 ><Break id="0" /><strong>Muscle Fascia</strong></h2><br />
                <p><strong>Fascia</strong> is a specialized connective tissue layer surrounding muscles, bones and joints and gives support and protection to the body. It consists of three layers – the superficial fascia, the deep fascia and the sub serous fascia. Fascia is one of the 3 types of dense connective tissue (the others being ligaments and tendons) and it extends without interruption from the top of the head to the tip of the toes.</p>                
            </Container>
            <Container>
                <h2 ><Break id="1" /><strong>Trigger Points</strong></h2><br />
                <p><strong>Trigger points</strong> have been defined as areas of muscle that are painful to palpation and are characterized by the presence of taut bands. Tissue can become thick, tough and knotted. They can occur in muscle, the muscle-tendon junctions, bursa, or fat pad. Sometimes, trigger points can be accompanied by inflammation and if they remain long enough, what was once healthy fascia is replaced with inelastic scar tissue.</p>
                
            </Container>
            <Container>
                <h2><Break id="2" /><strong>General Guidelines</strong></h2><br />
                <p><strong>Spend 1-2 minutes</strong> per self myofascial release technique and on each side (when applicable).</p>
                <p><strong>When a trigger point is found</strong> (painful area) hold for 30-45 seconds.</p>
                <p><strong>Keep the abdominal muscles tight</strong> which provides stability to the lumbo-pelvic-hip complex during rolling.</p>
                <p><strong>Remember to breathe slowly</strong> as this will help to reduce any tense reflexes caused by discomfort.</p>
                <p><strong>Complete the self myofascial release exercises</strong> 1-2 x daily.</p>
                
            </Container>           
            <Container>
                <h2><Break id="3" /><strong>Self Myofascial Release Exercises</strong></h2><br />
                <p>For these exercises you will need a foam roll (which is very inexpensive and your gym may even provide)</p><br />
                <ul>
                    <li><h3>Adductor Self Myofascial Release</h3><br />                
                    <p>Extend the thigh and place foam roll in the groin region with body prone (face down) on the floor. Be cautious when rolling near the adductor complex origins at the pelvis. If a tender point is located, stop rolling, and rest on the tender point until pain decreases by 75% </p></li><br />
                    
                    
                    <li><h3>Hamstring Self Myofascial Release</h3><br />                 
                    <p>Place hamstrings on the roll with hips unsupported. Feet can be crossed so that only one leg at a time is on the foam roll. Roll from knee toward posterior hip. If a tender point is located, stop rolling, and rest on the tender point until pain decreases by 75% </p></li><br />
                    
                    <li><h3>Quadriceps Self Myofascial Release</h3><br />                 
                    <p>Body is positioned prone (face down) with quadriceps on foam roll. It is very important to maintain proper core control (abdominal drawn-in position & tight gluteus) to prevent low back compensations. Roll from pelvic bone to knee, emphasizing the lateral (outside) thigh. If a tender point is located, stop rolling, and rest on the tender point until pain decreases by 75% </p></li><br />
                    
                    
                    <li><h3>Iliotibial Band Self Myofascial Release</h3><br />                  
                    <p>Position yourself on your side lying on foam roll. Bottom leg is raised slightly off floor. Maintain head in neutral position with ears aligned with shoulders. This may be PAINFUL for many, and should be done in moderation. Roll just below hip joint down the outside thigh to the knee. If a tender point is located, stop rolling, and rest on the tender point until pain decreases by 75%.</p></li><br />            
                    
                    <li><h3>Upper Back Self Myofascial Release</h3><br />                   
                    <p>Place hands behind head or wrap arms around chest to clear the shoulder blades across the thoracic wall. Raise hips until unsupported. Stabilize the head in a neutral position. Roll the mid-back area on the foam roll. If a tender point is located, stop rolling, and rest on the tender point until pain decreases by 75%.</p></li>                 
                </ul>
            </Container>            
            <Break />
            <Particle />
        </Container>
    );
}

export default SelfMyoRelease;