import React from "react";
import {  useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { ArrowUp} from "react-bootstrap-icons";



const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    isVisible && (
      <Button
        variant="secondary"
        style={{
          position: 'fixed',
          bottom: '60px',
          right: '50px'
        }}
        onClick={scrollToTop}
      >
        <ArrowUp />
      </Button>
    )
  );
};

export default ScrollToTop;
