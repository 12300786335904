import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";

function WarmUpCoolDown({ name, ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [expand, updateExpanded] = useState(false);

    const openDiscord = () => {
        window.open("https://discord.com/invite/cP3E9FrMCn", "_blank");
    };
    return (
        <Container fluid className="about-section">
            <Container>
                <h2><strong>The Importance of Warming Up and Cooling Down in Exercise ft. Project Reign </strong></h2>
                <p>Warm-ups and cool-downs are essential components of a well-rounded exercise routine, helping to reduce the risk of injury and promote recovery. By incorporating dynamic warm-ups before exercise and gentle cool-down activities afterward, you can optimize performance, prevent injuries, and enhance overall well-being. This article will discuss the benefits of warming up and cooling down, along with practical tips for incorporating these practices into your exercise routine.</p>
            </Container>
            <br /><br />
            <Button variant="primary" onClick={handleShow} className="me-2">
                Table of Contents
            </Button>

            <Button variant="primary" onClick={openDiscord}>
                Check out Kyle's Project Reign
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Injury Prevention: Warming Up and Cooling Down ft. Project Reign</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link
                        as={Link}
                        to="/library"
                        onClick={() => updateExpanded(false)}
                    >
                        <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library</Nav.Link>

                    <a href="#1">The Benefits of Warming Up</a>
                    <br /><br />
                    <a href="#2">Effective Warm-Up Strategies</a>
                    <br /><br />
                    <a href="#3">The Benefits of Cooling Down</a>
                    <br /><br />
                    <a href="#4">Effective Cool-Down Strategies</a>
                    <br /><br />
                    <a href="#5">Conclusion</a>  
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <h2 ><Break id="1" /><strong>The Benefits of Warming Up</strong></h2><br />
                <p>Warming up prepares the body for more intense physical activity by gradually increasing blood flow, heart rate, and body temperature.</p><br />
                <ul>
                    <li><p><strong>Improved flexibility:</strong> Increased muscle temperature allows for greater elasticity and range of motion, reducing the risk of injury.</p></li>
                    <li><p><strong>Enhanced performance:</strong> Warming up activates the neuromuscular system, improving coordination, reaction time, and power output.</p></li>
                    <li><p><strong>Mental preparation: </strong> The warm-up period provides an opportunity to mentally prepare for exercise, increasing focus and motivation.</p></li>  
                </ul>
            </Container>            
            <Container>
                <h2><Break id="2" /><strong>Effective Warm-Up Strategies</strong></h2><br />
                <p>A dynamic warm-up, incorporating light aerobic activity and functional movement patterns, is ideal for preparing the body for exercise.</p>
                <ul>
                    <li><p>Aim for a 5-10 minute warm-up session before each workout, including the following activities:</p></li><br />
                    <li><p><strong>Aerobic exercise:</strong>Begin with light cardiovascular activity, such as brisk walking, jogging, or cycling, to gradually increase heart rate and blood flow.</p></li>
                    <li><p><strong>Dynamic stretching:</strong> Perform dynamic stretches that mimic the movements of your upcoming workout. Examples include leg swings, arm circles, hip rotations, and walking lunges.</p></li>
                    <li><p><strong>Sport-specific drills:</strong> For athletes or individuals participating in specific sports, incorporate sport-specific drills to enhance neuromuscular activation and movement efficiency.</p></li>   
                </ul>
            </Container>
            
            <Container>
                <h2><Break id="3" /><strong>The Benefits of Cooling Down</strong></h2><br />
                <p>Cooling down helps the body transition from intense exercise back to a resting state.</p>
                <ul>
                    <li><p><strong>Reduced blood pooling:</strong> Gentle activity after exercise helps prevent blood from pooling in the extremities, reducing the risk of dizziness or fainting.</p></li><br />
                    <li><p><strong>Enhanced recovery:</strong> Cool-down activities can help remove metabolic waste products, such as lactic acid, from the muscles, promoting recovery and reducing muscle soreness.</p></li>
                    <li><p><strong>Improved flexibility:</strong> Incorporating static stretching or foam rolling during cool-down can help maintain or improve flexibility, preventing muscle imbalances and reducing the risk of injury.</p></li>
                </ul>
            </Container>            
            <Container>
                <h2><Break id="4" /><strong>Effective Cool-Down Strategies</strong></h2><br />
                <ul>
                    <li><p>Aim for a 5-10 minute cool-down session after each workout, incorporating the following activities:</p></li>
                    <li><p><strong>Low-intensity aerobic exercise:</strong> Engage in low-intensity cardiovascular activity, such as slow walking or cycling, to gradually lower heart rate and blood pressure.</p></li>
                    <li><p><strong>Static stretching:</strong> Perform static stretches for the major muscle groups worked during the exercise session. Hold each stretch for 15-30 seconds, avoiding bouncing or forcing the stretch.</p></li>
                    <li><p><strong>Foam rolling:</strong> Use a foam roller to perform self-myofascial release, targeting tight or sore muscles. Spend 30-60 seconds on each muscle group, applying gentle pressure and rolling slowly.</p></li>  
                </ul>
            </Container>            
            <Container>
                <h2><Break id="5" /><strong>Conclusion</strong></h2><br />
                <p>Incorporating warm-ups and cool-downs into your exercise routine is crucial for injury prevention and recovery. By engaging in dynamic warm-ups before exercise and gentle cool-down activities afterward, you can optimize performance, prevent injuries, and promote overall well-being. Remember to dedicate time to both warm-up and cool-down sessions, ensuring a safe and effective workout experience.</p>
                
            </Container>
            <Break />
            <ScrollToTop />
            <Particle />
        </Container>
    );
}

export default WarmUpCoolDown;