import React from "react";
import { Container, Offcanvas, Button } from "react-bootstrap";
import Particle from "../../../Particle";
import { useState } from 'react';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import OpenInNewTabButton from "../../OpenInNewTabButton"
import ProgramAccordion from "../../ProgramAccordion";
import Break from "../../Break";

const Calisthenics = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <Container>
                <h2>Welcome to Calisthenics Programs</h2>
                <p>Woop</p>
                <Button variant="primary" onClick={handleShow} className="me-2">
                    Table of Contents
                </Button>

                <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Calisthenics Programs</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav.Link as={Link} to="/library" onClick={() => setShow(false)}>
                            <CgFileDocument style={{ marginBottom: "2px" }} /> Go Back To Library
                        </Nav.Link>
                    </Offcanvas.Body>
                </Offcanvas>
            </Container>
            <Break></Break>
            <Container>
                <h2 id="id">Table</h2>
                <p>Info</p>
                <ProgramAccordion sheetUrl="" />
                <br></br>
                <Container>
                    <OpenInNewTabButton>
                        url=""
                        buttonText="Make a Copy"
                    </OpenInNewTabButton>
                    <br></br><br></br>
                    <p>Check out the full article: <a href=" https://liftvault.com/programs/strength/starting-strength-spreadsheet/" target="_blank" rel="noopener noreferrer">https://liftvault.com/programs/strength/starting-strength-spreadsheet/</a></p>
                </Container>
            </Container>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default Calisthenics;