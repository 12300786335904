import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { CgFileDocument } from 'react-icons/cg';

const NavItemComponent = ({ to, label, updateExpanded }) => {
  return (
    <Nav.Item>
      <Nav.Link
        as={Link}
        to={to}
        onClick={() => updateExpanded(false)}
      >
        <CgFileDocument style={{ marginBottom: "2px" }} /> {label}
      </Nav.Link>
    </Nav.Item>
  );
};

export default NavItemComponent;
