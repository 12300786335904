import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Particle from "../Particle";

const Applications = () => {
    return (
        <Container fluid className="about-section">
            <Container>
                <h1>Welcome to The Gym Community Application Page!</h1>
                <br></br>
                <h3>
                    Welcome to our application center! We're thrilled to see you're interested in becoming a part of our dynamic team.
                    Here, we offer various roles tailored to diverse skill sets and professional interests. Whether you're looking to assist others, dive into operations, specialize in personal training, or
                    explore marketing opportunities, we have a place for you. Each position plays a crucial role in our mission to deliver outstanding service and results.
                </h3>
                <br></br>
                <h3> If you're passionate about fitness, like our community, and want to be a part of our team, please click below to apply.</h3>
                <Button
                    variant="custom"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSffPSdO4Ja_ztf5Rfwj8iYDwMVoLAUFe0YIAULNrV4l42w5vw/viewform?usp=sf_link"
                    target="_blank"
                    style={{ backgroundColor: '#5F3787', color: 'white', border: 'none' }}>
                    Mod Application
                </Button>
                <br></br><br></br>
                <h3>Interested in helping out and becoming a part of our team? Click the link below to apply!</h3>
                <Button
                    variant="custom"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdrpJN64pjE67kGcrrWlaGTL5uFkTp6jZRYN2Hf413x8v_3mQ/viewform?usp=sf_link"
                    target="_blank"
                    style={{ backgroundColor: '#5F3787', color: 'white', border: 'none' }}>
                    Staff Application
                </Button>
                <br></br><br></br>
                <h3>If you're a team player with a passion for supporting and facilitating smooth workflows, the Helper role is for you. Click the link below to apply!</h3>
                <Button
                    variant="custom"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScdRnvgjKF8SHV10tf2faJNv0FLqiANaMkHzlUSmloiSTRwwg/viewform?usp=sf_link"
                    target="_blank"
                    style={{ backgroundColor: '#5F3787', color: 'white', border: 'none' }}>
                    Helper Application
                </Button>
                <br></br><br></br>
                <h3>Aspiring personal trainers with certifications, your expertise can help transform lives. Join our fitness experts in the CPT role.</h3>
                <Button
                    variant="custom"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdgJWuIx-pLPNqckeozoJf-BY5QZLGTBxBR990zcCgtnEQqAw/viewform?usp=sf_link"
                    target="_blank"
                    style={{ backgroundColor: '#5F3787', color: 'white', border: 'none' }}>
                    CPT Role Application
                </Button>
                <br></br><br></br>
                <h3>Looking to advertise in our server? Click the link below to apply!</h3>
                <Button
                    variant="custom"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSenPP_5uFPFzPM-RRM2XL0wviANOrWrL-9NNh82Ku3XxBADsw/viewform?usp=sf_link"
                    target="_blank"
                    style={{ backgroundColor: '#5F3787', color: 'white', border: 'none' }}>
                    Advertisement Application
                </Button>
            </Container>
            <Particle />
        </Container>
    );
};

export default Applications;
