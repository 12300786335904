import React from "react";
import {Container, Button} from "react-bootstrap";
import Particle from "../../../Particle";
import Break from "../../Break";

function BuildBench() {
    return (
        <Container fluid className="about-section">

            <Container>
                <h2>The Benching Bible</h2>
                <p>This free pdf below from House of Hypertrophy is a must have if you want to troubleshoot your bench or just get better in general.</p>
                <br/>
                <a href={
                        `${
                            process.env.PUBLIC_URL
                        }/Ultimate-Bench-Guide.pdf`
                    }
                    target="_blank"
                    download>
                    <Button variant="primary">Download PDF</Button>
                </a>
            </Container>

            <Break></Break>
            <Container>
                <h2>A Strong Set Up For Bench</h2>
                <br></br>
                <p>1. Grip width will be individual based upon limb length. Set your hands at a width that allows the bar to be right over your wrist and wrist right over your elbow when it is on your chest. Keeping these three things in line is critical to maximizing power.</p>
                <br></br>
                <p>2. A tight setup is critical to a big press. In your setup, your knees need to be aligned below your hips to ensure that your butt stays down on the bench through the movement. Think about squeezing your shoulder blades together as tight as possible, and make sure your chest is as high as possible.</p>
                <br></br>
                <p>3. Whether you are using a thumbless(hopefully not) or full thumb grip, you need to squeeze the bar as hard as possible. This will send a tightness response through the body.</p>
                <br></br>
                <p>4. Tuck the elbows as you bring the bar to your chest. Whether you want to think about bending the bar or any other cue, it is key to engage the lats. Thinking about loading your lats, like you are loading a spring. Elbows do not need to be overly tucked though, think of a 45 degree angle from your torso with them.</p>
                <br></br>
                <p>5. There are two styles that are widely used in the bench, a more dynamic style (Dan Green style), in which you lower the bar quickly letting it sink into your chest before the press command-which allows for great leg drive but is a more high-risk, high-reward technique. The other is a more strict style (Brandon Lilly style), in which you pause the bar on your chest by touching it as lightly as possible, maintaining as much tightness as possible through the whole body before getting the press command. One isn’t necessarily better than the other, you just need to see what works best for you.</p>
                <br></br>
                <p>6. Regardless of style you use, leg drive is critical. Your leg drive should drive you down the bench toward your head, but since your shoulder blades are dug into the bench, you won’t move; instead, the high point of your chest will rise. Your feet should be pressed into the ground (heel up or down depending on federation and comfort), and you should think about doing an explosive leg extension action, but instead of you leg extending, you will drive your body toward your head.</p>
                <br></br>
                <p>7. The bench press is a violent movement. As you loaded the spring with the descent of the bar, now you must violently uncoil it in the press. As your elbows tuck on the way down, they should flare on the way up.</p>
                <br></br>
                <p>8. Practice a strong lockout by fully locking out every rep, rather than cutting them short as “bodybuilder reps” and hold the final rep of each set at lockout for a 3-5 count before racking it.</p>

            </Container>
            <Break></Break>
            <Particle/>
        </Container>
    );
}

export default BuildBench;
