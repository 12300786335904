import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../../../Particle";
import Break from "../../Break";

function FlexibilityRoutines() {
    return (
        <Container fluid className="about-section">
            
            <Container>
                    <h2>What is included in our Flexibility Routines section?</h2>
                    <p>Here is a collection of helpful Flexibiliy routines that we have gathered over the years. They range from trageted areas to full body routines. So take a look and find the one that suits your goals.</p>
                </Container>
            <Break></Break>
            <Container>
                <h2>Where to start with flexibility</h2>
                <p>Here is your one stop shop from bodyweight warrior onhow to get started.</p>
                <a href="https://www.bodyweightwarrior.co.uk/blog/get-started-with-flexibility" target="_blank" rel="noopener noreferrer">
                Click Here to view the guide.</a>
                <br></br><br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/sDe77axV288?si=PMamx0DrCxqGlisD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
                <h2>Beginner Full Body Stretch</h2>
                <p>Here is a 15 minute beginner stretch flexibility routine.</p>
                <a href="https://www.bodyweightwarrior.co.uk/blog/beginner-stretch-flexibility-v3" target="_blank" rel="noopener noreferrer">
                Click Here to view the guide.</a>
                <br></br><br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/QR0JKN1NmV8?si=ySqplZLB0bZrIK0J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
                <h2>Beginner Flexibility Routine</h2>
                <p>Here is a 25 minute beginner flexibility routine.</p>
                <a href="https://www.bodyweightwarrior.co.uk/blog/beginner-flexibility-routine-v3" target="_blank" rel="noopener noreferrer">
                Click Here to view the guide.</a>
                <br></br><br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/OjcN6RzWFWs?si=s2tH-N2DG3m_N6nn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
                <h2>Hamstring Flexibility</h2>
                <p>Here is a 15 minute hamstring flexibility routine from body weight warrior</p>
                <a href="https://www.bodyweightwarrior.co.uk/blog/15-min-hamstring-flexibility-routine" target="_blank" rel="noopener noreferrer">
                Click Here to view the guide.</a>
                <br></br><br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/3Ymjw7TSzrE?si=IAakEYrT6lG9nZFK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
                <h2>Hip Flexor Flexibility</h2>
                <p>Here is a 20 minute routine from our boy bodyweight warrior</p>
                <a href="https://www.bodyweightwarrior.co.uk/blog/hip-flexor-flexibility" target="_blank" rel="noopener noreferrer">
                Click Here to view the guide.</a>
                <br></br><br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Z04ldN6WnRY?si=JZ4xRbldVu-95y2Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
                <h2>Shoulder Flexibility</h2>
                <p>Here is a 15 minute routine from our boy bodyweight warrior on shoulder flexibility</p>
                <a href="https://www.bodyweightwarrior.co.uk/blog/15-min-shoulder-flexibility-routine" target="_blank" rel="noopener noreferrer">
                Click Here to view the guide.</a>
                <br></br><br></br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/R0J_aPs-Jgo?si=K3DZVv-69ujSvzjN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
            </Container>

            <Particle />
        </Container>
    );
}

export default FlexibilityRoutines;