import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../../../Particle";
import Break from "../../Break";

function Yoga() {
    return (
        <Container fluid className="about-section">
            
            <Container>
                    <h2>What is included in our YOGA section?</h2>
                    <p>Here is a collection of helpful yoga videos from some of our personal favorites. They range from Astanga Yoga to spinal and deep stretch focus. At the very bottom there is a link to free online yoga classes fromfive Parks Yoga.</p>
                </Container>
            <Break></Break>
            <Container>
                <h2>Ashtanga Yoga:</h2>
                <p>What is it?</p>
                <p>Ashtanga is a very dynamic and athletic form of hatha yoga, made up of six series or levels, with a fixed order of postures. It is rooted in vinyasa, the flowing movements between postures, with a focus on energy and breath. While it is a very physical practice, it also promotes mental clarity and inner peace.</p>
                <a href="https://www.yogabasics.com/learn/ashtanga-yoga/" target="_blank" rel="noopener noreferrer">
                Click Here To Learn More</a>
                <br></br><br></br><br></br>
                <h2>Ashtanga Yoga Routine By Fightmaster Yoga</h2>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/pzSLOAsd0uU?si=kdKHq1sR3Xx51rc8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br></br><br></br><br></br>
                <h2>Here is an extended 60 minute version from David and Jelena Yoga</h2>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/O0NIiu50LN8?si=PXCtuw06ba1U80pq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <Break></Break>
                <h2>Here is a Spine and Hip focused routine from Adison Briana</h2>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/FgkoKF4K0FY?si=6For82nNCTU_jUrI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <Break></Break>
                <h2>Deep Stretch Slow Flow Yoga from Adison Briana</h2>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/UykgQN4TI_Q?si=0DOWqJX2RAMZQVU1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <Break></Break>
                <h2>Free Yoga Classes</h2>
                <p>Need more or want to take classes? Visit <a href="https://fiveparksyoga.com/" target="_blank" rel="noopener noreferrer">Five Parks Yoga</a>. They have free yoga coureses.</p>
                
            </Container>

            <Particle />
        </Container>
    );
}

export default Yoga;