import React from "react";
import {Container, Offcanvas, Button} from "react-bootstrap";
import Particle from "../../../Particle";
import {useState} from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import {CgFileDocument} from "react-icons/cg";
import Break from "../../Break";


const HypertrophyProgramming = ({name, ...props}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h2 className="title">Notes on Hypertophy: A programatic Standpoint</h2>

            <br></br>
            <br></br>
            <Button variant="primary"
                onClick={handleShow}
                className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Hypertrophy101 Guide Contents</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link}
                        to="/library"
                        onClick={
                            () => setShow(false)
                    }>
                        <CgFileDocument style={
                            {marginBottom: "2px"}
                        }/>
                        Go Back To Library
                    </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
            <br></br>
                <h2>How to Find Your Maximum Adaptive Volume</h2>
                <br></br>
                <h4>Finding MAV is done by continually adding sets until soreness becomes excessive and peformance begins to degrade. Here is how to quantify excessive soreness and degrading performance.</h4>
                <br></br>
                <p>
                    <strong>Soreness at the End of a Session</strong>
                </p>
                <ul>
                    <li>No soreness = 1 point</li>
                    <li>A little stiff or sore afterward, but not sore at all by the time the next session for that muscle group occurred = 2 points</li>
                    <li>Definitely getting DOMS (delayed onset muscle soreness), but it went away just in time for that muscle group’s next session = 3 points</li>
                    <li>You got DOMS and were still feeling them during the next session = 4 points</li>
                </ul>

                <p>
                    <strong>Performance During a Session</strong>
                </p>
                <ul>
                    <li>You had 2+ reps in reserve, RPE 7 or lower = 1 point</li>
                    <li>You had 1 rep in reserve, RPE 8 = 2 points</li>
                    <li>You just barely got all the reps, RPE 9/9.5 = 3 points</li>
                    <li>You missed reps or had performance degrade from last week (accounting for different weights, etc.) = 4 points</li>
                </ul>

                <p>
                    <strong>How Many Sets to Add Based on Points</strong>
                </p>
                <ul>
                    <li>1 + 1 = add 2 or 3 sets to that exercise</li>
                    <li>Any 2s = add 1 set to that exercise</li>
                    <li>Any 3s = do not add any sets to that exercise</li>
                    <li>Any 4s = consider taking a light session, half week, or full deload</li>
                </ul>

            </Container>
            <br/><br/>
            <Container>
                <h2>How to Find Your Minimum Effective Volume</h2>
                <br></br>
                <h4>Method 1: Start with recommended MEV and run for one mesocycle. Increase weights each week, but do not increase the number of sets. Test your performance on core exercises for each muscle group at the end of the mesocycle.</h4>
                <ul>
                    <li>Start with recommended MEV and run for one mesocycle.</li>
                    <li>Increase weights each week, but do not increase the number of sets.</li>
                    <li>Test performance on core exercises for each muscle group at the end of the mesocycle.</li>
                    <li>If performance improved, the volume was at or above your MEV. Try lowering volume by 2 sets each week until you find a volume that doesn't increase performance. Add 2 sets to this volume for your approximate MEV.</li>
                    <li>If strength did not improve, the volume was below your MEV. Try adding 2 sets per week until performance improves.</li>
                </ul>
                <br></br>
                <h4>Method 2: To approximate whether a particular training session was within your MEV, ask the following questions.</h4>
                <p>Ask the following questions after a training session:</p>
                <ul>
                    <li>
                        <strong>Did the training session provide a pump?</strong>
                        <ul>
                            <li>No pump = 0 points</li>
                            <li>Some pump = 1 point</li>
                            <li>Great pump = 2 points</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Did the training session challenge the target muscles?</strong>
                        <ul>
                            <li>No = 0 points</li>
                            <li>Lots of tensions and fatigue = 1 point</li>
                            <li>Muscles felt stressed and exhausted near their limit = 2 points</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Did the training session make you sore?</strong>
                        <ul>
                            <li>No = 0 points</li>
                            <li>Stiff for a few hours after or a little sore the next day = 1 point</li>
                            <li>Sore for multiple days = 2 points</li>
                        </ul>
                    </li>
                </ul>
                <p>A score between 2 and 4 is likely within your MEV. Frequency of training per week will influence the expected score range:</p>
                <ul>
                    <li>Training a muscle group 2x per week, expect more 4s.</li>
                    <li>Training a muscle group 3-4x per week, expect more 3s.</li>
                    <li>Training a muscle group 5-6x per week, expect more 2s or even 1s.</li>
                </ul>
            </Container>
            <br/><br/>
            <Container>
                <h2>How To Find Your Maintenance Volume</h2>
                <ul>
                    <li>Spend two to three times per year doing maintenance training and eating to resensitize your body to stimulus and growth.</li>
                </ul>

                <p>
                    <strong>Starting Maintenance Training</strong>
                </p>
                <ul>
                    <li>Start by following the "MV" (Maintenance Volume) volume recommendations in the article or on the “Sets per Week Summary” tab in the spreadsheet.</li>
                </ul>

                <p>
                    <strong>Assessing and Adjusting Maintenance Volume</strong>
                </p>
                <ul>
                    <li>If your rep strength is conserved at the end of one mesocycle at the prescribed volume, then the volume was adequate.</li>
                    <li>For the next mesocycle, try reducing the number of sets to determine if your MV is lower.</li>
                    <li>If your rep strength declined, the volume was too low, and your MV is higher than the volume of work done in the previous mesocycle.</li>
                    <li>For the next mesocycle, increase the number of sets and see if rep strength can be conserved.</li>
                </ul>

                <p>
                    <strong>General Tip</strong>
                </p>
                <ul>
                    <li>Be conservative with your volume estimations. Your MV is probably lower than you think.</li>
                </ul>
            </Container>
            <br/><br/>
            <Container>
            <Break></Break>
            <Particle />
            </Container>
        </Container>
    );
}

export default HypertrophyProgramming;
