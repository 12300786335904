import React from 'react';
import { Button } from 'react-bootstrap';

function OpenInNewTabButton({ url, buttonText }) {
    const handleClick = () => {
        // Open the URL in a new tab/window. No third parameter is needed.
        const newWindow = window.open(url, '_blank');
        // Check if the window opened successfully
        if (newWindow) {
            // Apply security features to window if supported
            newWindow.opener = null;
            newWindow.rel = 'noopener noreferrer';
        }
    };

    // Explicitly apply some common button sizing and layout styles
    const customStyle = {
        backgroundColor: '#5F3787', // Deep purple color
        color: 'white',             // White text color
        border: 'none',             // No border
        padding: '0.5rem 1rem',     // Use 'rem' units for consistency with Bootstrap
        fontSize: '1rem',           // Match Bootstrap's default font size
        textAlign: 'center',        // Center the text
        textDecoration: 'none',     // No underline on text
        display: 'inline-block',    // Use inline-block if you want button to be inline
        verticalAlign: 'middle',    // Align text vertically
        cursor: 'pointer',          // Cursor to pointer
    };

    // Note: The '&:hover' will not work for inline styles in React. 
    // You will need to use CSS classes or pseudo-class via styled-components for hover effects.

    return (
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                style={customStyle}
                // To keep bootstrap button classes
                className="btn"
            >
                {buttonText || 'Make a Copy'}
            </a>
    );
}

export default OpenInNewTabButton;