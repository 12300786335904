import React from "react";
import {Container, Offcanvas, Button} from "react-bootstrap";
import Particle from "../../../Particle";
import {useState} from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import {CgFileDocument} from "react-icons/cg";
import Break from "../../Break";
import ScrollToTop from "../../../ScrollToTop";


const OvercomingProcrastination = ({name, ...props}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="about-section">
            <h1 className="title">Overcoming Procrastination</h1>
            <br></br>
            <Button variant="primary"
                onClick={handleShow}
                className="me-2">
                Table of Contents
            </Button>

            <Offcanvas show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Overcoming Procrastination</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav.Link as={Link}
                        to="/library"
                        onClick={
                            () => setShow(false)
                    }>
                        <CgFileDocument style={
                            {marginBottom: "2px"}
                        }/>
                        Go Back To Library
                    </Nav.Link>
                </Offcanvas.Body>
            </Offcanvas>
            <Container>
                <Break></Break>
                <h2 id="2">Dr K On Procrastination</h2><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/TvRtVuwcrnY?si=0lRw37a_xcCH3md6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br /><br />
                <h2 id="3">Andrew Huberman On Procrastination</h2><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/i3tCAXfv07g?si=lKgmRsreqYJsMMGG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br /><br />
                <h2 id="4">Jocko On Procrastination</h2><br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/mF2lUnKRo0g?si=E8GekcJ5YtLHnJIG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Container>
            <ScrollToTop/>
            <Break></Break>
            <Particle />
        </Container>
    );
}

export default OvercomingProcrastination;
